import { createSelector } from 'reselect';
import { APP_REDUCER } from './reducer.app';

export function selectAppState(state) {
  return state.get(APP_REDUCER);
}

export const getLoader = (name = 'loader') =>
  createSelector(
    selectAppState,
    appState => appState.get(name),
  );

export const selectNotifications = createSelector(
  selectAppState,
  appState => appState.get('notifications').toJS(),
);

export const selectApiLock = createSelector(
  selectAppState,
  appState => appState.get('apiLock'),
);

export const getNatureByCategory = category =>
  createSelector(
    selectAppState,
    appState =>
      appState
        .get('homeNatures')
        .toJS()
        .find(item => item.code === category),
  );

export const getTPENatureByCategory = category =>
  createSelector(
    selectAppState,
    appState =>
      appState
        .get('homeTPENatures')
        .toJS()
        .find(item => item.code === category),
  );

export const generalConditionSelector = createSelector(
  selectAppState,
  appState => appState.get('generalCondition'),
);

export const getFaqSelector = createSelector(
  selectAppState,
  appState => appState.get('faq').toJS(),
);

export const getShowLegaleNotice = createSelector(
  selectAppState,
  appState => appState.get('showLegaleNotice'),
);
