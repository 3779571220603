import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { institutionalWording } from 'common/locale';
import { subMenuTPEWording } from 'common/constants/subMenuTPEWording';
import { useSelector } from 'react-redux';
import { getLocation } from 'core/root.selector';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/svg/retour.svg';

const item = item => {
  return item.map((str, i) => (
    <React.Fragment key={str}>
      {str}
      {item.length - 1 !== i && <br />}
    </React.Fragment>
  ));
};

const Element = ({ submenu, baseLink, classes }) => {
  return (
    <div className='mb-4'>
      <h3 className={`${classes.subTitle} title4 text-uppercase`}>
        {submenu.title}
      </h3>
      <div>
        {submenu.links.map(link => (
          <div key={link.text}>
            <Link className={classes.subLink} to={`${baseLink}${link.to}`}>
              {link.text}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

const NavitemfinancementCreation = ({
  classes,
  label,
  baseLink,
  customClass,
  items,
  isActiveMenu,
  onMenuClicked,
}) => {
  const location = useSelector(getLocation);
  const isCurrentMenu = location.startsWith(baseLink);
  return (
    <Nav.Item
      className={`link-element ${classes.textPrimary} ${
        isCurrentMenu ? 'current' : ''
      } ${isActiveMenu ? classes.activeMenu : ''}`}
    >
      <div className='menu-element' onClick={onMenuClicked}>
        <span className={classes.backIcon}>
          <BackIcon />
        </span>
        {item(label)}
      </div>
      <div className={`${customClass} ${classes.submenu}`}>
        <div className='row'>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[0]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[1]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[2]} baseLink={baseLink} />
          </div>
        </div>
      </div>
    </Nav.Item>
  );
};
const NavitemfinancementCycleExploitation = ({
  classes,
  label,
  baseLink,
  customClass,
  items,
  isActiveMenu,
  onMenuClicked,
}) => {
  const location = useSelector(getLocation);
  const isCurrentMenu = location.startsWith(baseLink);
  return (
    <Nav.Item
      className={`link-element ${classes.textPrimary} ${
        isCurrentMenu ? 'current' : ''
      } ${isActiveMenu ? classes.activeMenu : ''}`}
    >
      <div className='menu-element' onClick={onMenuClicked}>
        <span className={classes.backIcon}>
          <BackIcon />
        </span>
        {item(label)}
      </div>
      <div className={`${customClass} ${classes.submenu}`}>
        <div className='row'>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[0]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[1]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[2]} baseLink={baseLink} />
          </div>
        </div>
      </div>
    </Nav.Item>
  );
};
const NavitemfinancementDeveloppement = ({
  classes,
  label,
  baseLink,
  customClass,
  items,
  isActiveMenu,
  onMenuClicked,
}) => {
  const location = useSelector(getLocation);
  const isCurrentMenu = location.startsWith(baseLink);
  return (
    <Nav.Item
      className={`link-element ${classes.textPrimary} ${
        isCurrentMenu ? 'current' : ''
      } ${isActiveMenu ? classes.activeMenu : ''}`}
    >
      <div className='menu-element' onClick={onMenuClicked}>
        <span className={classes.backIcon}>
          <BackIcon />
        </span>
        {item(label)}
      </div>
      <div className={`${customClass} ${classes.submenu}`}>
        <div className='row'>
          <div className='col-md-6'>
            <Element classes={classes} submenu={items[0]} baseLink={baseLink} />
          </div>
          <div className='col-md-6'>
            <Element classes={classes} submenu={items[1]} baseLink={baseLink} />
          </div>
        </div>
      </div>
    </Nav.Item>
  );
};

const TPELinks = ({ classes }) => {
  const availableMenus = {
    FINANCEMENT_CREATION: 'FINANCEMENT_CREATION',
    FINANCEMENT_CYCLE_EXPLOITATION: 'FINANCEMENT_CYCLE_EXPLOITATION',
    FINANCEMENT_DEVELOPPEMENT: 'FINANCEMENT_DEVELOPPEMENT',
  };
  const [activeMenu, setActiveMenu] = useState('');
  const changeActiveMenu = newMenu => {
    if (window.innerWidth > 992) {
      return;
    }
    newMenu === activeMenu ? setActiveMenu('') : setActiveMenu(newMenu);
  };
  return (
    <Nav className={`pl-4 ${classes.nav}`}>
      {(!activeMenu || activeMenu === availableMenus.FINANCEMENT_CREATION) && (
        <NavitemfinancementCreation
          label={institutionalWording.FINANCEMENT_CREATION}
          baseLink='/offres-tpe/financement-creation'
          items={subMenuTPEWording.FINANCEMENT_CREATION}
          classes={classes}
          customClass=''
          isActiveMenu={activeMenu === availableMenus.FINANCEMENT_CREATION}
          onMenuClicked={() =>
            changeActiveMenu(availableMenus.FINANCEMENT_CREATION)
          }
        />
      )}

      {(!activeMenu ||
        activeMenu === availableMenus.FINANCEMENT_CYCLE_EXPLOITATION) && (
        <NavitemfinancementCycleExploitation
          label={institutionalWording.FINANCEMENT_CYCLE_EXPLOITATION}
          baseLink='/offres-tpe/finanacement-cycle-exploitation'
          items={subMenuTPEWording.FINANCEMENT_CYCLE_EXPLOITATION}
          classes={classes}
          customClass=''
          isActiveMenu={
            activeMenu === availableMenus.FINANCEMENT_CYCLE_EXPLOITATION
          }
          onMenuClicked={() =>
            changeActiveMenu(availableMenus.FINANCEMENT_CYCLE_EXPLOITATION)
          }
        />
      )}

      {(!activeMenu ||
        activeMenu === availableMenus.FINANCEMENT_DEVELOPPEMENT) && (
        <NavitemfinancementDeveloppement
          label={institutionalWording.FINANCEMENT_DEVELOPPEMENT}
          baseLink='/offres-tpe/finanacement-developpement'
          items={subMenuTPEWording.FINANCEMENT_DEVELOPPEMENT}
          classes={classes}
          customClass=''
          isActiveMenu={activeMenu === availableMenus.FINANCEMENT_DEVELOPPEMENT}
          onMenuClicked={() =>
            changeActiveMenu(availableMenus.FINANCEMENT_DEVELOPPEMENT)
          }
        />
      )}
    </Nav>
  );
};

export default TPELinks;
