import React from 'react';
import tooltipIcon from 'assets/svg/tooltip.svg';
import { OverlayTrigger, Tooltip as BootsrapTooltip } from 'react-bootstrap';

const ToolTip = ({ children, title, placement = 'top', trigger = 'hover' }) => {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      delay={{ show: 150, hide: 200 }}
      overlay={
        <BootsrapTooltip id={`tooltip-${placement}`}>{title}</BootsrapTooltip>
      }
    >
      {children || <img src={tooltipIcon} alt={title} />}
    </OverlayTrigger>
  );
};

export default ToolTip;
