import { makeStyles } from '@material-ui/styles';
import { ReactComponent as ScrollDown } from 'assets/svg/modal-scroll-down.svg';
import { LegalNoticeConst } from 'common/constants/legalNotice';
import { footerWording } from 'common/locale';
import { LightDivider } from 'features/loanRequest/components/Dividers';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const useStyles = makeStyles(({ color }) => ({
  devider: {
    height: 6,
    backgroundColor: color.shallowGray,
  },
  deviderFull: ({ percentage }) => ({
    height: 6,
    backgroundColor: color.primary,
    width: `${percentage}%`,
  }),
  inlineDevider: {
    flexGrow: 2,
  },
  modal: {
    '& .modal-content': {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      
    },
  },
  body: {
    display: 'flex',
    maxHeight: 500,
    overflow: 'auto',
    paddingLeft: 64,
    paddingRight: 165,
    width: '85%',
    height: '100%',
    overflowY: 'scroll',
    boxSizing: 'content-box',
    scrollBehavior: 'smooth',
    '@media (max-height: 740px)': {
      maxHeight: 350,
    },
  },
  scrollDownContainer: {
    position: 'relative',
  },
  scrollDown: () => ({
    position: 'absolute',
    cursor: 'pointer',
    marginLeft: 20,
    bottom: `-${
      document.getElementById('modalContent')
        ? document.getElementById('modalContent').scrollTop
        : 0
    }px`,
  }),
  justif: {
    'text-align': 'justify',
  },
}));

function LegalNotice({ showLegalNotice, onHideLegaleNotice = {} }) {
  const [show, setShow] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const classes = useStyles({ percentage });

  const onHideLegaleNoticeClicked = () => {
    onHideLegaleNotice();
  };

  const handleScroll = () => {
    const { scrollHeight, scrollTop, offsetHeight } = document.getElementById(
      'modalContent',
    );
    setPercentage(() => (100 * (offsetHeight + scrollTop)) / scrollHeight);
  };

  useEffect(() => {
    const showModal = async () => {
      await setShow(showLegalNotice);
      if (showLegalNotice) {
        const {
          scrollHeight,
          scrollTop,
          offsetHeight,
        } = document.getElementById('modalContent');
        setPercentage(() => (100 * (offsetHeight + scrollTop)) / scrollHeight);
      }
    };
    showModal();
  });

  const handleScrollDown = () => {
    document.getElementById('modalContent').scrollTop =
      document.getElementById('modalContent').scrollHeight -
      document.getElementById('modalContent').offsetHeight;
  };

  return (
    <>
      <Modal show={show} className={`${classes.modal}`} size='lg'>
        <Modal.Header className={'pl-6 pr-6 pt-6'}>
          <Modal.Title className={'loanStepTitle'}>
            {footerWording.LEGAL_NOTICE}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          id={'modalContent'}
          className={classes.body}
          onScroll={handleScroll}
        >
          <span>
            {LegalNoticeConst.map(element => (
              <React.Fragment key={element.id}>
                <LightDivider light={true} title={element.title} />
                <div className={`mb-4 ${classes.justif}`}>
                  {element.content}
                </div>
              </React.Fragment>
            ))}
          </span>
          <div className={classes.scrollDownContainer}>
            <div className={classes.scrollDown} onClick={handleScrollDown}>
              <ScrollDown />
            </div>
          </div>
        </Modal.Body>
        <div className={` ${classes.devider} ${classes.inlineDevider}`}>
          <div className={` ${classes.deviderFull} ${classes.inlineDevider}`} />
        </div>
        <Modal.Footer className={` ustify-content-end`}>
          <Button
            className={`pl-6 pr-6 rounded-pill primaryButton`}
            onClick={onHideLegaleNoticeClicked}
          >
            {footerWording.CLOSE}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default LegalNotice;
