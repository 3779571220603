const production =
  process.env.REACT_APP_PROD &&
  process.env.REACT_APP_PROD.toLowerCase() !== 'false';

export const env = {
  apiPath: production
    ? `${window.location.origin}/${process.env.REACT_APP_API_PATH}`
    : process.env.REACT_APP_API_PATH,
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  googleMapKey: process.env.REACT_MAP_KEY,
  googleAnalyticKey: process.env.REACT_APP_GA_KEY,
  useReCaptcha:
    !!process.env.REACT_APP_USE_RECAPTCHA &&
    process.env.REACT_APP_USE_RECAPTCHA.toLowerCase() === 'true',
};
