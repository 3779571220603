import { call, put, select, takeLatest } from '@redux-saga/core/effects';
// import { toastTypes } from 'common/component/constants';
// import { ErrorToast } from 'common/component/Toast';
// import { Errors } from 'common/locale';

import generateRandomId from 'common/utils/generateRandomId.util';
import { apiGet, apiPost } from 'common/utils/request';
// import { createUploader } from 'common/utils/upload.util';
import { releaseRequestConstraints } from 'common/utils/validate/constraints';
// import { NATURE_ERROR } from 'common/utils/validate/errors';
import { costumValidate } from 'common/utils/validate/validate';
import { push } from 'connected-react-router';
// import { getLocation } from 'core/root.selector';
import { hideLoaderAction, showLoaderAction } from 'features/app/actions.app';
// import { customTakeLatest } from 'features/app/sagas.app';
// import { getAccoutTypeSelector } from 'features/auth/selectors.auth';
// import { loansListAt } from 'features/home/actions.loansList';
// import { initiatedLoanIdSelector } from 'features/home/selectors.loansList';
import { updateReleaseRequestsLine } from './actions.releaseRequest';
import { releaseRequestAt } from './actions.releaseRequest';
import {
  getNewReleaseRequestsListSelector,
  getFieldsSelector,
} from './selectors.releaseRequest';
import { getSelectedTiersSelector } from 'features/home/selectors.loansList';

export function* getReleaseRequestsAction() {
  try {
    yield put(showLoaderAction());
    const { data } = yield apiGet(
      `/creditEntreprise/demandeAttestation/getDemandeAttestations`,
    );
    yield put({
      type: releaseRequestAt.GET_REALEASE_REQUESTS_SUCCESS,
      releaseRequestsList: data?.demandeAttestations,
    });
    yield put(hideLoaderAction());
  } catch (err) {
    yield put(hideLoaderAction());
    console.error('cannot get Release Requests ', err);
  }
}

function* getReleaseRequestsSaga() {
  yield takeLatest(
    releaseRequestAt.GET_REALEASE_REQUESTS,
    getReleaseRequestsAction,
  );
}

// *************************************************************************************** //

export function* getReleaseRequestByIdAction(action) {
  try {
    const { idRelease } = action;
    yield put(showLoaderAction());
    const { data } = yield apiGet(
      `/creditEntreprise/demandeAttestation/getDemandeAttestationById/${idRelease}`,
    );
    yield put({
      type: releaseRequestAt.GET_REALEASE_REQUEST_DETAIL_SUCCESS,
      releaseRequestDetail: data?.demandeAttestations,
    });
    yield put(hideLoaderAction());
  } catch (err) {
    yield put(hideLoaderAction());
    console.error('cannot get Release Requests ', err);
  }
}

function* getReleaseRequestByIdActionSaga() {
  yield takeLatest(
    releaseRequestAt.GET_REALEASE_REQUEST_DETAIL,
    getReleaseRequestByIdAction,
  );
}
// *************************************************************************************** //

export function* getGuaranteesAction() {
  try {
    yield put(showLoaderAction());
    const { data } = yield apiGet(
      `/creditEntreprise/parametresDemande/Garanties`,
    );
    yield put({
      type: releaseRequestAt.GET_ALL_GUARANTEES_SUCCESS,
      guaranteesList: data.map(guarantee => ({
        value: guarantee.id,
        label: guarantee.label,
        garantiesDependencies: guarantee.garantiesDependencies,
      })),
    });
    yield put(hideLoaderAction());
  } catch (err) {
    yield put(hideLoaderAction());
    console.error('cannot get gaurantees ', err);
  }
}

function* getGuaranteesSaga() {
  yield takeLatest(releaseRequestAt.GET_ALL_GUARANTEES, getGuaranteesAction);
}

// *************************************************************************************** //

export function* getReleaseTypesAction() {
  try {
    yield put(showLoaderAction());
    const { data } = yield apiGet(
      `/creditEntreprise/demandeAttestation/getTypesAttestation`,
    );
    yield put({
      type: releaseRequestAt.GET_REALEASE_TYPES_SUCCESS,
      releaseTypes: data?.listAttestations?.map(type => ({
        value: type.id,
        label: type.label,
        isGuarantee: type.isGuarantee,
      })),
    });
    yield put(hideLoaderAction());
  } catch (err) {
    yield put(hideLoaderAction());
    console.error('cannot get Release Types ', err);
  }
}

function* getReleaseTypesSaga() {
  yield takeLatest(releaseRequestAt.GET_REALEASE_TYPES, getReleaseTypesAction);
}

// *************************************************************************************** //

export function* validateReleaseLineAction() {
  try {
    const fields = yield select(getFieldsSelector);
    const demandesAtestations = yield select(getNewReleaseRequestsListSelector);
    const errors = costumValidate(fields, releaseRequestConstraints);
    if (errors) {
      return yield put({
        type: releaseRequestAt.SET_REALEASE_REQUEST_ERRORS,
        errors,
      });
    }

    yield put({
      type: releaseRequestAt.SET_REALEASE_REQUEST_ERRORS,
      errors: {},
    });

    let newLine = {
      ...fields,
      //idGarantie: fields.guarantee.id,
    };

    let newDemandesattestations = [...demandesAtestations];

    // update line if it is already exist
    if (newLine.id) {
      newDemandesattestations = newDemandesattestations.map(line =>
        line.id === newLine.id ? newLine : line,
      );
    } else {
      // otherwise add a new one
      newLine.id = generateRandomId();
      newDemandesattestations = [...newDemandesattestations, newLine];
    }
    yield put(updateReleaseRequestsLine(newDemandesattestations));
  } catch (e) {
    console.error('add new line', e);
  }
}
function* validateReleaseLineSaga() {
  yield takeLatest(
    releaseRequestAt.VALIDATE_RELEASE_LINE,
    validateReleaseLineAction,
  );
}

export function* downloadFile(action) {
  try {
    const { idRelease, pathFile } = action;
    const uploadedFileUrl = `/creditEntreprise/demandeAttestation/downloadFileDemandeAttestation/${idRelease}`;
    const { data } = yield call(apiGet, uploadedFileUrl, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', pathFile);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    console.error("can't download this file ", e);
  }
}

function* downloadFileSaga() {
  yield takeLatest(releaseRequestAt.DOWNLOAD_RELEASE, downloadFile);
}

export function* submitReleaseRequest(action) {
  try {
    yield put(showLoaderAction());
    const companyName = yield select(getSelectedTiersSelector);
    // remove unused id
    const payload = action.releaseRequests.map(({ id, ...request }) => {
      return { ...request, company: companyName?.label };
    });
    const { data } = yield call(
      apiPost,
      '/creditEntreprise/demandeAttestation/setDemandeAttestation',
      payload,
    );

    if (data.errorCode) {
      //   yield put({
      //     type: loanRequestAt.STEP_ERRORS,
      //     errors: { globalError: [data.errorCode] },
      //   });
      return yield put(hideLoaderAction());
    }
    yield put({
      type: releaseRequestAt.SAVE_REALEASE_REQUEST_SUCCESS,
    });

    yield put(push(`/release/validation`));
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('error :', error);
  }
}
function* submitReleaseRequestSaga() {
  yield takeLatest(
    releaseRequestAt.SAVE_REALEASE_REQUEST,
    submitReleaseRequest,
  );
}

export default [
  getGuaranteesSaga,
  getReleaseTypesSaga,
  getReleaseRequestsSaga,
  getReleaseRequestByIdActionSaga,
  validateReleaseLineSaga,
  submitReleaseRequestSaga,
  downloadFileSaga,
];
