import { makeStyles } from '@material-ui/styles';
import { ReactComponent as Eye } from 'assets/svg/eye.svg';
import { loanRequestWording } from 'common/locale';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { REQUIRED_LABEL } from './constants';
import ToolTip from './ToolTip';

const useStyles = makeStyles(({ color }) => ({
  label: {
    color: color.inputColor,
    fontSize: 12,
    paddingLeft: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& > img': {
      marginLeft: 10,
    },
  },
  switchContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 25,
    paddingLeft: 0,
    '& > input:checked ~  label::after': {
      transform: 'translateX(2rem) !important',
    },
    '& label': {
      marginBottom: 0,
    },
  },
  input: ({ type }) => {
    return {
      color: color.inputColor,
      borderRadius: '1.5rem',
      borderColor: color.shallowGray,
      width: '100%',
      height: 40,
      minHeight: type === 'textarea' ? 120 : 0,
      paddingLeft: 25,
      paddingTop: type === 'textarea' ? '1rem' : '0.375rem',
      fontSize: 12,
      filter: 'none',
      '&:-webkit-autofill': {
        // To change autofill background-color in google Chrome
        WebkitBoxShadow: '0 0 0 30px white inset !important',
      },
      // Commented due to a warning in google chrome
      // '&:-moz-autofill': {
      //   // To change autofill background-color in google Chrome
      //   filter:
      //     'grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%)',
      // },
      '&.is-invalid': {
        backgroundPosition: 'center right calc(1em + 0.1875rem)',
      },
      '&[readonly], &:disabled': {
        backgroundColor: color.shallowGray,
      },
      '&::placeholder ': {
        color: color.darkGray,
      },
    };
  },
  customLabel: {
    position: 'relative',
    top: 16,
    left: 15,
    paddingRight: 6,
    paddingLeft: 6,
    background: 'white',
    fontSize: 11,
  },
  select: {
    color: color.inputColor,
    borderRadius: '1.5rem',
    borderColor: color.shallowGray,
    width: '100%',
    height: 40,
    paddingLeft: 20,
    fontSize: 12,
    '&[readonly], &:disabled': {
      backgroundColor: color.shallowGray,
    },
    '&.is-invalid': {
      backgroundPosition: 'center right calc(1em + 0.1875rem) !important',
      paddingRight: '5px',
    },
  },
  errorFeedback: {
    position: 'absolute',
    top: '100%',
    left: 20,
    marginTop: 2,
  },
  switch: ({ checked }) => {
    return {
      paddingBottom: 35,
      '&:before': {
        content: `'${
          checked
            ? loanRequestWording.entrepriseIdentificationStep
                .SWITCH_POSITIVE_VALUE
            : loanRequestWording.entrepriseIdentificationStep
                .SWITCH_NEGATIVE_VALUE
        }'`,
        fontSize: 11,
        color: 'white !important',
        padding: checked ? '6px 0 20px 5px' : '6px 0 20px 30px',
        height: 30,
        width: '60px !important',
        borderRadius: '50px !important',
        borderColor: (checked ? color.secondary : color.error) + ' !important',
        backgroundColor:
          (checked ? color.secondary : color.error) + ' !important',
      },
      '&:after': {
        padding: 11,
        marginTop: '2px',
        color: 'white !important',
        width: 'calc(1.9rem - 12px) !important',
        height: 'calc(1.9rem - 12px) !important',
        backgroundColor: 'white !important',
        borderRadius: '1.5rem !important',
      },
    };
  },
  currencyContainer: {
    position: 'relative',
  },
  currencySuffix: {
    color: color.darkBlue,
    fontSize: 12,
    position: 'absolute',
    bottom: 12,
    right: 30,
    fontFamily: 'tex_gyre_herosbold',
  },

  eye: {
    position: 'absolute',
    cursor: 'pointer',
    width: 22,
    right: 30,
    top: 8,
    '&:active': {
      '& .fill-hover': {
        fill: color.primary,
        stroke: color.primary,
      },
    },
  },
  checkbox: {
    width: '18px!important',
    height: '18px!important',
    display: 'iline-block!important',
  },
  labelCheckbox: {
    marginBottom: 0,
  },
}));

/* Input field */
export const Input = React.forwardRef(
  (
    {
      placeholder = '',
      value = '',
      onChange = () => {},
      label = '',
      name = 'input',
      error = '',
      className = '',
      customLabel = false,
      title,
      required,
      type = '',
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles({ type: rest.as || 'input' });
    return (
      <>
        {label && (
          <Form.Label
            className={`${classes.label} ${
              customLabel ? classes.customLabel : ''
            }`}
          >
            <ToolTip title={label}>
              <span>
                {label}
                {required && REQUIRED_LABEL}
              </span>
            </ToolTip>
            {title && <ToolTip title={title} />}
          </Form.Label>
        )}

        <Form.Control
          className={`${classes.input} ${className}`}
          isInvalid={!!error}
          name={name}
          placeholder={placeholder}
          value={value || ''}
          onKeyDown={evt =>
            evt.key === 'e' &&
            evt.target.type === 'number' &&
            evt.preventDefault()
          }
          onChange={onChange}
          ref={ref}
          type={type}
          {...rest}
        />
        {error && (
          <Form.Control.Feedback
            type='invalid'
            className={classes.errorFeedback}
          >
            {error[0]}
          </Form.Control.Feedback>
        )}
      </>
    );
  },
);

/* Select Field */
export const Select = ({
  options = [],
  value = '',
  placeholder = '',
  onChange = () => {},
  label = '',
  name = 'select',
  error = '',
  className = '',
  customLabel = false,
  required,
  title,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <>
      {label && (
        <Form.Label
          className={`${classes.label} ${
            customLabel ? classes.customLabel : ''
          }`}
        >
          <ToolTip title={label}>
            <span>
              {label}
              {required && REQUIRED_LABEL}
            </span>
          </ToolTip>
          {title && <ToolTip title={title} />}
        </Form.Label>
      )}
      <Form.Control
        className={`${classes.select}  ${className}`}
        {...rest}
        as='select'
        isInvalid={!!error}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        onChange={onChange}
      >
        {placeholder && (
          <option hidden className={classes.selectPlaceholder}>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option key={option.value} value={option.value || ''}>
            {option.label}
          </option>
        ))}
      </Form.Control>
      {error && (
        <Form.Control.Feedback type='invalid' className={classes.errorFeedback}>
          {error[0]}
        </Form.Control.Feedback>
      )}
    </>
  );
};

/* Radio Field */
export const Checked = ({
  options = [],
  value = '',
  placeholder,
  onChange = () => {},
  label = '',
  name = 'default',
  error = '',
  ...rest
}) => {
  const classes = useStyles();
  return (
    <>
      <Form.Label as='legend' className={classes.label}>
        {label}
      </Form.Label>
      <span>
        {options.map(option => (
          <Form.Check
            key={option.value}
            {...rest}
            type='radio'
            label={option.label}
            name={name}
            checked={option.checked}
            value={option.value}
            onChange={onChange}
          />
        ))}
      </span>
    </>
  );
};

/* Switch Field */
export const Switch = ({
  checked = false,
  onChange = () => {},
  label,
  name = 'default',
  id = 0,
  ...rest
}) => {
  const [localChecked, setLocalChecked] = useState(checked);
  const handleChange = () => {
    setLocalChecked(!localChecked);
    onChange(!localChecked);
  };

  useEffect(() => {
    setLocalChecked(checked);
  }, [checked]);
  const classes = useStyles({ checked: localChecked });

  return (
    <div className={`custom-control custom-switch ${classes.switchContainer}`}>
      {label && (
        <label htmlFor={`customSwitch${id}`} className={classes.label}>
          {label}
        </label>
      )}

      <input
        type='checkbox'
        className='custom-control-input'
        id={`customSwitch${id}`}
        name={name}
        value={localChecked}
        checked={localChecked}
        onChange={handleChange}
        {...rest}
      />
      <label
        className={`custom-control-label ${classes.switch}`}
        htmlFor={`customSwitch${id}`}
      ></label>
    </div>
  );
};

/* Currency Formatter Field */
// To resolve a bug, because customInput attribute of CurrencyFormat accept only function.
function CustomInput(props) {
  return <Input {...props} />;
}
export function CurrencyField({ onChange, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.currencyContainer}>
      <CurrencyFormat
        {...props}
        value={props.value || ''}
        displayType='input'
        thousandSeparator=' '
        decimalSeparator=', '
        fixedDecimalScale={true}
        decimalScale={2}
        onValueChange={values =>
          onChange({
            target: {
              name: props.name,
              value: values.floatValue,
            },
          })
        }
        customInput={CustomInput}
      />
      <span className={classes.currencySuffix}>MAD</span>
    </div>
  );
}

export const PasswordField = props => {
  const [type, setType] = useState('password');
  const toggleType = e => {
    e.preventDefault();
    setType(type === 'password' ? 'text' : 'password');
  };
  const classes = useStyles();
  return (
    <>
      <Input {...props} type={type} />
      <Eye
        className={classes.eye}
        onPointerDown={toggleType}
        onPointerUp={toggleType}
      />
    </>
  );
};

export const CheckBox = React.forwardRef(
  (
    {
      value = '',
      onChange = () => {},
      label = '',
      name = 'input',
      error = '',
      className = '',
      customLabel = false,
      title,
      type = '',
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles({ type: rest.as || 'input' });
    return (
      <>
        <div className='d-flex flex-row bd-highlight align-items-center mb-3'>
          <Form.Control
            className={`border-0 ${classes.input} ${classes.checkbox} ${className}`}
            isInvalid={!!error}
            name={name}
            checked={value}
            onKeyDown={evt =>
              evt.key === 'e' &&
              evt.target.type === 'number' &&
              evt.preventDefault()
            }
            onChange={onChange}
            ref={ref}
            type='checkbox'
            {...rest}
          />
          <Form.Label className={`ml-1 ${classes.labelCheckbox}`}>
            {label}
          </Form.Label>
        </div>
      </>
    );
  },
);
