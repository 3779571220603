import * as errorKeys from './errorKeys';

export const FORMAT_NOT_VALID = 'Le format est incorrect';
export const TOO_SHORT =
  'Le mot de passe est trop court (6 caractères minimum)';
export const RC_ERROR = 'Veuillez saisir au moins 4 caractères';
export const CODE_ERROR_LENGTH = 'Veuillez saisir au moins 6 chiffres';
export const LOGIN_OR_PASSWORD_NOT_VALID =
  'L’identifiant ou le mot de passe saisi est incorrect';
export const ACCOUNT_DISABLED = 'Votre compte est actuellement inactif';
export const CODE_ERROR_NUMERIC =
  'Veuillez uniquement saisir des caractères numériques';
export const FIELDS_REQUIRED = 'Ce champ est obligatoire';
export const AT_LEAST_ONE_GUARANTEE_REQUIRED =
  'Au moins une ligne de garantie est obligatoire.';
export const MISSING_DEPENDENCY_GUARANTEES =
  'Les garanties de dépendance manquent';
export const INVALID_ADDRESS_EMAIL = 'Adresse email non valide';
export const INVALID_PERCENTAGE = 'Veuillez saisir un pourcentage valide';
export const EMAIL_CONFIRMATION = 'Les emails saisis ne correspondent pas';
export const LOGIN_ATTEMPTS_EXCEEDED =
  "Nombre authentification atteint. Veuillez contacter l'administrateur";
export const INVALID_PASSWORD =
  'Minimum 8 caractères, au moins une majuscule, une minuscule, un chiffre et un caractère spécial';
export const INVALID_PASSWORD_LENGTH = 'Au moins 8 caractères';
export const PASSWORD_CONFIRMATION =
  'Les mots de passe entrés ne sont pas identiques';
export const NATURE_ERROR = 'Veuillez choisir une nature différente';
export const USER_NOT_AUTHORIZED =
  "L'identifiant entré ne correspond pas à une entreprise";

export const PASSWORD_ERRORS = {
  [errorKeys.USER_NOT_FOUND]: 'Utilisateur introuvable',
  [errorKeys.PASSWORD_ALREADY_USED]: 'Vous avez déja utilisé ce mot de passe',
  [errorKeys.PASSWORD_NOT_VALID]: "Mot de passe actuel n'est pas valide",
  [errorKeys.LOGIN_OR_PASSWORD_NOT_VALID]: LOGIN_OR_PASSWORD_NOT_VALID,
  [errorKeys.PASSWORDS_DONT_MATCH]: PASSWORD_CONFIRMATION,
  [errorKeys.USER_NOT_AUTHORIZED]: USER_NOT_AUTHORIZED,
};

export const RIB_ERRORS = {
  [errorKeys.RIB_NOT_VALID]: "RIB n'est pas valide",
  [errorKeys.USER_NOT_FOUND]: "RIB n'existe pas",
  [errorKeys.RIB_ALREADY_USED]: 'Ce RIB est dèja utilisé',
  racine: 'Doit avoir 5 chiffres',
  key: 'Doit avoir 2 chiffres',
};

export const LENGTH_100 = 'Ce champ ne doit pas dépasser 100 caractères';
export const LENGHT_10 = 'Ce champ ne doit pas dépasser 10 caractères';
export const ACCOUNT_ACTIVATION_ERRORS = {
  [errorKeys.USER_NOT_FOUND]: 'Utilisateur introuvable',
};
export const SHAREHOLDERS_REQUIRED = 'Veuillez saisir au moins un actionnaire';
export const MANAGEMENTS_REQUIRED =
  'Veuillez saisir au moins une personne du management';
export const INTERLOCUTORS_REQUIRED =
  'Veuillez saisir au moins un interlocuteur.';
export const LOANLINES_REQUIRED =
  'Merci de saisir au moins une ligne de crédit avant de passer à l’étape suivante.';
export const AGENCY_REQUIRED = 'Merci de choisir une des agences suivantes.';
