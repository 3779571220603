export const colors = {
  primary: '#2980B9',
  secondary: '#33CCCC',
  secondaryVariant: '#56dd92',
  darkBlue: '#2C3E50',
  darkGray: '#9F9F9F',
  shallowGray: '#ECF0F1',
  inputColor: '#6E6E6E',
  liteGray: '#FBFBFC',
  success: '#1DD1A1',
  error: '#FF6B6B',
  warning: '#FF9F43',
  fancyGray: '#e2e7e8',
  primaryLight: '#1BC4C5',
  primaryDark: '#3389D7',
  orange: '#ffaf6b',
  card: '#FDFDFD',
  gray: '#707070',
  types: '#858995',
  typesBg: '#f6f8f8',
  darkGreen: '#2C3E504D',
  personaliseGray: '#2C3E5080',
  brightBlue: '#368ce3',
};

const theme = {
  color: {
    ...colors,
  },
  background0: {
    '&:before': {
      background: 'none',
      transform: 'none',
    },
  },
  background1: {
    '&:before': {
      content: "''",
      background: `linear-gradient(to right, ${colors.primary} 0%, ${colors.secondary} 100%)`,
      borderRadius: 114,
      position: 'absolute',
      transform: 'rotate(45deg)',
      top: '-150px',
      left: '-550px',
      width: '1200px',
      height: '1200px',
      '@media (max-width: 4500px)': {
        left: '-400px',
      },
      '@media (max-width: 2700px)': {
        left: '-500px',
      },
      '@media (max-width: 1645px)': {
        left: '-600px',
      },
      '@media (max-width: 1310px)': {
        left: '-700px',
      },
      '@media (max-width: 1165px)': {
        left: '-850px',
      },
      '@media (max-height: 700px)': {
        top: '-250px',
      },
      '@media (max-width: 992px)': {
        top: '500px',
        left: '-600px',
      },
    },
  },
  background2: {
    '&:before': {
      content: "''",
      background: `linear-gradient(to right, ${colors.primary} 0%, ${colors.secondary} 100%)`,
      borderRadius: '4em 6em 4em 6em',
      position: 'absolute',
      transform: 'rotate(45deg)',
      top: '-284px',
      left: '-240px',
      width: '566px',
      height: '738px',
      '@media screen and (max-width: 1440px)': {
        left: '-350px',
      },
      '@media screen and (max-width: 1024px)': {
        left: '-450px',
      },
    },
  },
  background3: {
    '&:before': {
      content: "''",
      background: `linear-gradient(to top, ${colors.primary} 0%, ${colors.secondary} 100%)`,
      borderRadius: '4em 6em 4em 6em',
      position: 'absolute',
      left: '-240px',
      top: 0,
      width: '566px',
      height: '938px',
      transform: 'none',
    },
  },
};

export default theme;
