import { apiGet } from 'common/utils/request';
import authAT from 'features/auth/actions.auth';
import { isAuthenticatedSelector } from 'features/auth/selectors.auth';
import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  appAT,
  getFaqSuccess,
  getHomeNaturesSuccess,
  getHomeTPENaturesSuccess,
  getNotificationsSuccess,
  hideLoaderAction,
  showLoaderAction,
} from './actions.app';
import { APP_ENDPOINTES, refreshTokenDelay } from './constants.app';
import { selectApiLock } from './selector.app';

export function* refreshToken() {
  try {
    yield delay(refreshTokenDelay);
    yield put({ type: appAT.UPDATE_API_LOCK, apiLock: true });
    yield call(apiGet, APP_ENDPOINTES.REFRESH_TOKEN);
    yield put({ type: appAT.UPDATE_API_LOCK, apiLock: false });
    yield delay(refreshTokenDelay);
  } catch (e) {
    yield put({ type: appAT.UPDATE_API_LOCK, apiLock: false });
    yield delay(refreshTokenDelay);
  }
}

function* refreshTokenSaga() {
  while (true) {
    const isAuthenticated = yield select(isAuthenticatedSelector);
    if (!isAuthenticated) {
      yield delay(600000);

      yield take(authAT.SET_AUTH_SUCCESS);
      // we need to wait for 10 min after a new authentication before calling for refresh token.
      yield delay(refreshTokenDelay);
    }
    if (isAuthenticated) {
      yield refreshToken();
    }
  }
}

export function* getNotifications() {
  try {
    yield put(showLoaderAction('configLoader'));
    const { data } = yield call(apiGet, APP_ENDPOINTES.NOTIFICATIONS);

    yield put(getNotificationsSuccess(data.notifications));
    yield put(hideLoaderAction('configLoader'));
  } catch (e) {
    console.error('notification errors');
    yield put(hideLoaderAction('configLoader'));
  }
}

function* getNotificationSaga() {
  yield customTakeLatest(appAT.GET_NOTIFICATIONS, getNotifications);
}

export function* updateNotifications() {
  try {
    yield call(apiGet, '/creditEntreprise/notifications/updateNotifications');
    yield getNotifications();
  } catch (e) {
    console.error('notification errors');
  }
}

function* updateNotificationSaga() {
  yield takeLatest(appAT.UPDATE_NOTIFICATIONS, updateNotifications);
}

export function* getHomeNatures() {
  try {
    yield put(showLoaderAction());
    const { data } = yield call(
      apiGet,
      '/creditEntreprise/parametres/categorie',
    );
    yield put(getHomeNaturesSuccess(data));
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('error on homeNatures', error);
  }
}

function* getHomeNaturesSaga() {
  yield takeLatest(appAT.GET_HOME_NATURES, getHomeNatures);
}

export function* getHomeTPENatures() {
  try {
    yield put(showLoaderAction());
    const { data } = yield call(
      apiGet,
      '/creditEntreprise/parametres/categorieTPE',
    );
    yield put(getHomeTPENaturesSuccess(data));
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('error on homeTPENatures', error);
  }
}

function* getHomeTPENaturesSaga() {
  yield takeLatest(appAT.GET_HOME_TPE_NATURES, getHomeTPENatures);
}

export function* customTakeLatest(type, fun) {
  yield takeLatest(type, function*(action) {
    const apiLock = yield select(selectApiLock);
    if (!apiLock) {
      yield call(fun, action);
      return;
    }
    yield take(appAT.UPDATE_API_LOCK);
    yield put(action);
  });
}

export function* getFaq() {
  try {
    yield put(showLoaderAction());
    const { data } = yield call(apiGet, '/creditEntreprise/parametres/faq');
    yield put(getFaqSuccess(data));
    yield put(hideLoaderAction());
  } catch (e) {
    yield put(hideLoaderAction());
    console.error('faq', e);
  }
}

function* getFaqSaga() {
  yield takeLatest(appAT.GET_FAQ, getFaq);
}

export default [
  getFaqSaga,
  getNotificationSaga,
  refreshTokenSaga,
  updateNotificationSaga,
  getHomeNaturesSaga,
  getHomeTPENaturesSaga,
];
