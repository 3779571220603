import React, { Component } from 'react';
import loadable from '@loadable/component';
import { Button } from 'react-bootstrap';
import Loader from './loader';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '' };
    this.reload = this.reload.bind(this);
  }

  reload() {
    window.location.reload();
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='on-chunk-loading'>
          Error!
          <Button variant='outline-primary rounded-pill' onClick={this.reload}>
            Retry
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

const Loading = () => {
  return (
    <div className='on-chunk-loading'>
      <Loader light />
    </div>
  );
};

const loadComponent = option =>
  loadable(() => option.loader(), {
    fallback: <Loading />,
  });

export default loadComponent;
