export const authTypeConst = {
  BMCEDIRECT: 'BMCEDIRECT',
  BBO: 'BBO',
  SSO: 'SSO',
  PROFILE: 'PROFILE',
};

export const authStep = {
  FROM: 'form',
  TYPE: 'type',
};

export const variants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 50,
    },
  },
};

export const authFormVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 50,
      delay: 0.2,
    },
  },
};

export const childVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      delay: 0.2,
      damping: 90,
    },
  },
};

export const PROSPECT_MAX_AMOUNT = 50000000;
