import authSagas from 'features/auth/sagas.auth';
import appSagas from 'features/app/sagas.app';
import loanRequestSagas from 'features/loanRequest/sagas.loanRequest';
import loansListSagas from 'features/home/sagas.loansList';
import releaseListSagas from 'features/releaseRequest/sagas.releaseRequest';

export default [
  ...authSagas,
  ...appSagas,
  ...loansListSagas,
  ...loanRequestSagas,
  ...releaseListSagas,
];
