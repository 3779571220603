import {
  ENTREPRISE_IDENTIFICATION_STEP,
  GUARANTEES_STEP,
  LOAN_INFORMATION_STEP,
} from 'features/loanRequest/constants.loanRequest';
import {
  CODE_ERROR_NUMERIC,
  EMAIL_CONFIRMATION,
  FIELDS_REQUIRED,
  FORMAT_NOT_VALID,
  INTERLOCUTORS_REQUIRED,
  INVALID_ADDRESS_EMAIL,
  INVALID_PASSWORD,
  INVALID_PASSWORD_LENGTH,
  LENGTH_100,
  LOANLINES_REQUIRED,
  MANAGEMENTS_REQUIRED,
  MISSING_DEPENDENCY_GUARANTEES,
  PASSWORD_CONFIRMATION,
  RIB_ERRORS,
  SHAREHOLDERS_REQUIRED,
  TOO_SHORT,
  AGENCY_REQUIRED,
  INVALID_PERCENTAGE,
  LENGHT_10,
} from './errors';

const alphabeticContraint = /^[A-Za-z\u002E\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0027\u002d\u002C\s]+$/;
const alphaNumericContraint = /^[0-9A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0027\u002d\u002C\s]+$/;

export const authConstraint = {
  bmceDirectAuth: {
    login: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      length: {
        is: 12,
        wrongLength: FORMAT_NOT_VALID,
      },
    },
    password: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      length: {
        minimum: 6,
        tooShort: TOO_SHORT,
      },
    },
  },
  bboAuth: {
    login: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    password: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      length: {
        minimum: 6,
        tooShort: TOO_SHORT,
      },
    },
  },
  profileAuth: {
    login: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      length: {
        is: 10,
        wrongLength: FORMAT_NOT_VALID,
      },
    },
    password: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      length: {
        minimum: 8,
        tooShort: TOO_SHORT,
      },
    },
  },
};

export const signupConstraint = {
  clientIdentify: {
    rc: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      numericality: { message: CODE_ERROR_NUMERIC },
    },
    bc: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    tribunal: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    racine: {
      numericality: { message: CODE_ERROR_NUMERIC },
      length: {
        minimum: 5,
        maximum: 5,
        message: RIB_ERRORS.racine,
      },
    },
    key: {
      numericality: { message: CODE_ERROR_NUMERIC },
      length: {
        minimum: 2,
        maximum: 2,
        message: RIB_ERRORS.key,
      },
    },
    companyName: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      length: {
        maximum: 100,
        message: LENGTH_100,
      },
    },
    activityArea: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      length: {
        maximum: 100,
        message: LENGTH_100,
      },
    },
  },
  managerIdentify: {
    firstName: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    lastName: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    phone: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      numericality: { message: CODE_ERROR_NUMERIC },
    },
    job: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    mail: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      email: { message: INVALID_ADDRESS_EMAIL },
    },
    mailConfirmation: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      email: { message: INVALID_ADDRESS_EMAIL },
      equality: { attribute: 'mail', message: EMAIL_CONFIRMATION },
    },
  },
};

export const ChangePasswordConstraint = {
  password: {
    presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    regex: {
      pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
      message: INVALID_PASSWORD,
    },
    length: {
      minimum: 8,
      tooShort: INVALID_PASSWORD_LENGTH,
    },
  },
  passwordConfirmation: {
    presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    equality: { attribute: 'password', message: PASSWORD_CONFIRMATION },
  },
};

export const loanStepsConstraints = {
  entrepriseIdentification: {
    legalCategorie: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      regex: {
        pattern: alphabeticContraint,
        message: FORMAT_NOT_VALID,
      },
    },
    companyName: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      regex: {
        pattern: alphaNumericContraint,
        message: FORMAT_NOT_VALID,
      },
    },
    shareCapital: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      numericality: {
        greaterThan: 0,
        message: CODE_ERROR_NUMERIC,
      },
    },
    headOffice: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    activityArea: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      regex: {
        pattern: alphabeticContraint,
        message: FORMAT_NOT_VALID,
      },
    },
    description: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      regex: {
        pattern: alphabeticContraint,
        message: FORMAT_NOT_VALID,
      },
    },
    lastCaYear: (...params) => {
      const entrepriseIdentificationInfo = params[1];
      if (
        entrepriseIdentificationInfo.typeUser === 'ENTR' ||
        entrepriseIdentificationInfo.seniority !== 'M1'
      )
        return {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          numericality: {
            message: CODE_ERROR_NUMERIC,
            greaterThan: 0,
          },
        };
      return {};
    },
    lastCaAmount: (...params) => {
      const entrepriseIdentificationInfo = params[1];
      if (
        entrepriseIdentificationInfo.typeUser === 'ENTR' ||
        entrepriseIdentificationInfo.seniority !== 'M1'
      )
        return {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          numericality: {
            message: CODE_ERROR_NUMERIC,
            greaterThan: 0,
          },
        };
      return {};
    },
    previsionnelCA: (...params) => {
      const entrepriseIdentificationInfo = params[1];
      if (
        entrepriseIdentificationInfo.typeUser !== 'ENTR' &&
        entrepriseIdentificationInfo.seniority === 'M1'
      )
        return {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          numericality: {
            message: CODE_ERROR_NUMERIC,
            greaterThan: 0,
          },
        };
      return {};
    },
    seniority: (...params) => {
      const entrepriseIdentificationInfo = params[1];
      if (entrepriseIdentificationInfo.typeUser !== 'ENTR')
        return {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
        };
      return {};
    },
    rnae: (...params) => {
      const entrepriseIdentificationInfo = params[1];
      if (entrepriseIdentificationInfo.typeUser === 'AEPRO')
        return {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          regex: {
            pattern: alphaNumericContraint,
            message: FORMAT_NOT_VALID,
          },
        };
      return {};
    },
    profession: (...params) => {
      const entrepriseIdentificationInfo = params[1];
      if (
        entrepriseIdentificationInfo.typeUser === 'EIPRO' ||
        entrepriseIdentificationInfo.typeUser === 'PPPRO' ||
        entrepriseIdentificationInfo.typeUser === 'AEPRO'
      )
        return {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
        };
      return {};
    },
    shareholders: {
      presence: { message: SHAREHOLDERS_REQUIRED, allowEmpty: false },
      arrayOfElements: {
        keys: ['name', 'CIN', 'capitalPercentage'],
      },
    },
    managements: {
      presence: { message: MANAGEMENTS_REQUIRED, allowEmpty: false },
      arrayOfElements: {
        keys: ['name', 'CIN', 'phoneNumber'],
      },
    },
    interlocutors: {
      presence: { message: INTERLOCUTORS_REQUIRED, allowEmpty: false },
      arrayOfElements: {
        keys: ['name', 'phoneNumber'],
      },
    },
  },

  [GUARANTEES_STEP]: {
    demandeGaranties: {
      guarnteesDependencies: {
        message: MISSING_DEPENDENCY_GUARANTEES,
      },
    },
  },

  [LOAN_INFORMATION_STEP]: {
    account: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    loanLines: {
      presence: { message: LOANLINES_REQUIRED, allowEmpty: false },
    },
    renewalType: {
      renewalPresence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
  },

  fields: {
    [ENTREPRISE_IDENTIFICATION_STEP]: {
      shareholders: {
        name: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
        },
        CIN: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
        },
        capitalPercentage: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          numericality: {
            greaterThan: 0,
            lessThanOrEqualTo: 100,
            notLessThanOrEqualTo: INVALID_PERCENTAGE,
            notGreaterThan: INVALID_PERCENTAGE,
          },
        },
      },
      managements: {
        name: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
        },
        CIN: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          regex: {
            pattern: alphaNumericContraint,
            message: FORMAT_NOT_VALID,
          },
        },
        phoneNumber: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          length: {
            maximum: 10,
            message: LENGHT_10,
          },
          numericality: { message: CODE_ERROR_NUMERIC },
        },
      },
      interlocutors: {
        name: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
        },
        CIN: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          regex: {
            pattern: alphaNumericContraint,
            message: FORMAT_NOT_VALID,
          },
        },
        phoneNumber: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          length: {
            maximum: 10,
            message: LENGHT_10,
          },
          numericality: { message: CODE_ERROR_NUMERIC },
        },
        email: {
          presence: { message: FIELDS_REQUIRED, allowEmpty: false },
          email: { message: INVALID_ADDRESS_EMAIL },
        },
      },
    },
    [LOAN_INFORMATION_STEP]: {
      loanPurpose: {
        presence: { message: FIELDS_REQUIRED, allowEmpty: true },
      },
      amount: {
        presence: { message: FIELDS_REQUIRED, allowEmpty: false },
        numericality: {
          greaterThan: 0,
          message: CODE_ERROR_NUMERIC,
        },
      },
    },
    [GUARANTEES_STEP]: {
      guarantee: {
        presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      },
      guaranteeAmount: {
        presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      },
    },
  },
};

export const questionsConstraints = {
  questions: {
    questions: {
      message: FIELDS_REQUIRED,
    },
  },
};

export const releaseRequestConstraints = {
  // guarantee: {
  //   presence: { message: FIELDS_REQUIRED, allowEmpty: false },
  // },
  attestation: {
    presence: { message: FIELDS_REQUIRED, allowEmpty: false },
  },
  description: {
    presence: { message: FIELDS_REQUIRED, allowEmpty: false },
  },
};

export const prospectConstraint = {
  information: {
    legalCategorie: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    companyName: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    shareCapital: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    headOffice: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    lastCaAmount: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
    name: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      regex: {
        pattern: alphabeticContraint,
        message: FORMAT_NOT_VALID,
      },
    },
    contact: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
      length: {
        maximum: 10,
        message: LENGHT_10,
      },
      numericality: { message: CODE_ERROR_NUMERIC },
    },
    amount: {
      presence: { message: FIELDS_REQUIRED, allowEmpty: false },
    },
  },
  agency: {
    agence: {
      presence: { message: AGENCY_REQUIRED, allowEmpty: false },
    },
  },
};
