import React from 'react';
import { makeStyles } from '@material-ui/styles';
const animate1 = 'lds-ellipsis1';
const animate2 = 'lds-ellipsis2';
const animate3 = 'lds-ellipsis3';

const useStyle = makeStyles(({ color }) => ({
  loader: ({ absolute, light, transparent }) => {
    return {
      position: absolute ? 'absolute' : 'fixed',
      width: '100%',
      height: '100%',
      background: transparent
        ? 'transparent'
        : light
        ? 'rgba(255, 255, 255, 0.5)'
        : 'rgba(0, 0, 0, 0.7)',
      top: 0,
      left: 0,
      zIndex: 1051, // more than bootsrap modal 1050
    };
  },
  ldsEllipsis: {
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%)',
    width: '64px',
    height: '64px',
    '& div': {
      position: 'absolute',
      top: 0,
      width: 11,
      height: 11,
      borderRadius: '50%',
      background: 'white',
      animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
      '&:nth-child(1)': {
        left: 6,
        animation: `$${animate1} 0.6s infinite`,
      },
      '&:nth-child(2)': {
        left: 6,
        animation: `$${animate2} 0.6s infinite`,
      },
      '&:nth-child(3)': {
        left: 26,
        animation: `$${animate2} 0.6s infinite`,
      },
      '&:nth-child(4)': {
        left: 45,
        animation: `$${animate3} 0.6s infinite`,
      },
    },
  },
  ldsEllipsisLight: {
    '& div': {
      background: color.primary,
    },
  },
  [`@keyframes ${animate1}`]: {
    from: {
      transform: 'scale(0)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
  [`@keyframes ${animate2}`]: {
    from: {
      transform: 'translate(0, 0)',
    },
    to: {
      transform: 'translate(19px, 0)',
    },
  },
  [`@keyframes ${animate3}`]: {
    from: {
      transform: 'scale(1)',
    },
    to: {
      transform: 'scale(0)',
    },
  },
}));

export const Loader = props => {
  const classes = useStyle(props);
  return (
    <div className={classes.loader}>
      <div
        className={`${classes.ldsEllipsis} ${
          props.light ? classes.ldsEllipsisLight : ''
        }`}
      >
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
export default Loader;
