export const loanRequestAt = {
  LOAN_REQUEST_DATA: 'LOAN_REQUEST_DATA',
  RENEW_LOAN_REQUEST_DATA: 'RENEW_LOAN_REQUEST_DATA',
  LOAN_REQUEST_DATA_SUCCESS: 'LOAN_REQUEST_DATA_SUCCESS',
  SUBMIT_ENTREPRISE_IDENTIFICATION: 'SUBMIT_CLIENT_IDENTIFICATION',
  SUBMIT_ENTREPRISE_IDENTIFICATION_ERROR: 'SUBMIT_CLIENT_IDENTIFICATION_ERROR',
  UPDATE_INPUT: 'UPDATE_INPUT',
  GET_GUARANTEES: 'GET_GUARANTEES',
  SET_GUARANTEES_LIST: 'SET_GUARANTEES_LIST',
  UPDATE_GUARANTEES_LIST: 'UPDATE_GUARANTEES_LIST',
  SAVE_GUARANTEES: 'SAVE_GUARANTEES',
  SAVE_GUARANTEES_SUCCESS: 'SAVE_GUARANTEES_SUCCESS',
  SET_GUARANTEES_ERRORS: 'SET_GUARANTEES_ERRORS',
  VALIDATE_GUARANTEE_LINE: 'VALIDATE_GUARANTEE_LINE',
  SET_DOCUMENTS_LIST: 'SET_DOCUMENTS_LIST',
  DOWLOAD_FILE: 'DOWLOAD_FILE',
  FILE_UPLOAD_HANDLER: 'FILE_UPLOAD_HANDLER',
  GET_LOAN_TYPES: 'GET_LOAN_TYPES',
  GET_LOAN_TYPES_SUCCESS: 'GET_LOAN_TYPES_SUCCESS',
  SUBMIT_STEP: 'SUBMIT_STEP',
  STEP_ERRORS: 'STEP_ERRORS',
  ADD_NEW_ELEMENT: 'ADD_NEW_ELEMENT',
  VALIDATE_NEW_ELEMENT: 'VALIDATE_NEW_ELEMENT',
  UPDATE_LOAN_TYPE_CONFIG: 'UPDATE_LOAN_TYPE_CONFIG',
  LOAN_REQUEST_STEP_DATA_SUCCESS: 'LOAN_REQUEST_STEP_DATA_SUCCESS',
  INIT_LOAN_TYPE: 'INIT_LOAN_TYPE',
  UPDATE_CURRENT_STEP: 'UPDATE_CURRENT_STEP',
  UPDATE_ID_DEMANDE: 'UPDATE_ID_DEMANDE',
  VALIDATE_REQUEST: 'VALIDATE_REQUEST',
  REMOVE_FILES: 'REMOVE_FILES',
  GET_LOAN_INFORMATION_CONFIG: 'GET_LOAN_INFORMATION_CONFIG',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',

  INIT_ENTREPRISE_IDENTIFICATION: 'INIT_ENTREPRISE_IDENTIFICATION',
  UPDATE_LOAN_DATA: 'UPDATE_LOAN_DATA',

  INIT_STEP_DATA: 'INIT_STEP_DATA',

  CANCEL_LOAN_REQUEST: 'CANCEL_LOAN_REQUEST',
  CANCEL_LOAN_REQUEST_SUCCESS: 'CANCEL_LOAN_REQUEST_SUCCESS',
};

export const getLoanRequestDataAction = id => ({
  type: loanRequestAt.LOAN_REQUEST_DATA,
  id,
});
export const getRenewLoanRequestDataAction = id => ({
  type: loanRequestAt.RENEW_LOAN_REQUEST_DATA,
  id,
});

export const updateUploadedDocuments = files => ({
  type: loanRequestAt.SET_DOCUMENTS_LIST,
  files,
});

export const removeFiles = files => ({
  type: loanRequestAt.REMOVE_FILES,
  files,
});

export const downloadAndSaveFile = payload => ({
  type: loanRequestAt.DOWLOAD_FILE,
  ...payload,
});

export const handleFileUploadAction = params => ({
  type: loanRequestAt.FILE_UPLOAD_HANDLER,
  ...params,
});

export const getLoanRequestDataSuccessAction = loanRequestData => ({
  type: loanRequestAt.LOAN_REQUEST_DATA_SUCCESS,
  loanRequestData,
});

export const getStepDataSuccessAction = (step, stepData) => ({
  type: loanRequestAt.LOAN_REQUEST_STEP_DATA_SUCCESS,
  step,
  stepData,
});

export const updateLoanDataAction = (input, data) => ({
  type: loanRequestAt.UPDATE_LOAN_DATA,
  input,
  data,
});

export const updateInput = (step, name, value) => ({
  type: loanRequestAt.UPDATE_INPUT,
  value,
  name,
  step,
});

export const submitStepAction = (step, nextStep) => ({
  type: loanRequestAt.SUBMIT_STEP,
  step,
  nextStep,
});

export const stepErrorAction = (step, errors) => ({
  type: loanRequestAt.STEP_ERRORS,
  errors,
  step,
});

export const addNewElementAction = step => ({
  type: loanRequestAt.ADD_NEW_ELEMENT,
  step,
});

export const validateNewElementAction = step => ({
  type: loanRequestAt.VALIDATE_NEW_ELEMENT,
  step,
});

export const getGuarantees = () => ({
  type: loanRequestAt.GET_GUARANTEES,
});

export const validateNewGuaranteeLine = () => ({
  type: loanRequestAt.VALIDATE_GUARANTEE_LINE,
});

export const updateGuaranteeLine = guarantees => ({
  type: loanRequestAt.UPDATE_GUARANTEES_LIST,
  guarantees,
});

export const updateCurrentStep = step => ({
  type: loanRequestAt.UPDATE_CURRENT_STEP,
  step,
});

export const validateRequestAction = (step, nextStep) => ({
  type: loanRequestAt.VALIDATE_REQUEST,
  step,
  nextStep,
});
