import Contact from 'assets/svg/contact.svg';
import Faq from 'assets/svg/faq.svg';
import Home from 'assets/svg/home.svg';
import RequestLoan from 'assets/svg/request-loan.svg';
import IconList from 'assets/svg/icon-list.svg';
import { homeMenuWording } from 'common/locale';

export const ACCEPTED_LOANS = 'loanLines';
export const LOANS_REQUEST = 'listDemandes';
export const MOST_USED_NATURES = 'mostUsedNatures';
export const IS_QUESTIONS_MODAL = 'isQuestionsModal';
export const IS_FILES_MODAL = 'isFilesModal';
export const AVAILABLE_STATUS = {
  SENT: 'SENT',
  GES: 'GES',
  INITIATED: 'INITIATED',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
  MISSING_DOCUMENTS: 'MISSING_DOCUMENTS',
  MISSING_INFORMATIONS: 'MISSING_INFORMATIONS',
  INCOMPLETE: 'INCOMPLETE',
};

export const ACCEPTED_LOANS_STATUS = {
  OUVERT: 'OUVERT',
  TERMINE: 'TERMINE',
  EN_GESTION: 'EN_GESTION',
  A_RENOUVELER: 'A_RENOUVELER',
  ANNULE: 'ANNULE',
  TREATED: 'TREATED',
  REJECTED: 'REJECTED',
};

export const HOME_ENDPOINTS = {
  LIST_TIERS: '/creditEntreprise/parametres/listTiers',
  CONS_DEMANDES: '/creditEntreprise/consultationDemande/consDemandes',
  MOST_USED_NATURES: '/creditEntreprise/parametres/mostUsedNatures',
  LOAN_DETAILS: '/creditEntreprise/demande/stautDemande',
  REPLY_QUESTIONS: '/creditEntreprise/consultationDemande/setquestions',
  CONTACT: '/creditEntreprise/demande/agent',
};

export const variants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 50,
    },
  },
};

export const toastVariants = {
  hidden: {
    opacity: 0,
    y: -100,
    transition: {
      damping: 50,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      damping: 50,
      delay: 1.2,
    },
  },
};

export const menuContent = [
  {
    id: 'id1',
    title: { value: homeMenuWording.HOME, action: 'HOME', icon: Home },
  },
  {
    id: 'id2',
    title: {
      value: homeMenuWording.REQUEST_LOAN,
      action: '',
      icon: RequestLoan,
    },
    subTitle: [
      { value: homeMenuWording.OPERATING_CREDIT, action: 'REQUEST_LOAN' },
    ],
  },
  {
    id: 'id3',
    title: {
      value: homeMenuWording.MANAGE_MY_LOANS,
      action: '',
      icon: IconList,
    },
    subTitle: [
      { value: homeMenuWording.REVOLVE_LOAN, action: 'REVOLVE_LOAN' },
      {
        value: homeMenuWording.MODIFY_CONDICTION_LOAN,
        action: 'MODIFY_CONDICTION_LOAN',
      },
      {
        value: homeMenuWording.REQUEST_ATTESTATION,
        action: 'REQUEST_ATTESTATION',
      },
      {
        value: homeMenuWording.MONITORING_REQUESTS_ATTESTATION,
        action: 'MONITORING_REQUESTS_ATTESTATION',
      },
    ],
  },
];

export const menuFooter = [
  {
    id: 'CONTACT',
    title: { value: homeMenuWording.CONTACT, action: 'CONTACT', icon: Contact },
  },
  {
    id: 'FAQ',
    title: { value: homeMenuWording.FAQ, action: 'FAQ', icon: Faq },
  },
];
