import { releaseRequestAt } from './actions.releaseRequest';
import { fromJS } from 'immutable';

export const RELEASE_REQUEST_REDUCER = 'RELEASE_REQUEST_REDUCER';

export const initState = fromJS({
  releaseRequestsList: [],
  releaseRequestDetail: [],
  guaranteesList: [],
  releaseTypes: [],
  releaseRequestErrors: [],
  newReleaseRequsetsList: [],
  fields: { attestation: {}, guarantee: {}, description: '' },
  showConfirMessage: false,
});

export function releaseRequestReducer(state = initState, action) {
  switch (action.type) {
    case releaseRequestAt.GET_REALEASE_REQUESTS_SUCCESS:
      return state.set(
        'releaseRequestsList',
        fromJS(action.releaseRequestsList),
      );
    case releaseRequestAt.GET_REALEASE_REQUEST_DETAIL_SUCCESS:
      return state.set(
        'releaseRequestDetail',
        fromJS(action.releaseRequestDetail),
      );
    case releaseRequestAt.GET_REALEASE_TYPES_SUCCESS:
      return state.set('releaseTypes', fromJS(action.releaseTypes));

    case releaseRequestAt.GET_ALL_GUARANTEES_SUCCESS:
      return state.set('guaranteesList', action.guaranteesList);

    case releaseRequestAt.SET_REALEASE_REQUEST_ERRORS:
      return state.set('releaseRequestErrors', fromJS(action.errors));
    case releaseRequestAt.UPDATE_NEW_REALEASE_REQUEST_LIST:
      return state
        .set('newReleaseRequsetsList', fromJS(action.releaseRequests))
        .set(
          'fields',
          fromJS({
            attestation: {},
            guarantee: {},
            description: '',
          }),
        );
    case releaseRequestAt.SAVE_REALEASE_REQUEST_SUCCESS:
      return state
        .set('newReleaseRequsetsList', fromJS([]))
        .set(
          'fields',
          fromJS({
            attestation: {},
            guarantee: {},
            description: '',
          }),
        )
        .set('showConfirMessage', false);
    case releaseRequestAt.SHOW_CONFIRM_MESSAGE:
      return state.set('showConfirMessage', fromJS(true));
    case releaseRequestAt.HIDE_CONFIRM_MESSAGE:
      return state.set('showConfirMessage', fromJS(false));
    case releaseRequestAt.UPDATE_RELEASE_INPUT:
      return state.setIn([...action.name], fromJS(action.value));

    default:
      return state;
  }
}
