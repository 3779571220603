export const appAT = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_HOME_NATURES: 'GET_HOME_NATURES',
  GET_HOME_NATURES_SUCCESS: 'GET_HOME_NATURES_SUCCESS',
  GET_HOME_TPE_NATURES: 'GET_HOME_TPE_NATURES',
  GET_HOME_TPE_NATURES_SUCCESS: 'GET_HOME_TPE_NATURES_SUCCESS',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  SHOW_GENERAL_CONDITION_ACTION: 'SHOW_GENERAL_CONDITION_ACTION',
  GET_FAQ: 'GET_FAQ',
  GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
  UPDATE_API_LOCK: 'UPDATE_API_LOCK',
  SHOW_LEGALE_NOTICE: 'SHOW_LEGALE_NOTICE',
  HIDE_LEGALE_NOTICE: 'HIDE_LEGALE_NOTICE',
};

export const showLoaderAction = (name = 'loader') => ({
  type: appAT.SHOW_LOADER,
  name,
});

export const hideLoaderAction = (name = 'loader') => ({
  type: appAT.HIDE_LOADER,
  name,
});

export const getNotificationsSuccess = notifications => ({
  type: appAT.GET_NOTIFICATIONS_SUCCESS,
  notifications,
});

export const getHomeNatures = () => ({
  type: appAT.GET_HOME_NATURES,
});

export const getHomeNaturesSuccess = payload => ({
  type: appAT.GET_HOME_NATURES_SUCCESS,
  payload,
});

export const getHomeTPENatures = () => ({
  type: appAT.GET_HOME_TPE_NATURES,
});

export const getHomeTPENaturesSuccess = payload => ({
  type: appAT.GET_HOME_TPE_NATURES_SUCCESS,
  payload,
});

export const showGeneralConditionAction = value => ({
  type: appAT.SHOW_GENERAL_CONDITION_ACTION,
  value,
});

export const getFaqAction = () => ({
  type: appAT.GET_FAQ,
});

export const getFaqSuccess = faq => ({
  type: appAT.GET_FAQ_SUCCESS,
  faq,
});

export const showLegaleNotice = () => ({
  type: appAT.SHOW_LEGALE_NOTICE,
});

export const hideLegaleNotice = () => ({
  type: appAT.HIDE_LEGALE_NOTICE,
});
