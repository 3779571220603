import { makeStyles } from '@material-ui/styles';
import 'assets/scss/App.scss';
import 'assets/scss/custom-theme.scss';
import Footer from 'common/component/Footer';
import GoBack from 'common/component/GoBack';
import Header from 'common/component/header';
import LegalNotice from 'common/component/LegalNotice';
import Loader from 'common/component/loader';
import ToastContainer from 'common/component/Toast';
import {
  AUTH_STORAGE_KEY,
  getLocalStorage,
} from 'common/utils/localStorage.util';
import { getLocation } from 'core/root.selector';
import { getLoader, getShowLegaleNotice } from 'features/app/selector.app';
import GeneralCondition from 'features/auth/components/generalCondition/GeneralCondition';
import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'Routes';
import { hideLegaleNotice } from './features/app/actions.app';
import backImg from 'assets/svg/back.svg';
import { getSelectedTiersSelector } from 'features/home/selectors.loansList';
import { Link } from 'react-router-dom';

const getBackground = location => {
  switch (true) {
    case /auth/.test(location):
      return 'background1';
    case /prospect/.test(location):
    case /sign-up/.test(location):
    case /change-password/.test(location):
    case /profile/.test(location):
    case /validation/.test(location):
    case /terms/.test(location):
    case /faq/.test(location):
      return 'background2';
    case /entreprise-identification/.test(location):
    case /loan-information/.test(location):
    case /guarantee/.test(location):
    case /upload-documents/.test(location):
    case /additional-information/.test(location):
    case /recap-information/.test(location):
      return 'background3';
    default:
      return 'background0';
  }
};

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: '#fff',
    boxShadow: '0 5px 30px rgba(105, 106, 119, 0.1) !important',
  },
  container: props => {
    return {
      overflow: 'hidden',
      position: 'relative',
      minHeight: '100vh',
      ...theme[getBackground(props.location)],
    };
  },
  pageWrapper: {
    minHeight: 'calc(100vh - 395px)',
  },
  colWrapper: props => {
    const marginTop =
      window.innerWidth < 992 && props.location === '/auth' ? 0 : 60;
    return {
      marginTop: marginTop,
    };
  },
  back: {
    '& img': {
      width: '50px',
      height: 'auto',
      position: 'absolute',
      left: '6%',
      marginTop: '110px',
    },
  },
}));

function App() {
  const dispatch = useDispatch();
  const location = useSelector(getLocation);
  const loader = useSelector(getLoader('loader'));
  const configLoader = useSelector(getLoader('configLoader'));
  const classes = useStyles({ location });
  const authenticated = getLocalStorage(AUTH_STORAGE_KEY);

  const showLegalNoticePopup = useSelector(getShowLegaleNotice);
  const hideLegalNoticePopup = () => {
    dispatch(hideLegaleNotice());
  };
  const selectedtiers = useSelector(getSelectedTiersSelector);
  return (
    <div className={classes.container}>
      <GeneralCondition />
      <Row>
        <Col>
          {location !== '/home' &&
            (!location.startsWith('/release') ||
              location.endsWith('/validation')) &&
            !location.startsWith('/loan-details') &&
            !location.startsWith('/loan-details') &&
            !location.startsWith('/RenewalLoans') &&
            !location.startsWith('/ToModifyLoans') && (
              <Header location={location} />
            )}
          <GoBack location={location} />
        </Col>
      </Row>
      <Row className={classes.pageWrapper}>
        <Col
          className={
            location !== '/home' &&
            !location.startsWith('/RenewalLoans') &&
            (!location.startsWith('/release') ||
              location.endsWith('/validation')) &&
            !location.startsWith('/loan-details') &&
            !location.startsWith('/ToModifyLoans') &&
            classes.colWrapper
          }
        >
          <Routes />
        </Col>
        {(location === '/faq' || location === '/terms') && (
          <Button
            as={Link}
            to={authenticated ? '/home' : '/'}
            variant='link'
            className={classes.back}
          >
            <img src={backImg} alt='go back' />
          </Button>
        )}
      </Row>
      {location !== '/auth' &&
        location !== '/prospect' &&
        !location.startsWith('/authSSO') && (
          <Footer selectedtiers={selectedtiers} location={location} />
        )}

      {(loader || configLoader) && <Loader light />}

      <ToastContainer />
      <LegalNotice
        showLegalNotice={showLegalNoticePopup}
        onHideLegaleNotice={hideLegalNoticePopup}
      />
    </div>
  );
}
export default App;
