import { loansListWording } from 'common/locale';
import { apiGet, apiPost } from 'common/utils/request';
import { questionsConstraints } from 'common/utils/validate/constraints';
import { costumValidate } from 'common/utils/validate/validate';
import { hideLoaderAction, showLoaderAction } from 'features/app/actions.app';
import { customTakeLatest } from 'features/app/sagas.app';
import authAT from 'features/auth/actions.auth';
import { getLocation } from 'core/root.selector';
//import { useSelector } from 'react-redux';
import {
  loanRequestAt,
  updateInput,
} from 'features/loanRequest/actions.loanRequest';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { LOAN_INFORMATION_STEP } from '../loanRequest/constants.loanRequest';
import {
  getContactSuccess,
  getLoansSuccess,
  getMostUsedNaturesSuccess,
  getRequestTrackingSuccess,
  getTiersSuccess,
  loansListAt,
  replySuccessMessage,
  setSelectedTiers,
  toggleRequestModalAction,
  updateTrackingRequirement,
} from './actions.loansList';
import {
  AVAILABLE_STATUS,
  HOME_ENDPOINTS,
  IS_QUESTIONS_MODAL,
} from './constants.loansList';
import { getLoanDetailsSelector } from './selectors.loansList';

export function* getContact() {
  try {
    const { data } = yield call(apiGet, HOME_ENDPOINTS.CONTACT);
    yield put(getContactSuccess(data));
  } catch (e) {
    console.error("Can't get contact");
    yield put({ type: loansListAt.GET_CONTACT_ERROR });
  }
}

function* getContactSaga() {
  yield takeLatest(loansListAt.GET_CONTACT, getContact);
}

export function* getTiersSaga() {
  try {
    const { data } = yield call(apiGet, HOME_ENDPOINTS.LIST_TIERS);
    if (data.length === 0) {
      yield put({ type: authAT.LOG_OUT });
    }
    const selectedTiers = data.find(tiers => tiers.isSelected);
    yield put(getTiersSuccess(data));
    yield put(setSelectedTiers(selectedTiers));
  } catch (e) {
    console.error("Can't tiers's list");
  }
}

function* getTiersWatcher() {
  yield customTakeLatest(loansListAt.GET_TIERS_LIST, getTiersSaga);
}

export function* getLoanstListSaga(action) {
  try {
    yield put(showLoaderAction());
    const {
      data: { loanLines, listDemandes },
    } = yield call(apiPost, HOME_ENDPOINTS.CONS_DEMANDES, {
      idTiers: action.tiers,
    });
    yield put(
      getLoansSuccess(
        listDemandes,
        loanLines.filter(element => element.loanPurpose),
      ),
    );

    //const toasterViewed = getSessionStorage(TOASTER_CLOSED);

    const loan = listDemandes.find(
      request => request && request.statut === AVAILABLE_STATUS.INITIATED,
    );
    const location = yield select(getLocation);

    if (loan) {
      if (location === '/home') {
        yield put({ type: loansListAt.UPDATE_TOAST_IS_OPEN, value: true });
        yield put({
          type: loansListAt.UPDATE_INIT_LOAN_ID,
          value: loan.idDemande,
        });
        yield put({
          type: loanRequestAt.UPDATE_ID_DEMANDE,
          idDemande: loan.idDemande,
        });
      } else {
        yield put({ type: loansListAt.UPDATE_TOAST_IS_OPEN, value: false });
        yield put({
          type: loansListAt.UPDATE_INIT_LOAN_ID,
          value: loan.idDemande,
        });
        yield put({
          type: loanRequestAt.UPDATE_ID_DEMANDE,
          idDemande: loan.idDemande,
        });
      }
    }

    yield put(hideLoaderAction());
  } catch (e) {
    yield put(getLoansSuccess());
    yield put(hideLoaderAction());
    console.error("Can't get tiers's list");
  }
}

export function* getMostUsedNaturesSaga() {
  try {
    const { data } = yield call(apiGet, HOME_ENDPOINTS.MOST_USED_NATURES);
    yield put(getMostUsedNaturesSuccess(data));
  } catch (e) {
    console.error('Cant get most used natures');
  }
}

export function* getRequestTrackingSaga(action) {
  try {
    yield put(showLoaderAction());
    const { data } = yield call(
      apiGet,
      `${HOME_ENDPOINTS.LOAN_DETAILS}/${action.id}`,
    );
    yield put(getRequestTrackingSuccess(data));
    yield put(hideLoaderAction());
  } catch (e) {
    yield put(hideLoaderAction());
    console.error('Cant get request tracking');
  }
}

function* getLoansWatcher() {
  yield customTakeLatest(loansListAt.GET_LOANS_LIST, getLoanstListSaga);
}

function* getMostUsedNaturesWatcher() {
  yield customTakeLatest(
    loansListAt.GET_MOST_USED_NATURES,
    getMostUsedNaturesSaga,
  );
}

function* getRequestTrackingWatcher() {
  yield takeLatest(
    loansListAt.GET_REQUEST_TRACKING_DETAILS,
    getRequestTrackingSaga,
  );
}

export function* removeAdditionalFileSaga(action) {
  try {
    const { documents } = yield select(getLoanDetailsSelector);
    const payload = {
      idDemande: action.idDemande,
      idDocument: action.idDocument,
    };

    const newDocs = documents.map(doc => {
      if (doc.id === action.idDocument) {
        payload.other = !!doc.other;
        return {
          ...doc,
          pathSaved: '',
          fileSize: '',
          fileName: '',
        };
      }

      return doc;
    });

    yield put(showLoaderAction());
    yield call(apiPost, 'creditEntreprise/demande/deleteDocs', [payload]);
    yield put(updateTrackingRequirement('documents', newDocs));
    yield put(hideLoaderAction());
  } catch (e) {
    yield put(hideLoaderAction());
    console.error('file cannot be deleted ', e);
  }
}

export function* replyQuestionsSaga(action) {
  try {
    const { questions } = yield select(getLoanDetailsSelector);
    const errors = costumValidate({ questions }, questionsConstraints);

    if (errors && errors.questions) {
      return yield put(
        updateTrackingRequirement('errors', errors.questions[0]),
      );
    }

    yield put(showLoaderAction());
    yield put(updateTrackingRequirement('errors', {}));

    yield call(apiPost, HOME_ENDPOINTS.REPLY_QUESTIONS, {
      idDemande: action.idDemande,
      questions,
    });

    yield put(hideLoaderAction());
    yield put(toggleRequestModalAction(IS_QUESTIONS_MODAL));
    yield put(replySuccessMessage(loansListWording.REPLY_QUESTION_SUCCESS));
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('cant submit replies', error);
  }
}

function* removeAdditionalFileWatcher() {
  yield takeLatest(
    loansListAt.REMOVE_ADDITIONAL_FILE,
    removeAdditionalFileSaga,
  );
}

function* replyQuestionsWatcher() {
  yield takeLatest(loansListAt.REPLY_QUESTIONS, replyQuestionsSaga);
}

export function* selectLoanNature(action) {
  try {
    yield put(
      updateInput(LOAN_INFORMATION_STEP, ['fields', 'nature'], action.nature),
    );
    yield put({ type: loanRequestAt.LOAN_REQUEST_DATA });
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('cant submit replies', error);
  }
}

function* selectLoanNatureSaga() {
  yield takeLatest(loansListAt.SELECT_LOAN_NATURE, selectLoanNature);
}

export default [
  getTiersWatcher,
  getLoansWatcher,
  getMostUsedNaturesWatcher,
  getRequestTrackingWatcher,
  removeAdditionalFileWatcher,
  replyQuestionsWatcher,
  selectLoanNatureSaga,
  getContactSaga,
];
