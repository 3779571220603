/**
 * 
 * import React from 'react';

const justif = {
  textAlign: 'justify',
};
 */

export const GeneralConditionConst = [
  {
    id: 1,
    title: 'Article 1. Editeur du Site',
    content: `
        <p class='mb-3' style={justif} > 
          BANK OF AFRICA (EX « BMCE BANK ») -S.A. au Capital de 1.998.204.600 Dirhams
          - 140, avenue Hassan II - <span class='text-nowrap'>20 000</span> Casablanca - Maroc - BP 13425 CASA
          Ppal - Télex : <span class='text-nowrap'>21 931 - 24 004</span> <span class='text-nowrap'>+212 (0) 522 20 04 20</span>
           / <span class='text-nowrap'>+212 (0) 522 20 03 25</span> - Registre de Commerce : CASA 27129 - N° Identification Fiscale
          : 01085112 - Patente : 35502790 - CCP RABAT 1030 - CNSS <span class='text-nowrap'>10 2808 5</span> -
          <span class='general-condition-url'>
            www.creditbusinessonline.ma.
          </span>
        </p>
        <p class='mb-3' style={justif} >
          BANK OF AFRICA édite le site web accessible à l'adresse
          http://creditbusinessonline.ma
        </p>
        <p class='mb-3' style={justif} >
          L'utilisateur ne peut bénéficier des services qui lui sont proposés
          sur ce Site web que sous réserve de son acceptation des présentes
          conditions générales.
        </p>
        <p class='mb-3' style={justif} >
          L'utilisateur déclare avoir obtenu de la part de BANK OF AFRICA toutes
          les informations nécessaires quant aux services proposés et adhère
          sans réserve aux présentes conditions générales d'utilisation.
        </p>
        <p class='mb-3' style={justif} >
          L'utilisateur reconnait que l'utilisation de son espace personnel
          nécessite le respect de l'ensemble des prescriptions d'utilisations
          définies au sein des présentes, et de ce fait reconnaît disposer de la
          compétence et des moyens nécessaires pour accéder et utiliser cet
          espace personnel.
        </p>
      `,
  },
  {
    id: 2,
    title: 'Article 2. Définitions ',
    content: `
        <p class='mb-3' style={justif} >« La Banque » : désigne BANK OF AFRICA </p>
        <p class='mb-3' style={justif} >
          « Conditions générales d'utilisation » ou « CGU » : désigne le présent
          document
        </p>
        <p class='mb-3' style={justif} >
          « Code d'accès » : désigne indifféremment l'identifiant le mot de
          passe et le code répondant aux critères de sécurité instaurés par BANK
          OF AFRICA et ayant pour objet d'identifier et d'authentifier
          l'utilisateur pour les besoins de son accès à son espace personnel
        </p>
        <p class='mb-3' style={justif} >
          « Espace personnel » : environnement accessible par le biais des codes
          d'accès via internet aux utilisateurs ayant déjà adhéré au service
          Business online ou Bmce direct , ayant choisi de pouvoir bénéficier
          des services de la plateforme Credit Business Online.
        </p>
        <p class='mb-3' style={justif} >
          « Site » : désigne le site de la plateforme Credit Business Online de
          BANK OF AFRICA accessible à l'adresse http://creditbusinessonline.ma
        </p>
        <p class='mb-3' style={justif} >
          « Utilisateur » : désigne toute personne morale titulaire d'un compte
          bancaire ouvert sur les livres de BANK OF AFRICA et ayant déjà adhéré
          au service Business online ou bmce direct utilisant la plateforme de
          Credit Business Online.
        </p>
        <p class='mb-3' style={justif} >
          « Service de Credit Business Online » : désigne toutes fonctionnalités
          proposées dans l'espace personnel aux utilisateurs et dédiées à la
          gestion de leurs crédits accordés par la BANK OF AFRICA.
        </p>
        <p class='mb-3' style={justif} >A titre d’exemple* :</p>
        <ul>
          <li>
            Consultation de la situation des crédits octroyés par la banque
          </li>
          <li>Suivi des demandes de crédit initiés via le site</li>
          <li>Demandes de renouvellement des lignes de crédit</li>
          <li>Demandes de modification des conditions des lignes de crédit</li>
        </ul>
        Cette liste est non exhaustive et à titre purement indicatif.
      `,
  },
  {
    id: 3,
    title: 'Article 3. Objet ',
    content: `
        <p class='mb-3' style={justif} >
          Les présentes Conditions générales ont pour objet de :
        </p>
        <ul>
          <li>Définir les conditions d'accès et d'utilisation du Site</li>
          <li>
            Fournir des informations sur les services proposés par La Banque via
            la plateforme Crédit Business Online
          </li>
        </ul>
      `,
  },
  {
    id: 4,
    title: 'Article 4. Modification',
    content: `
        <p class='mb-3' style={justif} >
          La Banque se réserve le droit d'apporter aux présentes Conditions
          générales toutes les modifications qu'elle juge nécessaires et utiles,
          et la possibilité de modifier en tout ou partie les CGU afin de
          l'adapter, notamment, aux évolutions de son exploitation, et/ou à
          l'évolution de la législation et/ou aux évolutions des services
          proposés.
        </p>
        <p class='mb-3' style={justif} >
          Les présentes Conditions générales sont opposables pendant toute la
          durée d'utilisation de l'Espace personnel et jusqu'à ce que de
          nouvelles conditions générales d'utilisation remplacent les présentes.
        </p>
        <p class='mb-3' style={justif} >
          La Banque communiquera à l'Utilisateur les nouvelles conditions
          générales d'utilisation par tout moyen, et ce, dès leur date d'entrée
          en vigueur.
        </p>
        <p class='mb-3' style={justif} >
          Par ailleurs, tout usage de l'Espace personnel par l'Utilisateur après
          les modifications des Conditions générales d'utilisation vaut
          acceptation sans réserve par ce dernier.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur doit informer La Banque par écrit en cas de non
          acceptation de toute modification des Conditions générales
          d'utilisation.
        </p>

        <p class='mb-3' style={justif} >
          Les Conditions générales figurant en ligne sur le Site prévalent sur
          toute version imprimée de date antérieure.
        </p>
        `,
  },
  {
    id: 5,
    title: "Article 5. Accès au Site et à l'Espace personnel",
    content: `
        <h5>5.1 Modalités</h5>
        <p class='mb-3' style={justif} >
          L'Utilisateur est informé que le Site est fourni sur la base d'un
          service « en l'état de l'art » et accessible en fonction de sa
          disponibilité, 24 h sur 24 et 7 jours sur 7, sauf en cas de force
          majeure, difficultés informatiques, difficultés liées aux réseaux de
          télécommunications ou autres difficultés techniques. L’accès au
          Service Credit Business Online est gratuit pour tout client entreprise
          équipé des produits BMCE Direct ou Business Online , et hors
          fournisseurs d’accès Internet.
        </p>
        <p class='mb-3' style={justif} >
          L'accès au Service Credit Business Online nécessite que l'Utilisateur
          soit titulaire d’un compte bancaire ouvert sur les livres de BANK OF
          AFRICA, une convention d'ouverture de compte bancaire auprès de La
          Banque, opté pour les Services BMCE Direct ou Business Online et
          accepté les présentes Conditions générales d'utilisation ainsi que
          leurs évolutions éventuelles.
        </p>
        <p class='mb-3' style={justif} >
          La Banque se réserve le droit, sanspréavis, ni indemnité, de fermer
          temporairement ou définitivement l'Espace personnel ou de suspendre
          l'accès à un ou plusieurs Services de Credit Business Online pour
          effectuer une mise à jour, des modifications ou changement sur les
          méthodes opérationnelles, les serveurs et les heures d'accessibilité,
          sans que cette liste ne soit limitative.
        </p>
        <p class='mb-3' style={justif} >
          La banque se réserve le droit également de compléter ou de modifier, à
          tout moment, les Services de Credit Business Online et l’ Espace
          personnel de l’utilisateur Il appartient, donc, à l'Utilisateur de
          veiller à la compatibilité de ses moyens informatiques, aux évolutions
          du Site, de son Espace personnel et des Services de Credit Business
          Online.
        </p>
        <p class='mb-3' style={justif} >
          La Banque ne saurait être tenue responsable de l'impossibilité
          d'accéder au Site.
        </p>
        <h5>5.2 Code d'accès</h5>
        <h6>5.2.1 Connexion de l'Utilisateur à l’espace personnel</h6>
        <p class='mb-3' style={justif} >
          Tout client équipé de BMCE Direct ou Business Online peut accéder
          à la plateforme Credit Business Online en s’authentifiant via ses
          codes d’accès BMCE Direct ou Business Online.
        </p>
        <h6>5.2.2 Dispositions communes à tous les Utilisateurs</h6>
        <p class='mb-3' style={justif} >
          Les conditions générales d’utilisation doivent être acceptées par les
          Utilisateurs souhaitant accéder au site.
        </p>
        <p class='mb-3' style={justif} >
          L’accès au site par l’Utilisateur vaut acceptation des présentes CGU.
          En cas de non acceptation des CGU, l’Utilisateur doit renoncer à
          l’accès aux services proposés .
        </p>
        <p class='mb-3' style={justif} >
          Dès la reconnaissance de ses Codes d'accès, l'Utilisateur bénéficie
          d'un accès sécurisé.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur est seul responsable de la préservation et de la
          confidentialité de son identifiant, de son mot de passe et, par
          conséquent, des conséquences d'une divulgation volontaire ou
          involontaire à quiconque.
        </p>
        <p class='mb-3' style={justif} >
          Toute utilisation de l'Espace personnel et, en conséquence, toute
          opération réalisée à partir de cet Espace, à partir de l'identifiant,
          du mot de passe attribués à l'Utilisateur est présumée être exécutée
          par ce dernier. Les identifiant, mot de passe et en général tout autre
          code d'accès sont strictement personnels et confidentiels. La Banque
          recommande vivement à l'Utilisateur de se déconnecter de sa session et
          de fermer la fenêtre de son navigateur à l'issue de sa session de
          travail ainsi que de supprimer les traces de sa navigation dans
          l'historique de ses connexions internet pour éviter ainsi que d'autres
          personnes n'accèdent à ses informations personnelles.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur a l'obligation de notifier à La Banque sans délai toute
          compromission de la confidentialité de son identifiant, de son mot de
          passe ou toute utilisation par un tiers dont il aurait connaissance.
        </p>
        <h5>5.3 Refus d'accès</h5>
        <p class='mb-3' style={justif} >
          La Banque se réserve le droit de suspendre l'accès aux Services de
          Credit Business Online nécessitant l'identification de l'Utilisateur
          si elle devait relever des faits laissant présumer l'utilisation
          frauduleuse ou la tentative d'utilisation frauduleuse de ses services
          ou que l'Utilisateur a communiqué des informations inexactes se
          rapportant à son identité, elle en informe aussitôt l'Utilisateur, par
          tout moyen à sa convenance ce qui est expressément accepté par ce
          dernier.
        </p>
        `,
  },
  {
    id: 6,
    title: 'Article 6. Sécurité',
    content: `
        <p class='mb-3' style={justif} >
          Le Site est un système de traitement automatisé de données. Tout accès
          frauduleux, notamment aux espaces personnels qu'il renferme, est
          interdit et sanctionné pénalement.
        </p>
        <p class='mb-3' style={justif} >
          La Banque déploie ses meilleurs efforts, conformément aux règles de
          l'art, pour sécuriser l'Espace personnel mais, eu égard à la
          complexité de l'internet, elle ne saurait assurer une sécurité
          absolue.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur déclare accepter les caractéristiques et limites de
          l'internet et, notamment, il reconnaît avoir connaissance de la nature
          du réseau de l'internet, et en particulier, de ses performances
          techniques et des temps de réponse pour consulter, interroger ou
          transférer les données.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur a conscience que les données circulant sur l'internet ne
          sont pas nécessairement protégées, notamment contre les détournements
          éventuels.
        </p>

        <p class='mb-3' style={justif} >
          L'Utilisateur accepte de prendre toutes les mesures appropriées de
          façon à protéger ses propres données et/ou logiciels de la
          contamination par des éventuels virus sur le réseau de l'internet.
        </p>
        `,
  },
  {
    id: 7,
    title: 'Article 7. Responsabilité',
    content: `
        <p class='mb-3' style={justif} >
          L'Utilisateur s'engage à n'utiliser l'Espace personnel et les
          informations auxquelles il aurait accès que dans les seules conditions
          définies par la Banque.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur s'engage à (i) ne pas perturber l'usage que pourraient
          faire les autres utilisateurs du Site et de ne pas accéder aux espaces
          de tiers (ii) à ne commettre aucun acte pouvant mettre en cause la
          sécurité informatique de la Banque ou des autres utilisateurs ou
          visiteurs (iii) à ne pas interférer ou interrompre le fonctionnement
          normal de l'Espace personnel ou du Site.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur s'interdit notamment de modifier, copier, reproduire,
          télécharger, diffuser, transmettre, exploiter commercialement et/ou
          distribuer de quelque façon que ce soit les services, les pages du
          Site, ou les codes informatiques des éléments composant les services
          et le Site.
        </p>
        <p class='mb-3' style={justif} >
          Etant donné la diversité des sources de données concernant
          l'Utilisateur, des modalités de leur consultation et des délais pour
          réaliser leur transmission, la Banque fait son possible afin de
          garantir la qualité générale des informations diffusées et leur
          pertinence.
        </p>
        <p class='mb-3' style={justif} >
          Seuls les relevés de comptes, avis d'opérations périodiques... etc.
          feront foi. Les autres informations affichées sur l'Espace personnel
          de l'Utilisateur le sont à titre purement indicatif.
          <br />
          La responsabilité de la Banque ne pourra être recherchée en raison du
          caractère incomplet ou inexact de telles informations.
        </p>
        <p class='mb-3' style={justif} >
          La responsabilité de la Banque ne pourra être recherchée ni retenue en
          cas d'indisponibilité temporaire ou totale de tout ou partie de
          l'accès au Site ou à l'Espace personnel, d'une difficulté liée au
          temps de réponse, et d'une manière générale, d'un défaut de
          performance quelconque.
        </p>
        <p class='mb-3' style={justif} >
          La Banque ne saurait être responsable des atteintes à la sécurité
          informatique, pouvant causer des dommages aux matériels informatiques
          de l'Utilisateur et à ses données.
        </p>
        <p class='mb-3' style={justif} >
          La responsabilité de la Banque ne saurait être recherchée en cas
          d'usage frauduleux ou abusif ou dû à une divulgation volontaire ou
          involontaire à quiconque de l'identifiant, du mot de passe, du Code de
          réinitialisation et du Code à usage unique de l'Utilisateur.
        </p>
        <p class='mb-3' style={justif} >
          Sauf faute ou négligence prouvée de la Banque, les atteintes à la
          confidentialité des données personnelles de l'Utilisateur résultant de
          l'usage de son identifiant, de son mot de passe, du Code de
          réinitialisation ou de son Code à usage unique par un tiers ne
          sauraient engager la responsabilité de la Banque.
        </p>
        <p class='mb-3' style={justif} >
          La Banque ne saurait être responsable de la violation des présentes
          Conditions générales d’Utilisation par un autre utilisateur.
        </p>
        <p class='mb-3' style={justif} >
          La responsabilité de la Banque ne pourra être engagée en cas de
          dommages indirects résultant de l'utilisation des Services de banque
          en ligne. La responsabilité de la Banque ne pourra pas être recherchée
          ni retenue si d'éventuelles collectes et transmission de données
          personnelles, d'installation de cookies ou tout autre procédé tendant
          aux mêmes fins, sont effectués par des sites vers lesquels pointent
          des liens hypertextes accessibles depuis le Site ou accessibles par
          rebond.
        </p>
        <p class='mb-3' style={justif} >
          La Banque se réserve le droit de prendre toutes les mesures qu'elle
          jugerait adéquates afin d'empêcher ou de mettre un terme à l'atteinte
          à ses droits d'auteur ou aux droits d'auteur de tiers, sans qu'aucune
          responsabilité ne puisse lui être imputée de ce fait.
        </p>
        `,
  },
  {
    id: 8,
    title: 'Article 8. Propriété intellectuelle ',
    content: `
        <p class='mb-3' style={justif} >
          Les présentes Conditions générales d’Utilisation n'emportent aucune
          cession d'aucune sorte de droits de propriété intellectuelle sur les
          éléments appartenant à La Banque au bénéfice de l'Utilisateur.
        </p>
        <p class='mb-3' style={justif} >
          Le Site, les marques, les dessins, les modèles, les images, les
          textes, les photos, les logos, les chartes graphiques, les logiciels,
          les moteurs de recherche, les bases de données et les noms de domaine,
          sans que cette liste ne soit exhaustive, sont la propriété exclusive
          de la Banque.
        </p>
        <p class='mb-3' style={justif} >
          Toute reproduction et/ou représentation, totale ou partielle d'un de
          ces droits, sans l'autorisation expresse de La Banque, est interdite
          et constituerait une contrefaçon susceptible d'engager la
          responsabilité civile et pénale du contrefacteur.
        </p>
        <p class='mb-3' style={justif} >
          En conséquence, l'Utilisateur s'interdit tout agissement et tout acte
          susceptible de porter atteinte directement ou non aux droits de
          propriété intellectuelle de la Banque.
        </p>
        <p class='mb-3' style={justif} >
          La Banque confère à l'Utilisateur un droit d'usage privé, non
          collectif et non exclusif, sur le contenu du Site. Ce droit d'usage
          comprend le droit de reproduire pour stockage aux fins de
          représentation sur écran monoposte et de reproduction pour tirage sur
          papier. Toute mise en réseau, toute rediffusion, sous quelque forme,
          même partielle, est donc interdite. Ce droit est personnel, il est
          réservé à l'usage exclusif de l'Utilisateur. Il n'est transmissible en
          aucune manière.
        </p>
        <p class='mb-3' style={justif} >L'Utilisateur s'engage en conséquence à :</p>

        <div class='mb-3' style={justif} >
          <ul>
            <li>
              ne télécharger sur son ordinateur le contenu du Site que pour un
              usage personnel et limité dans le temps
            </li>
            <li>
              n'imprimer sur support papier les pages du Site téléchargées qu'à
              la condition que lesdites copies soient strictement limitées à un
              usage personnel.
            </li>
          </ul>
        </div>

        <p class='mb-3' style={justif} >
          L'Utilisateur s'engage à ne pas copier, reproduire, altérer, modifier,
          créer des œuvres dérivées ou divulguer au public tout ou partie du
          contenu du Site sans le consentement préalable et écrit de La Banque
          ou celui des tiers concernés.
        </p>
        `,
  },
  {
    id: 9,
    title: 'Article 9. Cookies ',
    content: `
        <p class='mb-3' style={justif} >
          L'Utilisateur est informé que, lors de ses visites sur le Site, un
          cookie peut s'installer automatiquement sur son logiciel de navigation
          et être temporairement conservé en mémoire sur son disque dur. Un
          cookie est un élément qui ne permet pas d'identifier l'Utilisateur
          mais sert à enregistrer des informations relatives à la navigation de
          celui-ci sur le Site.
        </p>
        <p class='mb-3' style={justif} >
          Ces informations sont utilisées aux fins d'optimisation de la sécurité
          du Site et de fournir un service optimal.
        </p>
        <div class='mb-3' style={justif} >
          Ainsi, les cookies sont utilisées pour :
          <br />
          <br />
          <ul>
            <li>
              Gérer les paramètres personnels lors de la connexion de
              l'Utilisateur au Site sécurisé
            </li>
            <li>
              Enregistrer des informations sur la navigation de l'Utilisateur
              sur le Site pour orienter sa navigation vers les contenus les
              mieux adaptés
            </li>
            <li>Mesurer le nombre de visites sur le Site</li>
            <li>Evaluer l'efficacité de nos campagnes de promotion interne.</li>
          </ul>
        </div>
        `,
  },
  {
    id: 10,
    title: 'Article 10. Liens hypertextes',
    content: `
        <p class='mb-3' style={justif} >
          Les liens hypertextes établis en direction d'autres sites ne sauraient
          engager la responsabilité de la Banque, notamment s'agissant du
          contenu de ces sites. La Banque n'étant pas l'éditeur de ces sites,
          elle ne peut en contrôler de manière parfaite le contenu.
        </p>
        <p class='mb-3' style={justif} >
          L'existence d'un lien du Site de la Banque vers un autre site ne
          constitue pas par conséquent, une recommandation ni une validation de
          ce site ou de son contenu. Il appartient à l'Utilisateur d'utiliser
          ces informations avec discernement et esprit critique.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur du Site ne peut en aucun cas mettre en place un lien
          hypertexte en direction d'un autre site, même édité à titre personnel,
          y compris un site de réseau social quel qu'il soit, sans
          l'autorisation écrite et préalable de la Banque.
        </p>
        `,
  },
  {
    id: 11,
    title: 'Article 11. Données à caractère personnel ',
    content: `
        <h5>11.1 Collecte</h5>
        <p class='mb-3' style={justif} >
          L'utilisation du Site nécessite la communication par l'Utilisateur de
          données à caractère personnel.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur s'engage à ce titre à ne communiquer que des
          informations exactes et pertinentes. Il tiendra informé La Banque de
          tout changement de ces informations.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur donne expressément son consentement pour que les données
          à caractère personnel le concernant soient collectées dans ce cadre.
        </p>
        <p class='mb-3' style={justif} >
          Les coordonnées des Utilisateurs ne sont utilisées que pour mieux les
          informer et, éventuellement, ne leur adresser que les informations
          qu'ils souhaitent recevoir.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur accepte que les données à caractère personnel le
          concernant soient communiquées et partagées pour les mêmes finalités
          entre les Filiales et les sociétés du Groupe BANK OF AFRICA ainsi qu'à ses
          partenaires, sous-traitants et prestataires dans la limite nécessaire
          à l'accomplissement des tâches qui leurs sont confiées.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur déclare avoir pris connaissance et accepte que par
          l'inscription sur le Site, ses données à caractère personnel soient
          partagées entre l'ensemble des Filiales et sociétés du Groupe BANK OF AFRICA.
        </p>
        <p class='mb-3' style={justif} >
          En outre, l'Utilisateur s'engage à fournir des informations exactes,
          complètes et d'actualité. Dans le cas où les informations fournies
          sont inexactes, incomplètes ou obsolètes, La Banque prendra toute
          mesure qu'elle jugera utile pour suspendre l'accès de l'Utilisateur au
          Site, définitivement ou provisoirement.
        </p>
        <p class='mb-3' style={justif} >
          La Banque s'engage à conserver les données personnelles pendant une
          durée n'excédant pas celle nécessaire à la réalisation des finalités
          pour lesquelles elles sont collectées ou traitées.
        </p>
        <p class='mb-3' style={justif} >
          La Banque pourra être obligée de divulguer toute information
          concernant l'Utilisateur afin de se conformer à toute loi ou
          réglementation en vigueur, ou pour répondre à toute demande judiciaire
          ou administrative.
        </p>
        <h5>11.2 Droits de l'Utilisateur :</h5>
        <p class='mb-3' style={justif} >
          Conformément à la loi 09-08 relative à la protection des personnes
          physiques à l'égard du traitement des données à caractère personnel,
          l'Utilisateur dispose d'un droit d'accès, de rectification des données
          personnelles le concernant, en application des articles 7 à 8 de la
          loi n°09-08 précitée.
        </p>
        <p class='mb-3' style={justif} >
          L'Utilisateur peut également, pour des motifs légitimes, s'opposer au
          traitement des données personnelles le concernant, sauf lorsqu'elles
          sont recueillies en vertu d'une loi. Cette opposition peut toutefois
          entraîner l'impossibilité pour l'Utilisateur à accéder au Site.
        </p>
        <div class='mb-3' style={justif} >
          Pour l'exercice de ces droits l'Utilisateur doit respecter la
          procédure suivante :
          <br />
          <br />
          <ul>
            <li>
              Demande écrite et signée par l'Utilisateur à laquelle est jointe
              une photocopie de sa pièce d'identité
            </li>
            <li>
              Demande adressée à l'agence auprès de laquelle l'Utilisateur a
              souscrit au Service de banque en ligne
            </li>
          </ul>
        </div>
        `,
  },
  {
    id: 12,
    title: 'Article 12. Prospection commerciale ',
    content: `
        <p class='mb-3' style={justif} >
          La prospection commerciale par courrier électronique est autorisée si
          les coordonnées du destinataire ont été recueillies dans le respect
          des dispositions de la loi 09-08 relative à la Protection des
          Personnes Physiques à l'égard du Traitement des Données à Caractère
          Personnel.
        </p>

        <p class='mb-3' style={justif} >
          L'Utilisateur dispose de la faculté de s'opposer, sans frais, à ce que
          les données le concernant soient utilisées à de telles fins.
        </p>
        `,
  },
  {
    id: 13,
    title: "Article 13. Opposition- Défaut d'Opposition",
    content: `
        <p class='mb-3' style={justif} >
          En cas de perte ou de vol des codes d'accès, L'Utilisateur doit
          immédiatement et sans délai demander de réinitialiser son mot de passe
          via le Site. A défaut d'opposition, La Banque n'est pas responsable
          des conséquences de l'utilisation, par un tiers, de l'identifiant, du
          mot de passe, Code de réinitialisation et du Code à usage unique de
          l'Utilisateur.
        </p>
        `,
  },
  {
    id: 14,
    title: 'Article 14. Préconisation ',
    content: `
        <p class='mb-3' style={justif} >
          Pour protéger le caractère confidentiel de ses demandes, l'Utilisateur
          est invité à prendre les dispositions adéquates, en fonction de son
          propre matériel logiciel pour, soit prévenir la mémorisation, dans son
          ordinateur, des données consultées, soit pour procéder à l'effacement
          de celles-ci une fois la consultation terminée.
        </p>
        <p class='mb-3' style={justif} >
          S'il importe des données sur un logiciel de gestion, l'Utilisateur
          veillera à en interdire l'accès aux tiers non autorisés.
        </p>
        <p class='mb-3' style={justif} >
          A ce titre, l'Utilisateur assure seul les risques de la manipulation
          et l'utilisation de son terminal de connexion par des tiers non
          autorisés.
        </p>
        <p class='mb-3' style={justif} >
          Il appartient, d'autre part, à l'Utilisateur de prendre toutes mesures
          appropriées pour protéger les données et/ou les logiciels stockés ou
          chargés sur son équipement informatique de la contamination par des
          virus ou de tentatives d'intrusion.
        </p>
        `,
  },
  {
    id: 15,
    title: 'Article 15. Cas de Force Majeure ',
    content: `
        <div class='mb-3' style={justif} >
          La Banque ne peut être tenue pour responsable de la non-exécution de
          l'une quelconque de ses obligations au titre des présentes notamment
          dans les cas suivants :
          <br />
          <br />
          <ul>
            <li>
              Lorsque la non-exécution est due à un empêchement indépendant de
              sa volonté
            </li>
            <li>
              Lorsqu'elle ne pouvait raisonnablement prévoir cet empêchement ou
              ses effets ou son aptitude à exécuter le contrat au moment de sa
              conclusion
            </li>
            <li>
              Lorsqu'elle n'aurait pas pu raisonnablement éviter ou surmonter
              cet empêchement, ou à tout le moins ses effets.
            </li>
          </ul>
        </div>
        `,
  },
  {
    id: 16,
    title: 'Article 16. Résolution',
    content: `
        <p class='mb-3' style={justif} >
          En cas de manquement aux obligations des présentes, clôture du ou des
          compte(s) de l'Utilisateur, utilisation frauduleuse et fonctionnement
          irrégulier du service dus aux manœuvres de l'Utilisateur, sans que
          cette liste ne soit exhaustive, La Banque peut prononcer de plein
          droit et sans préavis la résiliation ou la résolution des présentes
          Conditions générales d'utilisation du Site à l'égard de l'Utilisateur.
        </p>
        `,
  },
  {
    id: 17,
    title: 'Article 17. Loi Applicable et Juridiction Compétente',
    content: `
        <p class='mb-3' style={justif} >
        
          Le site www.creditbusinessonline.ma étant édité et hébergé au Maroc,
          il reste assujetti à la loi Marocaine. Tout utilisateur reconnaît
          ainsi la compétence des tribunaux marocains pour tout ce qui concerne
          son utilisation.
        </p>
        `,
  },
];
