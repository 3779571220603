import {
  AUTH_STORAGE_KEY,
  setLocalStorage,
} from 'common/utils/localStorage.util';
import { fromJS } from 'immutable';
import { appAT } from './actions.app';

export const initState = fromJS({
  apiLock: false,
  loader: false,
  configLoader: false,
  homeNatures: [],
  homeTPENatures: [],
  notifications: [],
  generalCondition: false,
  faq: [],
  showLegaleNotice: false,
});

export const APP_REDUCER = 'APP_REDUCER';

export function appReducer(state = initState, action) {
  switch (action.type) {
    case appAT.SHOW_LOADER:
      return state.set(action.name, true);
    case appAT.HIDE_LOADER:
      return state.set(action.name, false);
    case appAT.GET_NOTIFICATIONS_SUCCESS:
      return state.set('notifications', fromJS(action.notifications));
    case appAT.GET_HOME_NATURES_SUCCESS:
      return state.set('homeNatures', fromJS(action.payload));
    case appAT.GET_HOME_TPE_NATURES_SUCCESS:
      return state.set('homeTPENatures', fromJS(action.payload));
    case appAT.SHOW_GENERAL_CONDITION_ACTION:
      setLocalStorage(AUTH_STORAGE_KEY, !action.value);
      return state.set('generalCondition', action.value);
    case appAT.UPDATE_API_LOCK:
      return state.set('apiLock', action.apiLock);
    case appAT.GET_FAQ_SUCCESS:
      return state.set('faq', fromJS(action.faq));
    case appAT.SHOW_LEGALE_NOTICE:
      return state.set('showLegaleNotice', fromJS(true));
    case appAT.HIDE_LEGALE_NOTICE:
      return state.set('showLegaleNotice', fromJS(false));
    default:
      return state;
  }
}
