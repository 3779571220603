export const releaseRequestAt = {
  GET_REALEASE_REQUESTS: 'GET_REALEASE_REQUESTS',
  GET_REALEASE_REQUESTS_SUCCESS: 'GET_REALEASE_REQUESTS_SUCCESS',
  GET_REALEASE_REQUEST_DETAIL: 'GET_REALEASE_REQUEST_DETAIL',
  GET_REALEASE_REQUEST_DETAIL_SUCCESS: 'GET_REALEASE_REQUEST_DETAIL_SUCCESS',
  GET_REALEASE_TYPES: 'GET_REALEASE_TYPES',
  GET_REALEASE_TYPES_SUCCESS: 'GET_REALEASE_TYPES_SUCCESS',
  GET_ALL_GUARANTEES: 'GET_ALL_GUARANTEES',
  GET_ALL_GUARANTEES_SUCCESS: 'GET_ALL_GUARANTEES_SUCCESS',
  SAVE_REALEASE_REQUEST: 'SAVE_REALEASE_REQUEST',
  SAVE_REALEASE_REQUEST_SUCCESS: 'SAVE_REALEASE_REQUEST_SUCCESS',
  SET_REALEASE_REQUEST_ERRORS: 'SET_REALEASE_REQUEST_ERRORS',
  UPDATE_NEW_REALEASE_REQUEST_LIST: 'UPDATE_NEW_REALEASE_REQUEST_LIST',
  UPDATE_RELEASE_INPUT: 'UPDATE_RELEASE_INPUT',
  VALIDATE_RELEASE_LINE: 'VALIDATE_RELEASE_LINE',
  DOWNLOAD_RELEASE: 'DOWNLOAD_RELEASE',
  SHOW_CONFIRM_MESSAGE: 'SHOW_CONFIRM_MESSAGE',
  HIDE_CONFIRM_MESSAGE: 'HIDE_CONFIRM_MESSAGE',
};

export const getReleaseRequests = () => ({
  type: releaseRequestAt.GET_REALEASE_REQUESTS,
});

export const getReleaseRequestDetail = idRelease => ({
  type: releaseRequestAt.GET_REALEASE_REQUEST_DETAIL,
  idRelease,
});

export const getReleaseType = () => ({
  type: releaseRequestAt.GET_REALEASE_TYPES,
});

export const getAllGuarantees = () => ({
  type: releaseRequestAt.GET_ALL_GUARANTEES,
});

export const updateReleaseInput = (name, value) => ({
  type: releaseRequestAt.UPDATE_RELEASE_INPUT,
  name,
  value,
});

export const updateReleaseRequestsLine = releaseRequests => ({
  type: releaseRequestAt.UPDATE_NEW_REALEASE_REQUEST_LIST,
  releaseRequests,
});

export const validateNewReleaseRequestLine = () => ({
  type: releaseRequestAt.VALIDATE_RELEASE_LINE,
});

export const saveNewReleaseRequestLine = releaseRequests => ({
  type: releaseRequestAt.SAVE_REALEASE_REQUEST,
  releaseRequests,
});

export const downloadRelease = (idRelease, pathFile) => ({
  type: releaseRequestAt.DOWNLOAD_RELEASE,
  idRelease,
  pathFile,
});

export const showConfirMessage = () => ({
  type: releaseRequestAt.SHOW_CONFIRM_MESSAGE,
});
export const hideConfirmMessage = () => ({
  type: releaseRequestAt.HIDE_CONFIRM_MESSAGE,
});
