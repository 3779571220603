import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { fromJS } from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './core/root.reducers';
import rootSaga from './core/root.sagas';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory({ basename: '/cbo' });

export function configureStore(preloadedState) {
  const store = createStore(
    rootReducer(history), // root reducer with router state
    fromJS(preloadedState),
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
      ),
    ),
  );

  if (Array.isArray(rootSaga)) {
    rootSaga.forEach(saga => sagaMiddleware.run(saga));
  }
  return store;
}

export default configureStore();
