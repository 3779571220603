import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from '@redux-saga/core/effects';
import { toastTypes } from 'common/component/constants';
import { ErrorToast } from 'common/component/Toast';
import { Errors } from 'common/locale';
import generateRandomId from 'common/utils/generateRandomId.util';
import { getStepUrlByStepId } from 'common/utils/loanRequest.utils';
import { apiGet, apiPost } from 'common/utils/request';
import { createUploader } from 'common/utils/upload.util';
import { loanStepsConstraints } from 'common/utils/validate/constraints';
import { NATURE_ERROR } from 'common/utils/validate/errors';
import { costumValidate } from 'common/utils/validate/validate';
import { push, replace } from 'connected-react-router';
import { getLocation } from 'core/root.selector';
import { hideLoaderAction, showLoaderAction } from 'features/app/actions.app';
import { customTakeLatest } from 'features/app/sagas.app';
import { getAccoutTypeSelector } from 'features/auth/selectors.auth';
import { loansListAt } from 'features/home/actions.loansList';
import { initiatedLoanIdSelector } from 'features/home/selectors.loansList';
import {
  getStepDataSuccessAction,
  loanRequestAt,
  updateCurrentStep,
  updateGuaranteeLine,
  updateInput,
  updateLoanDataAction,
  updateUploadedDocuments,
} from './actions.loanRequest';
import {
  ADDITIONAL_INFORMATION_STEP,
  DOCUMENTS_UPLOAD_STEP,
  ENTREPRISE_IDENTIFICATION_STEP,
  errorCode,
  GUARANTEES_STEP,
  LOAN_INFORMATION_STEP,
  natureConst,
  RENEWAL,
  subTypeConst,
  SUMMARY_STEP,
  VALIDATION_STEP,
} from './constants.loanRequest';
import {
  getLoanId,
  getLoanStepSelector,
  selectStepInfoByArray,
} from './selectors.loanRequest';

function getCurrentStep(data) {
  if (data[SUMMARY_STEP] && data[SUMMARY_STEP].checked) {
    return SUMMARY_STEP;
  } else if (
    data[ADDITIONAL_INFORMATION_STEP] &&
    data[ADDITIONAL_INFORMATION_STEP].checked
  ) {
    return ADDITIONAL_INFORMATION_STEP;
  } else if (
    data[DOCUMENTS_UPLOAD_STEP] &&
    data[DOCUMENTS_UPLOAD_STEP].checked
  ) {
    return DOCUMENTS_UPLOAD_STEP;
  } else if (data[GUARANTEES_STEP] && data[GUARANTEES_STEP].checked) {
    return GUARANTEES_STEP;
  } else if (
    data[LOAN_INFORMATION_STEP] &&
    data[LOAN_INFORMATION_STEP].checked
  ) {
    return LOAN_INFORMATION_STEP;
  } else {
    return ENTREPRISE_IDENTIFICATION_STEP;
  }
}

export function* syncEntrepriseIdentification(action) {
  try {
    yield put(showLoaderAction());
    const idDemande =
      action.id && action.id !== '0' ? action.id : yield select(getLoanId);
    const payload = {
      idDemande,
    };
    const { data } = yield call(
      apiPost,
      '/creditEntreprise/demande/synchronize',
      payload,
    );
    yield put(getStepDataSuccessAction(ENTREPRISE_IDENTIFICATION_STEP, data));
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('grc error :', error);
  }
}

function* syncEntrepriseIdentificationSaga() {
  yield takeLatest(
    loanRequestAt.INIT_ENTREPRISE_IDENTIFICATION,
    syncEntrepriseIdentification,
  );
}

function* getStepConfig(step) {
  switch (step) {
    case LOAN_INFORMATION_STEP:
      return yield put({ type: loanRequestAt.GET_LOAN_INFORMATION_CONFIG });
    default:
      return;
  }
}

export function* getRenewalLoanRequestData(action) {
  try {
    if (action.loansList) {
      const initLoan = yield select(initiatedLoanIdSelector);
      if (initLoan) {
        yield put({ type: loansListAt.UPDATE_TOAST_IS_OPEN, value: true });
        return;
      }
      yield put(updateInput(LOAN_INFORMATION_STEP, ['loanType'], RENEWAL));
    } else {
      yield put(updateInput(LOAN_INFORMATION_STEP, ['loanType'], RENEWAL));
    }

    yield put(showLoaderAction());
    // TODO to handle the case when we init the laon request
    const idDemande =
      action.id && action.id !== '0' ? action.id : yield select(getLoanId);
    const payload = {
      idDemande,
      isModification: action.isModification,
      loanLines: action.loansList,
    };
    const location = yield select(getLocation);
    const { data } = yield call(
      apiPost,
      '/creditEntreprise/demande/getDemande',
      payload,
    );

    if (data.errorCode === errorCode.PRODUCT_NOT_FOUND) {
      ErrorToast(Errors.invalidNature, toastTypes.UNAUTHORIZED);
      yield put(hideLoaderAction());
      return;
    }

    yield put(
      getStepDataSuccessAction(
        ENTREPRISE_IDENTIFICATION_STEP,
        data[ENTREPRISE_IDENTIFICATION_STEP],
      ),
    );
    yield put(
      getStepDataSuccessAction(
        LOAN_INFORMATION_STEP,
        data[LOAN_INFORMATION_STEP],
      ),
    );
    yield put(getStepDataSuccessAction(GUARANTEES_STEP, data[GUARANTEES_STEP]));
    yield put(
      getStepDataSuccessAction(
        DOCUMENTS_UPLOAD_STEP,
        data[DOCUMENTS_UPLOAD_STEP],
      ),
    );
    yield put(
      getStepDataSuccessAction(
        ADDITIONAL_INFORMATION_STEP,
        data[ADDITIONAL_INFORMATION_STEP],
      ),
    );

    yield put(getStepDataSuccessAction('prevLoanData', data));

    if (data['statut']) {
      yield put(updateInput('status', [], data['statut']));
    }
    const currentStep = getCurrentStep(data);
    yield put(updateCurrentStep(currentStep));

    // get steps config, to move in a seperate function
    yield getStepConfig(currentStep);

    // No need for redirection if location equal to newLocation.
    const newLocation = getStepUrlByStepId(
      currentStep,
      idDemande ? idDemande : data.idDemande,
    );
    if (location !== newLocation) {
      yield put(
        push(
          getStepUrlByStepId(
            currentStep,
            idDemande ? idDemande : data.idDemande,
          ),
        ),
      );
    }

    yield put({
      type: loanRequestAt.UPDATE_ID_DEMANDE,
      idDemande: data.idDemande,
    });
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('grc error :', error);
  }
}

export function* getLoanRequestData(action) {
  try {
    if (action.nature) {
      const initLoan = yield select(initiatedLoanIdSelector);
      if (initLoan) {
        yield put({ type: loansListAt.UPDATE_TOAST_IS_OPEN, value: true });
        return;
      }
      yield put(updateInput(LOAN_INFORMATION_STEP, ['loanType'], RENEWAL));
    } else {
      yield put(updateInput(LOAN_INFORMATION_STEP, ['loanType'], RENEWAL));
    }

    yield put(showLoaderAction());
    // TODO to handle the case when we init the laon request
    const idDemande =
      action.id && action.id !== '0' ? action.id : yield select(getLoanId);
    const payload = {
      idDemande,
      nature: action.nature,
      amount: action.amount,
    };
    const location = yield select(getLocation);
    const { data } = yield call(
      apiPost,
      '/creditEntreprise/demande/getDemande',
      payload,
    );
    if (data.errorCode === errorCode.PRODUCT_NOT_FOUND) {
      ErrorToast(Errors.invalidNature, toastTypes.UNAUTHORIZED);
      yield put(hideLoaderAction());
      return;
    }

    yield put(
      getStepDataSuccessAction(
        ENTREPRISE_IDENTIFICATION_STEP,
        data[ENTREPRISE_IDENTIFICATION_STEP],
      ),
    );
    yield put(
      getStepDataSuccessAction(
        LOAN_INFORMATION_STEP,
        data[LOAN_INFORMATION_STEP],
      ),
    );
    yield put(getStepDataSuccessAction(GUARANTEES_STEP, data[GUARANTEES_STEP]));
    yield put(
      getStepDataSuccessAction(
        DOCUMENTS_UPLOAD_STEP,
        data[DOCUMENTS_UPLOAD_STEP],
      ),
    );
    yield put(
      getStepDataSuccessAction(
        ADDITIONAL_INFORMATION_STEP,
        data[ADDITIONAL_INFORMATION_STEP],
      ),
    );

    yield put(getStepDataSuccessAction('prevLoanData', data));

    if (data['statut']) {
      yield put(updateInput('status', [], data['statut']));
    }
    const currentStep = getCurrentStep(data);
    yield put(updateCurrentStep(currentStep));

    // get steps config, to move in a seperate function
    yield getStepConfig(currentStep);

    // No need for redirection if location equal to newLocation.
    const newLocation = getStepUrlByStepId(
      currentStep,
      idDemande ? idDemande : data.idDemande,
    );
    if (location !== newLocation) {
      yield put(
        push(
          getStepUrlByStepId(
            currentStep,
            idDemande ? idDemande : data.idDemande,
          ),
        ),
      );
    }

    yield put({
      type: loanRequestAt.UPDATE_ID_DEMANDE,
      idDemande: data.idDemande,
    });
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('grc error :', error);
  }
}

export function* submitStep(action) {
  try {
    yield put(showLoaderAction());
    //const idDemande = yield select(getLoanId);
    const stepData = yield select(getLoanStepSelector(action.step));
    const errors = costumValidate(stepData, loanStepsConstraints[action.step]);
    const payload = {
      ...stepData,
      //idDemande,
      currentStep: action.step,
      nextStep: action.nextStep,
    };
    delete payload.idDemande;
    if (errors) {
      yield put({
        type: loanRequestAt.STEP_ERRORS,
        errors,
        step: action.step,
      });
      return yield put(hideLoaderAction());
    }
    yield put({
      type: loanRequestAt.STEP_ERRORS,
      errors: {},
      step: action.step,
    });
    const { data } = yield call(
      apiPost,
      '/creditEntreprise/demande/process',
      payload,
    );

    if (data.errorCode) {
      yield put({
        type: loanRequestAt.STEP_ERRORS,
        errors: { globalError: [data.errorCode] },
        step: action.step,
      });
      return yield put(hideLoaderAction());
    }

    yield put({
      type: loanRequestAt.UPDATE_ID_DEMANDE,
      idDemande: data.idDemande,
    });

    yield put(getStepDataSuccessAction(action.nextStep, data));
    yield put(updateLoanDataAction(['prevLoanData', action.nextStep], data));
    yield put(push(getStepUrlByStepId(action.nextStep, data.idDemande)));
    yield put(updateCurrentStep(action.nextStep));

    // To update loan information.
    if (action.nextStep === SUMMARY_STEP) {
      yield put(
        getStepDataSuccessAction(
          LOAN_INFORMATION_STEP,
          data[LOAN_INFORMATION_STEP],
        ),
      );
    }

    if (action.step === SUMMARY_STEP && action.nextStep !== VALIDATION_STEP) {
      yield put(updateInput(action.step, ['checked'], false));
    } else {
      yield put(updateInput(action.step, ['checked'], true));
    }

    // get step config
    yield getStepConfig(action.nextStep);

    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('error :', error);
  }
}

export function* getLoanInformationConfig() {
  try {
    yield put(showLoaderAction('configLoader'));
    const authType = yield select(getAccoutTypeSelector);

    const { data } = yield call(
      apiPost,
      '/creditEntreprise/demande/getConfigLignesCredit',
      {},
    );
    const {
      data: { listCptGrc, objectLines },
    } = yield call(
      apiGet,
      `/creditEntreprise/parametresDemande/configSecondStep/${authType}`,
    );

    yield put({
      type: loanRequestAt.GET_CONFIG_SUCCESS,
      name: ['accountsConfig'],
      config: listCptGrc ? listCptGrc : [],
    });

    yield put({
      type: loanRequestAt.GET_CONFIG_SUCCESS,
      name: ['objectsConfig'],
      config: objectLines ? objectLines : [],
    });

    yield put({ type: loanRequestAt.UPDATE_LOAN_TYPE_CONFIG, config: data });

    const nature = yield select(
      selectStepInfoByArray(LOAN_INFORMATION_STEP, ['fields', 'nature']),
    );
    if (nature) {
      yield put(updateInput(LOAN_INFORMATION_STEP, ['disabled'], false));
    }

    yield put({
      type: loanRequestAt.INIT_LOAN_TYPE,
      initConfig: getTypeConfig(data, nature),
    });

    yield put(hideLoaderAction('configLoader'));
  } catch (error) {
    yield put(hideLoaderAction('configLoader'));
    console.error('Error :', error);
  }
}

export function* validateRequest(action) {
  try {
    yield put(showLoaderAction());
    const idDemande = yield select(getLoanId);
    const authType = yield select(getAccoutTypeSelector);

    const payload = {
      authType,
      //idDemande,
      currentStep: action.step,
      nextStep: action.nextStep,
    };
    delete payload.idDemande;
    yield call(apiPost, '/creditEntreprise/demande/process', payload);

    yield put(updateInput(action.step, ['checked'], true));
    yield put(push(`/loan/${idDemande}/validation`));
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('error :', error);
  }
}

function* validateRequestSaga() {
  yield takeLatest(loanRequestAt.VALIDATE_REQUEST, validateRequest);
}

export function* validateNewElement(action) {
  const { fields } = yield select(getLoanStepSelector(action.step));

  const errors = costumValidate(
    fields,
    loanStepsConstraints['fields'][action.step],
  );

  if (errors) {
    return yield put({
      type: loanRequestAt.STEP_ERRORS,
      errors,
      step: action.step,
    });
  }

  yield put({
    type: loanRequestAt.STEP_ERRORS,
    errors: {},
    step: action.step,
  });
  if (!fields.id) {
    yield put(
      updateInput(LOAN_INFORMATION_STEP, ['fields', 'id'], generateRandomId()),
    );
  }

  yield put({
    type: loanRequestAt.ADD_NEW_ELEMENT,
    step: action.step,
  });
  yield put(updateInput(LOAN_INFORMATION_STEP, ['disabled'], true));
}

export function* validateLoanInformationNewElement(action) {
  try {
    const loanInformation = yield select(getLoanStepSelector(action.step));
    const duplicate = loanInformation.loanLines.find(element => {
      return element.nature.value === loanInformation.fields.nature.value;
    });
    if (duplicate && duplicate.id !== loanInformation.fields.id) {
      return yield put({
        type: loanRequestAt.STEP_ERRORS,
        errors: { nature: [NATURE_ERROR] },
        step: action.step,
      });
    }
    yield validateNewElement(action);
  } catch (e) {
    console.error('error :', e);
  }
}

function* validateNewElementSaga() {
  yield takeLatest(
    loanRequestAt.VALIDATE_NEW_ELEMENT,
    validateLoanInformationNewElement,
  );
}

function* submitLoanInformationStepSaga() {
  yield takeLatest(loanRequestAt.SUBMIT_STEP, submitStep);
}

function* getLoanRequestDataSaga() {
  yield customTakeLatest(loanRequestAt.LOAN_REQUEST_DATA, getLoanRequestData);
}

function* getRenewLoanRequestDataSaga() {
  yield customTakeLatest(
    loanRequestAt.RENEW_LOAN_REQUEST_DATA,
    getRenewalLoanRequestData,
  );
}

export function* getGuaranteesAction() {
  try {
    const id = yield select(getLoanId);
    const { data } = yield apiGet(
      `/creditEntreprise/parametresDemande/Garanties/${id}`,
    );
    yield put({
      type: loanRequestAt.SET_GUARANTEES_LIST,
      guaranteesList: data.map(guarantee => ({
        value: guarantee.id,
        label: guarantee.label,
        garantiesDependencies: guarantee.garantiesDependencies,
      })),
    });
  } catch (err) {
    console.error('cannot get gaurantees ', err);
  }
}

export function* validateGuaranteeLineAction() {
  try {
    const { fields, demandeGaranties, guaranteesList } = yield select(
      getLoanStepSelector(GUARANTEES_STEP),
    );

    const errors = costumValidate(
      fields,
      loanStepsConstraints['fields'].loanGuarantee,
    );
    if (errors) {
      return yield put({
        type: loanRequestAt.SET_GUARANTEES_ERRORS,
        errors,
      });
    }

    yield put({
      type: loanRequestAt.SET_GUARANTEES_ERRORS,
      errors: {},
    });

    let newLine = {
      ...fields,
      idGarantie: fields.guarantee.id,
    };

    let newDemandeGaranties = [...demandeGaranties];

    // update line if it is already exist
    if (newLine.id) {
      newDemandeGaranties = newDemandeGaranties.map(line =>
        line.id === newLine.id ? newLine : line,
      );
    } else {
      // otherwise add a new one
      newLine.id = generateRandomId();
      newDemandeGaranties = [...newDemandeGaranties, newLine];
    }
    // if gurantee has dependencies
    if (newLine.guarantee.garantiesDependencies) {
      newLine.guarantee.garantiesDependencies.forEach(dep => {
        // adding dependencie if is not already in the list
        if (
          !newDemandeGaranties.some(g => Number(g.idGarantie) === Number(dep))
        ) {
          const depObj = guaranteesList.find(elm => elm.value === Number(dep));
          // make sure the dependecie guarantee was sent with config guarantees
          if (depObj) {
            const dependedLine = {
              ...newLine,
              id: generateRandomId(),
              idGarantie: depObj.value, // dependencie id

              guarantee: {
                id: depObj.value,
                label: depObj.label,
              },
            };
            newDemandeGaranties.push(dependedLine); // push gurantee to list
          }
        }
      });
    }
    yield put(updateGuaranteeLine(newDemandeGaranties));
  } catch (e) {
    console.error('add new line', e);
  }
}

/* 
  ####### We may  use this solution in the future if we found
  a solution to handle multip chanel for each file ####### 
*/

function* uploadFile(action) {
  const id = yield select(getLoanId);
  const formData = new FormData();
  formData.append('idDemande', id);
  formData.append('file', action.file);
  formData.append('idDocument', action.idDocument);

  const [uploadPromise, chan] = createUploader(
    formData,
    '/creditEntreprise/demande/uploadFile',
  );
  yield fork(watchOnProgress, chan, action.idDocument);

  try {
    yield call(() => uploadPromise);
  } catch (err) {
    console.error('Error on file upload');
  }
}

function* watchOnProgress(chan, docId) {
  while (true) {
    // eslint-disable-next-line no-unused-vars
    const data = yield take(chan);
  }
}

function* uploadFileWatcher() {
  yield takeLatest(loanRequestAt.FILE_UPLOAD_HANDLER, uploadFile);
}

/* 
  ####### END  ####### 
*/

/*
  ##### downloadFile #####
  isCanva: Boolean
  isTemplate: Boolean
  isOther: Boolean
  pathSaved: String
  fileName: String
*/

export function* downloadFile(action) {
  try {
    const {
      isCanva,
      isTemplate,
      pathSaved,
      fileName,
      isOther,
      loanId,
    } = action;

    const id = yield select(getLoanId); // only work in case of PDF reporter
    const uploadedFileUrl = `/creditEntreprise/demande/downloadFile/${loanId}/${pathSaved}?other=${isOther}`;
    const canvasUrl = `/creditEntreprise/demande/downloadMaquette/${pathSaved}`;
    const templateFileUrl = `/creditEntreprise/demande/pdfreport/${id}`;
    const { data } = yield call(
      apiGet,
      isCanva ? canvasUrl : isTemplate ? templateFileUrl : uploadedFileUrl,
      { responseType: 'blob' },
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    console.error("can't download this file ", e);
  }
}

function* getGuaranteesSaga() {
  yield takeLatest(loanRequestAt.GET_GUARANTEES, getGuaranteesAction);
}

function* validateGuaranteeLineSaga() {
  yield takeLatest(
    loanRequestAt.VALIDATE_GUARANTEE_LINE,
    validateGuaranteeLineAction,
  );
}

const getTypeConfig = (data, nature) => {
  if (!nature) {
    return {
      type: { value: data[0].value, label: data[0].label },
      subType: {
        value: data[0][subTypeConst][0].value,
        label: data[0][subTypeConst][0].label,
      },
      nature: {
        value: data[0][subTypeConst][0][natureConst][0].value,
        label: data[0][subTypeConst][0][natureConst][0].label,
      },
    };
  }

  const selectedSubType = data[0][subTypeConst].find(element => {
    return element[natureConst].find(subElement => {
      return parseInt(subElement.value, 10) === parseInt(nature.value, 10);
    });
  });

  const selectNature = selectedSubType[natureConst].find(element => {
    return parseInt(element.value, 10) === parseInt(nature.value, 10);
  });

  return {
    type: { value: data[0].value, label: data[0].label },
    subType: {
      value: selectedSubType.value,
      label: selectedSubType.label,
    },
    nature: {
      value: selectNature.value,
      label: selectNature.label,
    },
  };
};

// TODO rename to downloadFileWatcher
function* dowLoadFileWatcher() {
  yield takeLatest(loanRequestAt.DOWLOAD_FILE, downloadFile);
}

function* getLoanInformationConfigSaga() {
  yield takeLatest(
    loanRequestAt.GET_LOAN_INFORMATION_CONFIG,
    getLoanInformationConfig,
  );
}

export function* removeFilesSaga(action) {
  try {
    const idDemande = yield select(getLoanId);
    const { documents } = yield select(
      getLoanStepSelector(DOCUMENTS_UPLOAD_STEP),
    );
    const state = documents.reduce(
      (acc, file) => {
        if (action.files.includes(file.id)) {
          // updated file
          acc.newDocs.push({
            ...file,
            pathSaved: '',
            fileName: '',
          });

          acc.payload.push({
            idDemande,
            idDocument: file.id,
            other: !!file.other,
          });
        } else {
          // current file
          acc.newDocs.push(file);
        }

        return acc;
      },
      // default value
      {
        payload: [],
        newDocs: [],
      },
    );
    yield put(showLoaderAction());
    yield call(apiPost, 'creditEntreprise/demande/deleteDocs', state.payload);
    yield put(updateUploadedDocuments(state.newDocs));
    yield put(hideLoaderAction());
  } catch (e) {
    yield put(hideLoaderAction());
    console.error('file cannot be deleted ', e);
  }
}

function* removeFilesWatcher() {
  yield takeLatest(loanRequestAt.REMOVE_FILES, removeFilesSaga);
}

function* cancelLoanRequest(action) {
  try {
    yield put(showLoaderAction());
    const loanId = yield select(getLoanId);
    if (!loanId) {
      yield put(replace('/home'));
      return;
    }
    yield call(apiGet, `/creditEntreprise/demande/annuler/${loanId}`);
    yield put({
      type: loansListAt.UPDATE_TOAST_IS_OPEN,
      value: false,
    });
    // const location = yield select(getLocation);
    yield put(replace('/home'));
    yield put({ type: loanRequestAt.UPDATE_ID_DEMANDE, idDemande: null });
    yield put({ type: loansListAt.UPDATE_INIT_LOAN_ID, value: null });
    yield put(hideLoaderAction());
  } catch (e) {
    console.error("Can't cancel loan request");
    yield put(hideLoaderAction());
  }
}

function* cancelLoanRequestSaga() {
  yield takeLatest(loanRequestAt.CANCEL_LOAN_REQUEST, cancelLoanRequest);
}

export default [
  getGuaranteesSaga,
  validateGuaranteeLineSaga,
  getLoanRequestDataSaga,
  getRenewLoanRequestDataSaga,
  dowLoadFileWatcher,
  uploadFileWatcher,
  submitLoanInformationStepSaga,
  validateNewElementSaga,
  validateRequestSaga,
  getLoanInformationConfigSaga,
  syncEntrepriseIdentificationSaga,
  removeFilesWatcher,
  cancelLoanRequestSaga,
];
