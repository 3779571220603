export const RIB_NOT_VALID = 'RIB_NOT_VALID';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const RIB_ALREADY_USED = 'RIB_ALREADY_USED';
export const DATA_NOT_VALID = 'DATA_NOT_VALID';
export const PASSWORDS_DONT_MATCH = 'PASSWORDS_DONT_MATCH';
export const PASSWORD_ALREADY_USED = 'PASSWORD_ALREADY_USED';
export const PASSWORD_NOT_VALID = 'PASSWORD_NOT_VALID';
export const LOGIN_OR_PASSWORD_NOT_VALID = 'LOGIN_OR_PASSWORD_NOT_VALID';
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED';
export const ACCOUNT_DISABLED = 'ACCOUNT_DISABLED';
export const MODIFY_PASSWORD = 'MODIFY_PASSWORD';
export const USER_NOT_AUTHORIZED = 'USER_NOT_AUTHORIZED';
