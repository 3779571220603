import React, { useState } from 'react';
import Question from './Question';
import {
  ADDITIONAL_INFORMATION_STEP,
  SUMMARY_STEP,
  DOCUMENTS_UPLOAD_STEP,
} from 'features/loanRequest/constants.loanRequest';
import { useSelector, useDispatch } from 'react-redux';
import { selectStepInfoByArray } from '../../selectors.loanRequest';
import { loanRequestWording } from 'common/locale';
import { updateInput } from 'features/loanRequest/actions.loanRequest';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { getStepUrlByStepId } from 'common/utils/loanRequest.utils';

function AdditionalInformation({ submitStep, idLoan }) {
  const dispatch = useDispatch();
  const questions = useSelector(
    selectStepInfoByArray(ADDITIONAL_INFORMATION_STEP, ['questions']),
  );

  const [touched, setTouched] = useState(false);

  const previousStep = () => {
    if (touched) {
      return submitStep(ADDITIONAL_INFORMATION_STEP, DOCUMENTS_UPLOAD_STEP);
    }
    dispatch(push(getStepUrlByStepId(DOCUMENTS_UPLOAD_STEP, idLoan)));
  };

  const updateValue = (element, newValue) => {
    setTouched(true);
    dispatch(updateInput(ADDITIONAL_INFORMATION_STEP, element, newValue));
  };

  const nextStep = event => {
    event.preventDefault();
    submitStep(ADDITIONAL_INFORMATION_STEP, SUMMARY_STEP);
  };

  return (
    <Form onSubmit={nextStep}>
      <div className={'title4 mb-4'}>
        {loanRequestWording.additionalInformation.SUB_TITLE}
      </div>
      {questions &&
        questions.map((question, index) => (
          <Question
            textArea={index === questions.length - 1}
            key={question.id}
            id={question.id}
            index={index}
            question={question}
            updateInput={updateValue}
          />
        ))}

      <Row className='mt-7 justify-content-between'>
        <Col lg='3' md='4'>
          <Button
            block
            variant={`outline-primary`}
            className={`rounded-pill primaryButton`}
            onClick={previousStep}
          >
            {loanRequestWording.previous}
          </Button>
        </Col>
        <Col lg='3' md='4'>
          <Button block className={`rounded-pill primaryButton `} type='submit'>
            {loanRequestWording.next}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default AdditionalInformation;
