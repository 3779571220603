import { makeStyles } from '@material-ui/styles';
import React from 'react';
import phoneIcon from 'assets/svg/phone2.svg';
import mainLogo from 'assets/images/logo.png';
import facebookIcon from 'assets/svg/facebook.svg';
import youtubeIcon from 'assets/svg/youtube.svg';
import twitterIcon from 'assets/svg/twitter.svg';
import instagramIcon from 'assets/svg/instagram.svg';
import linkedinIcon from 'assets/svg/linkedin.svg';
import localisationIcon from 'assets/svg/localisation.svg';
import mailIcon from 'assets/svg/mail.svg';
import { useDispatch } from 'react-redux';
import { footerWording, altWording } from 'common/locale/index';
import { Link } from 'react-router-dom';
import { showLegaleNotice } from '../../features/app/actions.app';

export const useStyles = makeStyles(({ color }) => ({
  footer: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 20px ${color.opacityGray}`,
    padding: '65px 20px 20px 20px',
    marginTop: '40px',
    '@media (max-width: 992px)': {
      padding: '20px',
    },
  },
  title: {
    color: `${color.primary}`,
    fontSize: '14px',
  },
  listLink: {
    fontSize: '12px',
    marginBottom: '10px',
    '& a': {
      color: `${color.darkBlue}`,
      lineHeight: '18px',
      verticalAlign: 'middle',
      padding: '2px 13px',
      borderLeft: `3px solid ${color.primary}`,
      display: 'inline-block',
      textAlign: 'left',
      transition: 'background-color 0.5s ease',
      '@media (max-width: 768px)': {
        minWidth: '230px',
      },
    },
    '& a:hover': {
      textDecoration: 'none',

      borderRadius: '50rem',
      backgroundColor: `${color.primary}`,
      color: 'white',
    },
  },
  contactInfo: {
    fontSize: '12px',
    marginBottom: '10px',
    color: `${color.darkBlue}`,
    lineHeight: '18px',
    '& img': {
      verticalAlign: 'initial',
      marginRight: '5px',
    },
  },
  titleFooter: {
    fontSize: '12px',
    marginTop: '10px',
  },
  deviderFooter: {},
  socialLink: {
    padding: '5px 10px',
    cursor: 'pointer',
    '& img': {
      width: 28,
    },
  },
  container: {
    paddingLeft: 50,
    paddingRight: 50,
    '@media (max-width: 768px)': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

function Footer({ location, selectedtiers }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showLegalNoticePopup = e => {
    e.preventDefault();
    dispatch(showLegaleNotice());
  };
  return (
    <footer
      id={'footerComponent'}
      className={`page-footer font-small indigo ${classes.footer}`}
    >
      <div className={`text-left ${classes.container}`}>
        <div className='row'>
          <div className='col-lg-3 mx-auto pl-5 pr-5'>
            <h5 className={`font-weight-bold mt-3 mb-4 ${classes.title}`}>
              {footerWording.GROUP_WEBSITES}
            </h5>

            <ul className={`list-unstyled`}>
              <li className={`${classes.listLink}`}>
                <a href={footerWording.SHOWCASE_SITE_WEBSITE} target='_blank' rel="noopener noreferrer">
                  {footerWording.SHOWCASE_SITE}
                </a>
              </li>
              <li className={`${classes.listLink}`}>
                <a
                  href={footerWording.BMCE_BANK_OF_AFRICA_WEBSITE}
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  {' '}
                  {footerWording.BMCE_BANK_OF_AFRICA}{' '}
                </a>
              </li>
              <li className={`${classes.listLink}`}>
                <a href={footerWording.BMCE_DIRECT_WEBSITE} target='_blank' rel="noopener noreferrer">
                  {' '}
                  {footerWording.BMCE_DIRECT}{' '}
                </a>
              </li>
            </ul>
          </div>

          <div className='col-lg-3 mx-auto pl-5 pr-5'>
            <h5 className={`font-weight-bold mt-3 mb-4 ${classes.title}`}>
              {footerWording.RIGHTS_OF_USE}
            </h5>

            <ul className={`list-unstyled`}>
              <li className={`${classes.listLink}`}>
                <a href='/terms'>{footerWording.TERMS_OF_SERVICE}</a>
              </li>
              <li className={`${classes.listLink}`}>
                <a href onClick={showLegalNoticePopup}>
                  {footerWording.LEGAL_NOTICE}
                </a>
              </li>
              <li className={`${classes.listLink}`}>
                <Link to='/faq'>{footerWording.FAQ}</Link>
              </li>
            </ul>
          </div>

          <div className='col-lg-3 mx-auto pl-5 pr-5'>
            <h5 className={`font-weight-bold mt-3 mb-4 ${classes.title}`}>
              {footerWording.CONTACT}
            </h5>

            <ul className={`list-unstyled `}>
              <li className={`${classes.contactInfo}`}>
                <img src={phoneIcon} alt={altWording.BANK_OF_AFRICA_MAROC} />
                {location.startsWith('/offres-tpe') ||
                (selectedtiers?.typeUser && selectedtiers?.typeUser !== 'ENTR')
                  ? footerWording.TPE_PHONE_NUMBER
                  : footerWording.PHONE_NUMBER}
              </li>
              <li className={`${classes.contactInfo}`}>
                <img src={mailIcon} alt={altWording.ONLINE_BANK} />
                <a href={`mailto:${footerWording.WEB_SITE}`}>
                  {footerWording.WEB_SITE}
                </a>
              </li>
              <li className={`${classes.contactInfo}`}>
                <img
                  src={localisationIcon}
                  alt={altWording.BANK_MAROC_ENTREPRISE}
                />
                {footerWording.ADRESS}
              </li>
            </ul>
          </div>

          <div className='col-lg-3 mx-auto'>
            <div className='d-flex justify-content-center justify-content-lg-end align-items-center mt-3'>
              <img src={mainLogo} alt={altWording.BANK_OF_AFRICA_MAROC} />
            </div>
            <div className='text-center text-lg-right mt-4'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.linkedin.com/company/bank-of-africa-bmce-group'
                className={`${classes.socialLink}`}
              >
                <img src={linkedinIcon} alt='linkedin' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://twitter.com/Bank_OfAfrica'
                className={`${classes.socialLink}`}
              >
                <img src={twitterIcon} alt='twitter' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.facebook.com/BMCEBankOfAfrica'
                className={`${classes.socialLink}`}
              >
                <img src={facebookIcon} alt='facebook' />
              </a>
              <a
                href='https://www.youtube.com/c/BMCEBankOfAfrica'
                target='_blank'
                rel='noopener noreferrer'
                className={`${classes.socialLink}`}
              >
                <img src={youtubeIcon} alt='youtube' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.instagram.com/bank_of_africa'
                className={`${classes.socialLink}`}
              >
                <img src={instagramIcon} alt='instagram' />
              </a>
            </div>
          </div>
        </div>
        <hr className='clearfix w-100 d-lg-none' />
        <h6
          className={`mt-4 ${classes.titleFooter} text-lg-left text-center d-lg-inline`}
        >
          {footerWording.COPYRIGHT}
        </h6>
      </div>
    </footer>
  );
}

export default Footer;
