export const REQUIRED_LABEL = ' *';
export const notifTypeConst = {
  STATUS_UPDATED: 'STATUS_UPDATE',
  ATTESTATION_UPDATED: 'ATTESTATION_UPDATE',
};

export const toastTypes = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
};
