export const url = 'https://www.creditbusinessonline.ma/';
export const defaultTitle = 'Notre monde est capital | BANK OF AFRICA';
export const founders = 'BANK OF AFRICA';
export const defaultDescription =
  "Le Groupe BANK OF AFRICA a clôturé le premier exercice de son Plan Stratégique de Développement 2016-2020, sur un niveau record de son Résultat Net Part du Groupe, dépassant pour la 1ère fois la barre des 2 milliards de dirhams, illustrant ainsi la dynamique de croissance du Groupe tant au Maroc qu'à l'international.";
export const social = 'social';
export const socialLinks = {
  twitter: 'https://twitter.com/Bank_OfAfrica',
  youtube: 'https://www.youtube.com/c/BMCEBankOfAfrica',
  linkedin: 'https://www.linkedin.com/company/bank-of-africa-bmce-group',
};
export const address = {
  city: 'Casablanca',
  region: 'Grand Casablanca',
  country: 'Maroc',
  zipCode: 20000,
};
export const contact = 'contact';
export const foundingDate = 'foundingDate';
export const legalName = 'CRÉDIT BUSINESS ONLINE';
export const facebookAppId = 'facebookAppId';
export const type = 'Organization';
export const defaultKeywords = 'BANK OF AFRICA, crédit entreprise, Maroc';
