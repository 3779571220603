export const maxSharholders = 5;
export const maxManagements = 5;
export const maxInterlocutors = 5;
export const validFileExtensions = [
  'jpg',
  'jpeg',
  'png',
  'docx',
  'doc',
  'xls',
  'xlsx',
  'pdf',
  'zip',
  'rar',
];
export const limitSize = 10;
export const GUARANTEES_STEP = 'loanGuarantee';
export const ENTREPRISE_IDENTIFICATION_STEP = 'entrepriseIdentification';
export const LOAN_INFORMATION_STEP = 'loanInformation';
export const DOCUMENTS_UPLOAD_STEP = 'documentsUpload';
export const SUMMARY_STEP = 'summary';
export const ADDITIONAL_INFORMATION_STEP = 'additionalInformation';
export const VALIDATION_STEP = 'validation';

export const subTypeConst = 'lstSousType';
export const natureConst = 'lstNatureCreditOut';

export const otherLoanPurpose = 'Other';

export const menuVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      damping: 50,
      delay: 0.3,
    },
  },
};

export const stepVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 50,
    },
  },
};

export const sliderFirststepResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 40,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 30,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 20,
    slidesToSlide: 1,
  },
};

export const sliderSecondStepResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1501 },
    items: 3,
    paritialVisibilityGutter: 40,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1500, min: 1070 },
    items: 2,
    paritialVisibilityGutter: 30,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 1070, min: 0 },
    items: 1,
    paritialVisibilityGutter: 20,
    slidesToSlide: 1,
  },
};

export const selectConst = {
  renewal: [
    { label: 'Un renouvellement à l’identique', value: 'alike' },
    { label: 'Un renouvellement avec modification', value: 'withChange' },
  ],
};

export const RENEWAL = 'renewal';
export const NEW_LAON = 'new';

export const ALIKE = 'alike';
export const WITH_CHANGE = 'withChange';

export const errorCode = {
  PRODUCT_NOT_FOUND: 'PRODUCT_NOT_FOUND',
};

export const AvailableClientType = {
  // ENTR = parcours actuel
  ENTR: 'ENTR',
  // TPEA  ANCIENTE & CA PREVISIONNEL SI ANCIENTE <1
  TPEA: 'TPEA',
  // TPEB  ANCIENTE  & CA PREVISIONNEL SI ANCIENTE <1
  TPEB: 'TPEB',
  // EIPRO  LIST PROFESSION & ANCIENTE  & CA PREVISIONNEL SI ANCIENTE <1
  EIPRO: 'EIPRO',
  // AEPRO = RNAE AU LIEU DE CODE TRIBUNALE & LIST PROFESSION & ANCIENTE  & CA PREVISIONNEL SI ANCIENTE <1
  AEPRO: 'AEPRO',
  // PPPRO LIST PROFESSION & ANCIENTE  & CA PREVISIONNEL SI ANCIENTE <1
  PPPRO: 'PPPRO',
};

export const listSeniority = [
  { value: 'M1', label: 'Entre 0 et 1 ans' },
  { value: 'E15', label: 'Entre 1 et 5 ans' },
  { value: 'P5', label: 'Plus de 5 ans' },
];

export const AvailableSeniority = {
  M1: 'Entre 0 et 1 ans',
  E15: 'Entre 1 et 5 ans',
  P5: 'Plus de 5 ans',
};
