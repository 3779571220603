import { makeStyles } from '@material-ui/styles';
import useIsAuthenticated from 'common/hooks/useIsAuthenticated';
import authAT from 'features/auth/actions.auth';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Media, Navbar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import mainLogo from '../../assets/images/main-logo.png';
import logo from '../../assets/images/logo.png';
import Fermer from '../../assets/svg/Fermer.svg';
import Links from './Links';
import TPELinks from './TPELinks';
import { authWording, institutionalWording } from 'common/locale';
import useHeaderState from 'common/hooks/useHeaderState';
import BlackMenuBurger from '../../assets/svg/menuNoir.svg';

const useStyles = makeStyles(({ color }) => ({
  header: {
    padding: '0 60px 0  60px',
    maxWidth: 2500,
    zIndex: 2,
    '&.isHovering': {
      '& .navbar-toggler-icon': {
        backgroundImage: `url(${BlackMenuBurger})!important`,
      },
      background: 'white',
      '& $textPrimary': {
        color: color.darkBlue,
      },
      '& $search circle, & $search line': {
        stroke: color.darkBlue,
      },
    },
    '@media (max-width: 1165px)': {
      padding: '0 10px 0  10px',
    },
    '@media (max-height: 740px)': {
      marginBottom: '10px !important',
    },
    '@media (max-width: 992px)': {
      padding: 0,
      '& .navbar': {
        padding: 0,
      },
      '& .authButton': {
        padding: '5px 10px',
      },
    },
  },
  headerHomePage: {
    padding: '0 60px 0  60px',
    maxWidth: 2500,
    position: 'fixed',
    right: 0,
    left: 0,
    zIndex: 2,
    '&.isHovering': {
      '@media (min-width: 992px)': {
        background: 'white',
      },

      '& $textPrimary': {
        color: color.darkBlue,
      },
      '& $search circle, & $search line': {
        stroke: color.darkBlue,
      },
    },
    '@media (max-width: 1300px)': {
      padding: '0',
    },
    '@media (max-height: 740px)': {
      marginBottom: '10px !important',
    },
    '@media (max-width: 992px)': {
      padding: 0,
      '& .navbar': {
        padding: 0,
      },
      '& .authButton': {
        padding: '5px 10px',
      },
    },
  },
  isScrolling: {
    background: 'white',
    opacity: 1,
    '& .navbar-toggler-icon': {
      backgroundImage: `url(${BlackMenuBurger})!important`,
    },
    '& $textPrimary': {
      color: color.darkBlue,
    },
    '& $search circle, & $search line': {
      stroke: color.darkBlue,
    },
  },
  logo: {
    width: '200px !important',
    margin: '5px 0',
    '@media (max-width: 1024px)': {
      width: '123px !important',
    },
  },
  nav: {
    position: 'relative',
    width: '100%',
    '@media (max-width: 992px)': {
      height: 'calc(100vh - 60px)',
    },
  },
  textPrimary: {
    color: 'white',
    padding: '20px 10px !important',
    fontWeight: '500',
    fontSize: 12,
    marginRight: 25,
    cursor: 'pointer',
    '&:hover': {
      '@media (min-width: 992px)': {
        background: `linear-gradient(to top left, ${color.primary} 0%, ${color.secondary} 100%)`,
        color: 'white !important',
        '& $submenu': {
          display: 'block',
        },
      },
    },
    '&.current': {
      background: `linear-gradient(to top left, ${color.primary} 0%, ${color.secondary} 100%)`,
      color: 'white !important',
    },
    '@media (max-width: 1250px)': {
      marginRight: 20,
    },
  },
  search: {
    cursor: 'pointer',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 35,
    '& circle, & line': {
      stroke: 'white',
    },
  },
  submenu: {
    display: 'none',
    boxShadow: '0px 20px 20px -10px #e4e4e4 inset',
    left: 0,
    position: 'absolute',
    top: '100%',
    background: 'white',
    padding: '15px 0',
    width: '100%',
    zIndex: 1,
    color: color.darkBlue,
    gridTemplateColumns: '1fr 1fr 1fr',
    '& > div': {
      padding: '25px 20px 0 35px',
    },
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 18,
  },
  subLink: {
    position: 'relative',
    color: color.darkBlue,
    fontSize: 12,
    display: 'inline-block',
    padding: '2px 11px 2px 10px',
    marginBottom: 10,
    borderLeft: `2px solid ${color.primary}`,
    overflow: 'hidden',
    transition: 'all 0.2s',
    '&:after': {
      transition: 'all 0.2s',
      content: "''",
      position: 'absolute',
      top: 0,
      left: -1,
      height: '100%',
      width: 0,
      background: color.primary,
      zIndex: -1,
    },
    '&:hover, &.active': {
      textDecoration: 'none',
      color: 'white',
      borderRadius: 4,

      '&:after': {
        width: '100%',
      },
    },
  },
  navbarCollapse: {
    justifyContent: 'flex-end',
    '& .form-inline': {
      margin: '5px 0',
    },
    '@media (max-width: 992px)': {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 1000,
      background: 'white',
      '& $nav': {
        paddingLeft: '0px!important',
      },
      '& .link-element': {
        color: color.darkBlue,
        margin: 0,
        '& br': {
          display: 'none',
        },
      },
      '& .menu-element': {
        textAlign: 'center',
      },
    },
  },
  navbarToggle: {
    color: 'transparent',
    borderColor: 'transparent',
  },
  hideMenue: {
    display: 'none',
    padding: 15,
    color: 'transparent !important',
    borderColor: 'transparent !important',
    background: 'none',
    '&:focus': {
      outline: 'none !important',
    },
    '& img': {
      height: 22,
      width: 22,
    },
    '@media (max-width: 992px)': {
      display: 'block',
    },
  },
  activeMenu: {
    position: 'relative',
    background: `linear-gradient(to top left, ${color.primary} 0%, ${color.secondary} 100%)`,
    color: 'white !important',
    '& $submenu': {
      display: 'block',
      height: 'calc(100vh - 120px)',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    '& $backIcon': {
      display: 'block',
    },
  },
  backIcon: {
    position: 'absolute',
    left: '25px',
    top: '17px',
    display: 'none',
  },
  tpeAccess: {
    background: `linear-gradient(222deg, ${color.secondary} 0%, ${color.secondaryVariant} 100%)`,
    marginRight: 15,
    '@media (max-width: 1120px)': {
      display: 'none!important',
    },
  },
}));

function Header({ location, history }) {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const [headerHover, setHeaderHover] = useState('');
  const headerState = useHeaderState(location);

  const isHomeOrTPEPage = () => {
    return location === '/' || location === '/offres-tpe';
  };

  const enableHeader = () => {
    if (headerState.hasMenu) {
      setHeaderHover('isHovering');
    }
  };
  const disableHeader = () => {
    if (!headerState.isMenuSubRoute) {
      // remove background if is not menu's item
      setHeaderHover('');
    }
  };

  /* handle menu state if route changed */
  useEffect(() => {
    if (isHomeOrTPEPage()) {
      window.addEventListener('scroll', handleScroll);
    }

    if (headerState.isMenuSubRoute) {
      enableHeader();
    } else {
      disableHeader();
    }
    return () => {
      if (isHomeOrTPEPage()) {
        window.removeEventListener('scroll', () => handleScroll);
      }
    };

    // eslint-disable-next-line
  }, [headerState.isMenuSubRoute]);
  const [isExpanded, setExpanded] = useState(false);
  useEffect(() => {
    if (isExpanded === true) setExpanded(false);
  }, [location, isExpanded]);

  const logOut = () => {
    dispatch({ type: authAT.LOG_OUT });
  };
  const [isSticky, setSticky] = useState(false);

  const ref = useRef(null);
  const handleScroll = e => {
    if (ref.current && window.pageYOffset !== 0) {
      // setSticky(ref.current.getBoundingClientRect().top <= 0);
      setSticky(true);
    }
    if (ref.current && window.pageYOffset === 0) {
      setSticky(false);
    }
  };
  const handelMouseEnter = () => {
    if (window.innerWidth < 992) {
      return;
    }
    isSticky && isHomeOrTPEPage() ? disableHeader() : enableHeader();
  };
  const getLogo = () => {
    if (window.innerWidth < 992 && location === '/auth') return logo;
    if (headerHover === 'isHovering' || isSticky) return logo;
    return mainLogo;
  };
  const getLinks = () => {
    if (location.startsWith('/offres-tpe')) {
      return <TPELinks classes={classes} />;
    }
    return <Links classes={classes} />;
  };
  return (
    <div
      onMouseEnter={handelMouseEnter}
      onMouseLeave={disableHeader}
      className={`${
        isHomeOrTPEPage() ? classes.headerHomePage : classes.header
      } ${headerHover}${
        isSticky && isHomeOrTPEPage() ? classes.isScrolling : ''
      }`}
      ref={ref}
    >
      <Navbar className='navbar-light' expand='lg' expanded={isExpanded}>
        {headerState.hasMenu && (
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            className='navbar-light'
            onClick={() => setExpanded(true)}
          />
        )}
        <Navbar.Brand href='/' style={{ marginRight: 'auto', marginLeft: 10 }}>
          <Media>
            <img
              src={getLogo()}
              alt='Generic placeholder'
              id='logo'
              className={`img-fluid rounded float-left imgstyle ${classes.logo}`}
            />
          </Media>
        </Navbar.Brand>
        <Navbar.Collapse
          id='basic-navbar-nav'
          className={classes.navbarCollapse}
        >
          <button
            className={` ${classes.hideMenue}`}
            onClick={() => setExpanded(false)}
          >
            <img
              src={Fermer}
              alt=''
              className={`img-fluid rounded float-left`}
            />
          </button>
          {headerState.hasMenu && getLinks()}
        </Navbar.Collapse>
        <Navbar.Brand>
          <Form inline>
            {location === '/' && (
              <Button
                as={Link}
                target='_blank'
                variant={'secondary'}
                className={`rounded-pill secondaryButton authButton ${classes.tpeAccess}`}
                to={'/offres-tpe'}
              >
                {institutionalWording.HOME_TPE_ACCESS}
              </Button>
            )}
            {!isAuthenticated && (
              <Button
                as={Link}
                variant={'secondary'}
                className={'rounded-pill secondaryButton authButton'}
                to={'/auth'}
              >
                {authWording.LOG_IN}
              </Button>
            )}
            {isAuthenticated && (
              <Button
                variant={'secondary'}
                onClick={logOut}
                className={'rounded-pill secondaryButton authButton'}
                to={'/auth'}
              >
                {authWording.LOG_OUT}
              </Button>
            )}
          </Form>
        </Navbar.Brand>
      </Navbar>
    </div>
  );
}
export default Header;
