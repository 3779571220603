import { createSelector } from 'reselect';
import { AUTH_REDUCER } from './reducer.auth';
import { ACCOUNT_TYPE_KEY } from 'common/utils/localStorage.util';

const getAuth = state => state.get(AUTH_REDUCER);

export const getProfileStepSelector = createSelector(
  getAuth,
  auth => auth.get('currentStep'),
);

export const getRegisterData = createSelector(
  getAuth,
  auth => auth.get('register').toJS(),
);

export const getAuthErrorsSelector = createSelector(
  getAuth,
  auth => auth.get('authErrors').toJS(),
);

export const isAuthenticatedSelector = createSelector(
  getAuth,
  auth => auth.get('isAuthenticated'),
);

export const getTribunals = createSelector(
  getAuth,
  auth => auth.get('tribunals'),
);

export const getTribunalsCode = (label = 'value', value = 'value') =>
  createSelector(
    getTribunals,
    tribunals =>
      tribunals.map(tribunal => ({
        value: tribunal[value],
        label: tribunal[label],
      })),
  );

export const getBusnessCenters = createSelector(
  getAuth,
  auth => auth.get('bcs'),
);

export const getValidateAccount = createSelector(
  getAuth,
  auth => auth.get('validateAccountError'),
);

export const getChangePasswordData = createSelector(
  getAuth,
  auth => auth.get('changePassword').toJS(),
);

export const getTiersListSelector = createSelector(
  getAuth,
  auth => auth.getIn(['bboAuth', 'tiersList']).toJS(),
);

export const getAccoutTypeSelector = createSelector(
  getAuth,
  auth => auth.get(ACCOUNT_TYPE_KEY),
);

export const getProspectData = createSelector(
  getAuth,
  auth => auth.get('prospect').toJS(),
);
