import { colors } from 'core/root.theme';

export const sharedStyle = {
  cardContainer: {
    display: 'flex',
    justifyItems: 'start',
    flexWrap: 'wrap',
  },
  groupTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    fontSize: 20,
    marginTop: 20,
    minHeight: 38,
  },
  devider: {
    flexGrow: 2,
    height: 1,
    backgroundColor: colors.shallowGray + ' !important',
  },
};
