import React from 'react';
export const subMenuWording = {
  EXPLOITATION: [
    {
      title: 'Avances et découverts',
      links: [
        {
          text: 'Facilité de Caisse',
          to: '/facilite-caisse',
          suTitle:
            'PRÉVENEZ TOUT DÉCALAGE DE TRÉSORERIE AVEC LA SOLUTION DE FACILITÉ DE CAISSE DE BANK OF AFRICA',
          description:
            'Adaptée au niveau d’activité de votre entreprise, la Facilité de Caisse est très simple d’utilisation et répond à votre besoin ponctuel de financement par une avance de trésorerie :',
          items: [
            'Vous êtes assuré de gagner en souplesse dans votre gestion de trésorerie et de renflouer votre budget entreprise dans la limite du plafond autorisé',
            'Une fois le montant de la ligne de Facilité Caisse accordé et les garanties constituées, vous êtes libre de l’utiliser ou non, au fur et à mesure de l’évolution de votre activité',
            'Les intérêts sont calculés uniquement en fonction du nombre de jours pendant lesquels le crédit de trésorerie a été effectivement utilisé, et sur le montant dont vous avez réellement disposé',
            'Il vous est possible de renouveler notre solution de Facilité de Caisse autant de fois que vous le souhaitez',
          ],
          synthesis:
            'Optez pour une solution de facilité de caisse adaptée à votre cycle d’exploitation avec BANK OF AFRICA.',
          action: true,
        },
        {
          text: 'Découvert',
          to: '/bmce-decouverte',
          suTitle:
            'BESOIN DE FINANCER VOTRE PROJET D’ENTREPRISE ET DE TROUVER LA SOLUTION ADÉQUATE ?',
          description:
            'Pour couvrir les dépenses nécessaires à votre activité, l’autorisation de découvert de BANK OF AFRICA offre plusieurs avantages :',
          items: [
            'Utilisable immédiatement, l’avance de trésorerie accordée par BANK OF AFRICA répond spécifiquement aux besoins de votre secteur d’activité',
            'Notre solution finance votre besoin de trésorerie sur plusieurs mois, le temps pour votre entreprise de gérer ses dépenses et de renflouer son budget',
            'Son coût est proportionnel à la durée du découvert autorisé et aux montants accordés',
          ],
          synthesis:
            'Financez immédiatement vos projets grâce à notre solution personnalisée Découvert.',
          action: true,
        },
        {
          text: 'Crédit de Campagne',
          to: '/credit-campagne',
          suTitle:
            'FACILITÉ DE CAISSE ADAPTÉE À L’ACTIVITÉ SAISONNIÈRE, CRÉDIT DE CAMPAGNE S’AJUSTE À VOTRE CALENDRIER DE DÉPENSES',
          description:
            'Conçu pour répondre aux besoins spécifiques du secteur agroalimentaire, notre Crédit de Campagne couvre vos dépenses saisonnières :',
          items: [
            'La solution de Crédit de Campagne s’ajuste parfaitement au cycle de production spécifique de l’industrie agroalimentaire (achat d’engrais, embouche, semences etc.)',
            'Souple d’utilisation, sa ligne de découvert vous permet de gérer votre trésorerie à bon escient au gré des besoins en lien avec votre activité',
            'Son coût est proportionnel à la durée du découvert autorisé et aux montants accordés',
            'Le crédit est remboursable à échéance fixe pouvant aller jusqu’à 12 mois',
          ],
          synthesis:
            'Donnez à votre secteur d’activité le moyen de se développer avec BANK OF AFRICA.',
        },
        {
          text: 'Avances sur Marchandise',
          to: '/avances-sur-marchandise',
          suTitle:
            'POUR LA BONNE GESTION DES STOCKS DE VOTRE ENTREPRISE, BANK OF AFRICA DÉPLOIE SA SOLUTION D’AVANCES SUR MARCHANDISE',
          description:
            'Complément idéal de vos besoins de trésorerie, cette avance de fonds vous garantit un soutien au bon fonctionnement de votre activité. L’option d’Avances sur Marchandise représente :',
          items: [
            'Un financement pouvant couvrir jusqu’à 80 % de la valeur de votre stock de marchandise.',
            'Une aide financière renouvelable, spécifique à votre activité et valable sur une durée de 3 mois.',
          ],
          synthesis:
            'Bénéficiez d’une avance sur la valeur de votre marchandise avec la solution adaptée de BANK OF AFRICA.',
          action: true,
        },
        {
          text: 'Avance sur Marché Nanti',
          to: '/avance-sur-marche-nanti',
          suTitle:
            'LE RECOUVREMENT IDÉAL DE VOS CRÉANCES SUR L’ADMINISTRATION PUBLIQUE',
          description:
            'Votre entreprise détient des marchés publics ? Disposez immédiatement de vos créances sur l’Etat grâce notre solution d’Avance sur Marché Nanti :',
          items: [
            'Les avances couvrent jusqu’à 80 % des créances publiques sur simple nantissement du marché en faveur de votre partenaire BANK OF AFRICA',
            'Sur la base des droits constatés par l’administration publique, BANK OF AFRICA vous octroie une avance financière sous forme de liquidité immédiatement disponible',
            'Les avances sont versées directement sur le compte de votre entreprise après remise des attestations',
          ],
          synthesis:
            'Recouvrez vos créances sur l’Etat grâce à la solution d’Avance sur Marché Nanti de BANK OF AFRICA.',
          action: true,
        },
        {
          text: 'Warrant',
          to: '/bmce-warrant',
          suTitle:
            'FINANCEZ VOTRE GESTION DES STOCKS ET SOULAGEZ VOTRE TRÉSORERIE GRÂCE À L’AVANCE WARRANT',
          description:
            'Le Warrant est un bulletin de gage remis par des Magasins Généraux agrées par l’Etat au dépôt de votre marchandise. Leur acquisition vous procure une avance avec de nombreux avantages :',
          items: [
            'L’avance en compte Warrant est effective à la remise des documents dûment remplis en faveur de votre partenaire BANK OF AFRICA',
            'Le montant de ce crédit peut atteindre 80 % de la valeur de la marchandise voire 100 % dans certains cas',
            'Le remboursement du crédit est lié aux ventes effectuées par votre entreprise',
            "Pour couvrir vos besoins de crédit, les produits et matières figurant sur une liste établie par l'administration peuvent faire l'objet d'un nantissement sans dépossession",
          ],
          synthesis:
            'Optimisez votre gestion de stock grâce à notre solution Warrant.',
        },
        {
          text: 'Avance Finea',
          to: '/avance-finea',
          suTitle:
            'PRENEZ UN BON DÉPART DANS LE FINANCEMENT DE VOS MARCHÉS PUBLICS',
          description: (
            <>
              Vous êtes une entreprise intéressée par l’acquisition de marchés
              et souhaitez trouver les moyens de financement appropriés à cet
              objectif ?<br />
              Pour accompagner et concrétiser vos ambitions, BANK OF AFRICA a
              conçu l’Avance Finea en collaboration avec la Caisse Marocaine des
              Marchés (CMM) :
            </>
          ),
          items: [
            'Crédit à court terme garanti, l’Avance Finea facilite le financement de votre marché public et peut s’étendre sur une durée globale de 6 à 12 mois',
            'L’Avance Finea couvre jusqu’à 90% de la valeur du marché',
            'La CMM intervient pour garantir les prêts que nous vous accordons en échange du nantissement des marchés concernés en sa faveur',
            'Vous choisissez entre trois types de financement : Crédit de préfinancement, Crédit d’accompagnement ou Crédit de mobilisation',
          ],
          synthesis:
            'L’Avance Finea de BANK OF AFRICA, votre meilleur allié financier pour aborder les marchés publics en toute sérénité.',
          action: true,
        },
        {
          text: 'Avance sur Titres',
          to: '/avance-sur-titres',
          suTitle:
            'UNE MANIÈRE EFFICACE D’OPTIMISER VOTRE PLAN DE TRÉSORERIE GRÂCE À VOS TITRES EN BOURSE',
          description:
            'L’Avance sur Titres de BANK OF AFRICA offre à votre entreprise l’occasion de tirer profit de vos titres cotés en bourse par le biais de leur nantissement. Ce crédit à court terme garantit :',
          items: [
            'Une réponse immédiate à votre besoin de liquidité qui permet de soulager votre gestion de la trésorerie',
            'L’octroi d’une avance dont le montant est proportionnel à la valeur boursière moyenne de vos titres',
          ],
          synthesis:
            'Avec l’avance sur Titres de BANK OF AFRICA, profitez de vos titres cotés en Bourse et oxygéner votre trésorerie.',
          action: true,
        },
        {
          text: 'Crédit Relais',
          to: '/credit-relais',
          suTitle:
            'UNE AVANCE DE FONDS PROVISOIRE POUR RECADRER VOTRE BUDGET DE TRÉSORERIE',
          description:
            'En attendant une rentrée d’argent certaine sur vos comptes, BANK OF AFRICA intervient grâce au Crédit Relais :',
          items: [
            'Une solution immédiate de renflouement de votre budget d’entreprise',
            'Le Crédit Relais de BANK OF AFRICA pallie le décalage de trésorerie par l’octroi d’un crédit à court terme',
            'Rapide et provisoire, son coût est proportionnel à la durée d’utilisation',
            'Grâce au Crédit Relais de BANK OF AFRICA, bénéficiez d’une avance de fonds et concentrez vos efforts sur le développement de votre activité.',
          ],
        },
      ],
    },
    {
      title: "Crédit d'escompte",
      links: [
        {
          text: 'Escompte Commercial Maroc',
          to: '/escompte-commercial-maroc',
          suTitle: 'EQUILIBREZ VOTRE BUDGET À UN TAUX AVANTAGEUX',
          description: (
            <>
              L’escompte commercial vous permet de faire face aux délais de
              paiement de vos clients en tenant compte de vos effets de commerce
              dans votre chiffre d’affaires.
              <br />
              Cette solution vous offre plusieurs avantages :
            </>
          ),
          items: [
            'Elle facilite le financement de vos marchés publics par l’octroi d’une ligne d’escompte',
            'Le prêt accordé est garanti par la CMM (Caisse Marocaine des Marchés)',
            'Le financement peut durer globalement de 6 à 12 mois avec un montant variant de 75 à 90 % de la valeur du marché',
          ],
          synthesis:
            'Grâce à la solution d’escompte commercial, les délais n’auront plus d’impact sur votre trésorerie.',
          action: true,
        },
        {
          text: 'Escompte Commercial Etranger',
          to: '/escompte-commercial-etranger',
          suTitle:
            'ENTREPRISES EXPORTATRICES, PALLIEZ AU DÉLAI DE PAIEMENT DE VOTRE ACHETEUR ÉTRANGER',
          description:
            'La solution de Crédit Escompte Commercial Etranger de BANK OF AFRICA ou mobilisation des créances nées vous permet de :',
          items: [
            "Disposer immédiatement d’une avance de fonds correspondant à la valeur des marchandises exportées, en dirhams ou en devises, sans attendre l'échéance des documents tirés sur votre client étranger",
            'De couvrir le risque lié au change et de minimiser les tensions éventuelles avec les clients concernant les délais ',
          ],
          synthesis:
            'Disposez sans délai du montant de vos exportations avec l’Escompte Commercial Etranger de BANK OF AFRICA.',
          action: true,
        },
        {
          text: 'Escompte sans Recours',
          to: '/escompte-sans-recours',
          suTitle:
            'RÉGLEZ VOS FOURNISSEURS EN TEMPS VOULU DANS VOTRE ACTIVITÉ D’IMPORTATION',
          description:
            'Un délai de paiement peut ternir le rapport commercial avec vos fournisseurs et faire fléchir vos importations… Pour l’éviter, le principe de l’Escompte sans Recours est simple :',
          items: [
            'Votre fournisseur étranger s’adresse à l’entité BANK OF AFRICA de son pays ; il lui cède les créances qu’il détient sur vous et les crédite immédiatement de la valeur correspondante',
            'Sans frais de commission, l’Escompte sans Recours vous permet de sécuriser votre gestion des approvisionnements (matières premières, marchandises etc…)',
            'Grâce à l’Escompte sans Recours, vous facilitez la relation client fournisseur pour le bien de votre entreprise',
          ],
          synthesis:
            'Partenaire incontournable de votre commerce, BANK OF AFRICA vous facilite l’import avec l’Escompte sans Recours.',
        },
        {
          text: 'Crédit Acheteur',
          to: '/credit-acheteur',
          suTitle:
            'UNE LIGNE DE CRÉDIT CONÇUE POUR AIDER L’IMPORTATEUR MAROCAIN RÉSIDENT À L’ÉTRANGER',
          description:
            'Destiné à financer votre contrat d’exportation de biens d’équipement ou de services, le Crédit Acheteur de BANK OF AFRICA vous permet :',
          items: [
            'De réaliser vos importations grâce à l’octroi de fonds par une banque étrangère dans le cadre d’une convention signée avec BANK OF AFRICA',
            'De régler le fournisseur dans les termes de votre contrat commercial',
            'Crédit à moyen et long terme, le Crédit Acheteur peut vous permettre de bénéficier d’un délai de paiement sur plusieurs années',
          ],
          synthesis:
            'Entrepreneur marocain à l’étranger, importez vos marchandises en toute facilité grâce au crédit acheteur de BANK OF AFRICA.',
        },
      ],
    },
    {
      title: 'Cautions',
      links: [
        {
          text: 'Caution Provisoire',
          to: '/caution-provisoire',
          suTitle: 'OPTIMISEZ VOTRE PARTICIPATION AUX MARCHÉS PUBLICS',
          description:
            'Votre candidature à l’adjudication aux marchés publics est facilitée par la délivrance d’une Caution Provisoire BANK OF AFRICA à l’administration concernée :',
          items: [
            "Système efficace et rapide, la validité de la caution bancaire échoit le jour de l'attribution du marché, à l’ouverture des plis",
            'Vous êtes dispensé de dépôts de fonds, ainsi votre trésorerie se voit soulagée',
            'Le recours à la Caution Provisoire de BANK OF AFRICA donne une impulsion à votre candidature aux appels d’offre du marché',
          ],
          synthesis:
            'Avec BANK OF AFRICA, obtenez vos cautions provisoires en toute facilité.',
          action: true,
        },
        {
          text: 'Caution Définitive',
          to: '/caution-definitive',
          suTitle: 'OPTIMISEZ VOTRE PARTICIPATION AUX MARCHÉS PUBLICS',
          description:
            'Votre contrat d’adjudication au marché public se voit garanti par la délivrance d’une Caution Définitive à l’administration concernée :',
          items: [
            'L’administration du marché public dont vous êtes adjudicataire est assurée contre le risque d’abandon du marché au cours de sa réalisation',
            'Le montant de la caution de bonne fin se situe entre 3 et 5 % du montant initial du marché ',
            'La démarche de cautionnement par votre partenaire BANK OF AFRICA peut s’étaler dans le temps',
            "Sa validité demeure jusqu'à réception définitive du marché par l'Administration avec l'achèvement des travaux",
          ],
          synthesis:
            'Avec BANK OF AFRICA, bénéficiez de vos cautions définitives en toute souplesse.',
          action: true,
        },
        {
          text: "Caution de Restitution d'Acompte",
          to: '/caution-restitution',
          suTitle:
            'ENTREPRISES, FACILITEZ VOTRE ACCÈS AUX AVANCES DE L’ADMINISTRATION PUBLIQUE',
          description:
            'Vous êtes entrepreneur et souhaitez démarrer des travaux ? Vous avez besoin de fonds pour les initier ? BANK OF AFRICA vous garantit de toucher un acompte pour travaux grâce à sa Caution de Restitution d’Acompte. Le principe est simple :',
          items: [
            'La Caution de Restitution d’Acompte de BANK OF AFRICA est une garantie de remboursement de l’acompte que le maître d’ouvrage (l’Administration Publique) vous a versé en vertu d’un contrat commercial ',
            'Cette restitution de fonds est effective en cas d’inexécution totale ou partielle du contrat',
            'Le montant de la caution correspond au montant de l’acompte dont vous bénéficiez, généralement 15% du montant du contrat',
            'La Caution de Restitution d’Acompte de BANK OF AFRICA est valide jusqu’à échéance du contrat commercial qui coïncide avec la livraison ou fin de prestation, garantissant le bon achèvement des travaux',
          ],
          synthesis:
            'Avec la Caution De Restitution d’Acompte, garantissez vos acomptes et donnez du souffle à votre trésorerie.',
          action: true,
        },
        {
          text: 'Caution de Retenue de Garantie',
          to: '/caution-retenue-garantie',
          suTitle:
            'PRÉMUNISSEZ-VOUS CONTRE LA RETENUE DES FONDS SUR LA RÉALISATION DES MARCHÉS PUBLICS',
          description: (
            <>
              Votre entreprise prévoit de réaliser un marché public pour
              conduire des travaux ?<br />
              L’Administration Publique est en droit de retenir des fonds en cas
              de non-respect des clauses du cahier des charges.
              <br />
              La Caution de Retenue de Garantie de BANK OF AFRICA vous permet de
              :
            </>
          ),
          items: [
            'Déroger à l’obligation de retenue de fonds que l’Administration vous impose en prévention d’un éventuel non-respect du contrat dans l’exécution des travaux financés par le marché réalisé',
            'Soulager la trésorerie de votre entreprise en encaissant jusqu’à 100 % du montant des travaux réalisés',
            'Réaliser vos projets en toute confiance avec votre partenaire BANK OF AFRICA, en contrepartie d’un prélèvement direct couvrant la retenue d’acompte éventuelle par le maître d’ouvrage après constat des travaux',
          ],
          synthesis:
            'Avec la Caution de Retenue de Garantie de BANK OF AFRICA, vous prenez soin de vos fonds et de votre trésorerie.',
          action: true,
        },
      ],
    },
    {
      title: 'Crédits avec garanties d’organismes étatiques',
      links: [
        {
          text: 'Crédit Business Express',
          to: '/credit-business-express',
          suTitle: 'LE CRÉDIT D’AIDE AUX PME',
          description:
            'Conçu pour répondre aux besoins des petites entreprises, le crédit Business Express vous permet de financer un investissement ou de disposer d’une réserve de liquidités à conditions avantageuses :',
          items: [
            'Le crédit Business Express, en partenariat avec la Caisse Centrale de Garantie, finance aussi bien le cycle d’exploitation de votre entreprise que vos investissements',
            'Le traitement est rapide : la décision se fait sous trois jours ouvrables suite à la réception du dossier',
            'Dans un souci de qualité et de transparence de nos prestations, votre chargé d’affaire BANK OF AFRICA reste en contact direct dans le cadre d’un dialogue régulier ',
          ],
          synthesis:
            'Donnez à votre entreprise les moyens de sa réussite, contactez votre conseiller BANK OF AFRICA pour de plus amples informations sur le produit Business Express.',
        },
        {
          text: 'Damane Exploitation',
          to: '/damane-exploitation',
          suTitle: 'UNE GARANTIE POUR VOTRE CRÉDIT D’EXPLOITATION',
          description:
            'Conçu pour aider les entreprises dans l’obtention de crédits d’exploitation, Damane Exploitation est une garantie de la Caisse Centrale de Garantie (CCG) qui vous ouvre les portes de votre activité :',
          items: [
            'La garantie est adossée au crédit de fonctionnement que vous avez sollicité et réponds aux besoins en fonds de roulement de votre entreprise',
            'Votre partenaire BANK OF AFRICA se charge d’assurer le rôle d’intercesseur auprès de la CCG pour faire valider votre dossier dans les dix jours ouvrables',
            'La garantie est valable 18 mois à compter de la date de la notification et en cas de non renouvellement',
            'Damane Exploitation est accessible aux entreprises opérant dans tous les secteurs d’activité à l’exception de la promotion immobilière et de la pêche hauturière',
          ],
          synthesis:
            'Faites le choix d’un crédit d’exploitation garanti avec Damane Exploitation.',
        },
      ],
    },
    {
      title: 'Gestion Poste client',
      links: [
        {
          text: 'Factor',
          to: '/bmce-factor',
          suTitle: 'LE RECOUVREMENT DES CRÉANCES DÉTENUES SUR VOS CLIENTS',
          description:
            'Ne laissez pas un impayé ou un retard de paiement impacter votre trésorerie. La solution Factor permet d’externaliser la gestion de votre poste client et garantit de nombreux avantages pour votre entreprise :',
          items: [
            'Factor assure la gestion de vos créances et leur recouvrement total ou partiel grâce à notre filiale Maroc Factoring par un simple transfert de factures',
            'Vous obtenez une garantie à 100 % contre les risques d’impayé, que votre client se trouve au Maroc ou à l’étranger',
            'Notre solution Factor vous offre une visibilité sur la solvabilité de vos clients de manière à garantir et pérenniser la viabilité de vos accords commerciaux',
          ],
          synthesis:
            'Améliorez le recouvrement de vos créances clients et prenez soin de votre trésorerie avec Factor.',
          action: true,
        },
      ],
    },
  ],
  INVESTISSEMENT: [
    {
      title: 'Crédits moyen et long Terme',
      links: [
        {
          text: 'Crédit MLTD Direct',
          to: '/credit-mltd-direct',
          suTitle:
            'UNE AIDE SUR LA DURÉE SOUHAITÉE POUR LE DÉVELOPPEMENT DE VOTRE ENTREPRISE',
          description: (
            <>
              Vous avez prévu un investissement destiné à créer, équiper ou
              financer l’extension de votre entreprise ?<br />
              Votre partenaire BANK OF AFRICA vous accompagne sur la durée dans
              votre projet de modernisation par l’octroi d’un Crédit à Moyen et
              Long Terme offrant de multiples avantages :
            </>
          ),
          items: [
            'Le montant de l’aide peut atteindre 70% du coût total d’investissement, fonds de roulement compris',
            'Aisément ajustable, le crédit entreprise de BANK OF AFRICA peut être cumulé avec d’autres sources de financement comme le leasing, un crédit en devise ou le recours à une ligne extérieure)',
            "La durée de remboursement peut atteindre 7 ans pour le moyen terme et 12 ans pour le long terme, avec possibilité d'un différé de 2 à 3 ans",
            'Ce Crédit à Moyen et Long Terme s’adapte aux besoins spécifiques de votre entreprise ainsi qu’à sa taille et son statut (PME, grande société, entreprise publique ou entreprise privée)',
          ],
          synthesis:
            'Parce que vous avez des besoins réguliers en investissements, BANK OF AFRICA a créé le Crédit MLTD Direct.',
        },
        {
          text: 'Promotion Immobilière et Touristique',
          to: '/promotion-immo-touristique',
          suTitle:
            'CONCRÉTISEZ VOTRE PROJET IMMOBILIER GRÂCE À NOTRE CRÉDIT DÉDIÉ',
          description:
            'Vous êtes promoteur immobilier et souhaitez financer votre projet de construction à taux avantageux ? En optant pour notre crédit dédié aux promoteurs immobiliers, vous bénéficiez d’une solution de financement ajustée à vos besoins :',
          items: [
            'Le crédit immobilier de BANK OF AFRICA finance la construction de bureaux, commerces ou appartements destinés à la vente en copropriété',
            'Il couvre jusqu’à 100% du coût des constructions mais également des travaux de viabilisation du terrain',
            'Solution souple et rapide, notre crédit dédié au financement de la promotion immobilière et touristique finance tous les promoteurs immobiliers résidents ou installés au Maroc',
            'Votre partenaire BANK OF AFRICA met en place un programme d’accompagnement et de conseils gratuit tout au long du projet promotionnel',
          ],
          synthesis:
            'Profitez du Crédit Promotion Immobilière et Touristique BANK OF AFRICA et financez votre projet d’immobilier professionnel.',
        },
      ],
    },
    {
      title: 'Leasing',
      links: [
        {
          text: 'Bail Entreprise',
          to: '/bmce-bail-entreprise',
          suTitle:
            'ACQUÉREZ LE MATÉRIEL INDISPENSABLE À LA CROISSANCE DE VOTRE ENTREPRISE',
          description:
            'Pour vous aider dans l’acquisition de votre équipement d’entreprise, votre partenaire BANK OF AFRICA a mis au point Bail entreprises, une solution de crédit bail qui vous permet :',
          items: [
            'De financer un équipement neuf ou d’occasion, local ou importé : matériel informatique, mobilier de bureau, matériel roulant, engins de travaux, véhicules de tourisme etc.',
            'De disposer du matériel de votre choix jusqu’à terme du contrat de bail',
            'De bénéficier à terme d’une option d’achat sur le matériel dont vous avez disposé',
          ],
          synthesis:
            'Donnez de l’envergure à votre activité avec Bail entreprises.',
        },
      ],
    },
    {
      title: 'Financement des projets Agricoles',
      links: [
        {
          text: 'Agridev',
          to: '/bmce-agridev',
          suTitle: 'MODERNISEZ VOTRE DOMAINE AGRICOLE AVEC Agridev',
          description: (
            <>
              Vous êtes propriétaire d’une exploitation agricole et vous
              souhaitez lui donner les moyens d’extension indispensables à sa
              réussite ?<br />
              Le crédit Agridev vous permet de développer votre activité de
              ferme ou agro industrielle par :
            </>
          ),
          items: [
            'Une couverture allant jusqu’à 80 % de votre projet d’investissement agricole',
            'L’acquisition d’équipement agricole ou l’installation d’une exploitation agricole',
            'L’augmentation de vos capacités de production',
            'Le financement de la logistique de production',
          ],
          synthesis:
            'Votre conseiller BANK OF AFRICA est à votre écoute pour tout renseignement complémentaire.',
        },
      ],
    },
    {
      title: 'Cofinancements',
      links: [
        {
          text: 'Taalim Invest',
          to: '/bmce-taalim-invest',
          suTitle:
            'MODERNISEZ VOTRE ÉTABLISSEMENT SCOLAIRE PRIVÉ GRÂCE AU CRÉDIT Taalim Invest',
          description:
            'Vous avez un projet de réaménagement, d’extension ou de transformation de vos locaux d’enseignement privé ? BANK OF AFRICA vous propose une solution de financement de vos établissements scolaires ou de la formation privés :',
          items: [
            'Le crédit d’investissement Taalim Invest vous garantit une couverture optimale de vos frais de projets grâce au partenariat avec la CCG dont la participation s’élève à 30 %',
            'Vous bénéficiez d’un financement pouvant atteindre 12 ans et qui permet un différé de remboursement de 4 ans',
            'Taalim Invest peut aussi être accompagné d’un contrat de leasing pour financer le matériel roulant ou pour l’acquisition du matériel pédagogique ',
          ],
          synthesis:
            'Taalim Invest est au service de la modernisation de votre établissement scolaire privé.',
        },
        {
          text: 'Renovotel',
          to: '/bmce-renovotel',
          suTitle:
            'RÉNOVEZ VOTRE HÔTEL ET AMÉLIOREZ VOTRE IMAGE DE MARQUE GRÂCE AU FINANCEMENT Renovotel',
          description: (
            <>
              Vous êtes un professionnel de l’hôtellerie et souhaitez financer
              la mise à niveau de votre établissement ?<br />
              Renovotel est une solution de cofinancement avec la Caisse
              Centrale des Garanties qui vous permet de :
            </>
          ),
          items: [
            'Financer jusqu’à 70% du coût global du programme de rénovation des unités hôtelières existantes',
            'Consacrer un traitement spécial pour chaque catégorie d’unité hôtelière en termes de coût de rénovation par chambre',
            'Rembourser le crédit dans des conditions confortables puisque sa durée est fixée à 10 ans',
          ],
          synthesis:
            'Avec Renovotel, vous êtes à la hauteur de vos ambitions pour tous vos projets de rénovation.',
        },
      ],
    },
    {
      title: 'Financement de l’efficacité Énergétique',
      links: [
        {
          text: 'Cap Énergie',
          to: '/cap-energie',
          suTitle:
            'MODERNISEZ VOS ÉQUIPEMENTS ET AMÉLIOREZ VOTRE EFFICACITÉ ÉNERGÉTIQUE',
          description:
            'Vous êtes dirigeant d’une entreprise privée. Vous souhaitez effectuer une économie d’énergie sur votre activité. Cap Energie de BANK OF AFRICA vous propose une solution de crédit dédiée :',
          items: [
            'Vous êtes en mesure de financer vos projets d’extension de capacité ou de modernisation de vos équipements dans le but d’améliorer la maîtrise énergétique dans votre secteur d’activité',
            'L’accompagnement technique autour de vos projets d’investissement suite à l’acquisition de vos équipements est gratuit',
            'Vous inscrivez votre entreprise sur la voie du progrès lié aux énergies renouvelables, ce qui contribue à améliorer l’image de votre entreprise',
          ],
          synthesis:
            'Avec Cap Energie de BANK OF AFRICA, innovez et financez vos projets d’équipement et d’efficacité énergétique.',
        },
        {
          text: 'Fodep III',
          to: '/bmce-fodep-it',
          suTitle: 'FINANCEZ VOS RÉSOLUTIONS DE DÉPOLLUTION INDUSTRIELLE !',
          description: (
            <>
              FOPED III est un fonds sous forme de dons affecté à vos projets de
              dépollution industrielle ou artisanale. Ces dons sont jumelés à
              des crédits nourris par votre partenaire BANK OF AFRICA.
              <br />
              Il permet :
            </>
          ),
          items: [
            'Un financement de 20 à 40 % des projets en fonction de la nature de vos investissements : projet en aval (traitement ou élimination des déchets et émissions) ou projet intégré (déploiement de technologies propres et économie des ressources)',
            'L’optimisation de votre efficacité énergétique parallèlement aux efforts déployés pour le développement durable au Maroc et dans le respect de l’environnement',
            'Vous bénéficiez en outre de modalités de remboursement libres et à conditions avantageuses',
          ],
          synthesis:
            'Partenaire de tous vos projets, FODEP III accompagne votre projet de dépollution industrielle.',
        },
        {
          text: 'Cap Bleu',
          to: '/cap-bleu',
          suTitle:
            "Adapté à vos besoins, Cap Bleu vous permet de financer vos projets d'investissement dans le secteur de l'eau et de l’assainissement!",
          description:
            "Que vous soyez une entreprise privée ou publique de droit marocain, opérant dans le domaine industriel, Le secteur d’eau, d'agriculture, d’immobilier ou d’hôtellerie, Cap Bleu vous offre:",
          items: [
            "Un financement pouvant atteindre 100% des coûts d'investissement eligible;",
            "Une bonification du taux d'intérêt;",
            'Les intérêts sont calculés uniquement en fonction du nombre de jours pendant lesquels le crédit de trésorerie a été effectivement utilisé, et sur le montant dont vous avez réellement disposé',
            'Une assistance technique offerte gratuitement par un cabinet expert en la matière.',
            'Projets éligibles',
            "Projets d'investissement dans le secteur de l’eau et de l'assainissement;",
            "Projets d'économie d’eau, de dessalement et de création de stations de traitement d'eaux usées;",
            'Projets conformes aux lois nationales et internationales dans les domaines sociaux et environnementaux.',
          ],
          synthesis:
            "Pour tout complément d’information, nous vous invitons à contacter votre Chargé d'Affaires Entreprise.",
        },
      ],
    },
    {
      title: 'Crédits avec garanties D’organismes étatiques',
      links: [
        {
          text: 'Crédit Jeune Entreprise PRO',
          to: '/credit-jeune-entreprise-pro',
          suTitle:
            'JEUNES ENTREPRENEURS MAROCAINS, RÉALISEZ DÈS CE JOUR VOS AMBITIONS DE CRÉATION D’ENTREPRISE',
          description:
            'Vous êtes un jeune entrepreneur marocain décidé à monter votre propre affaire? BANK OF AFRICA se range à vos côtés pour initier votre projet d’entreprise dans les meilleures conditions :',
          items: [
            'Le crédit d’investissement octroyé par votre partenaire BANK OF AFRICA couvre jusqu’à 90% du coût du programme d’investissement agrée',
            'Pour un projet individuel, le montant du Crédit Jeune Entreprise PRO peut atteindre 1 000 000 Dhs',
            'Pour un projet commun, le montant du prêt peut atteindre 1 000 000 Dhs par associé ou par détenteur dans la limite de 3 000 000 Dhs au total',
            'Pour plus de sûreté, notre prêt est garanti par l’aval de la Caisse Centrale de Garantie à hauteur de 85%',
          ],
          synthesis:
            'Entrepreneurs en herbe, avec le Crédit Jeune Entreprise PRO de BANK OF AFRICA, lancez-vous dans l’aventure bien accompagné.',
        },
        {
          text: 'Croissance ',
          to: '/bmce-croissance',
          suTitle:
            'VOTRE GESTION D’ENTREPRISE PASSE PAR LA CROISSANCE AVEC BANK OF AFRICA',
          description:
            'En coopération avec la Caisse Centrale de Garantie, Croissance  s’adapte à votre cycle de production ainsi qu’à vos perspectives d’investissement et se décline en deux formules de financement :',
          items: [
            'La Formule Création est consacrée à vos investissements physiques tels que la construction d’immeubles, ou l’acquisition de matériel et  finance aussi vos projets immatériels tels que fonds de commerce, droit au bail et besoins en fonds de roulement',
            'La Formule Développement est allouée à vos projets d’extension d’entreprise et de développement de votre activité',
            'La durée du crédit varie entre 5 et 12 ans en fonction des besoins spécifiques de votre entreprise',
          ],
          synthesis: '',
        },
        {
          text: 'Relancia',
          to: '/bmce-relancia',
          suTitle:
            'RELANCEZ VOTRE ENTREPRISE ET OPTIMISEZ LE REMBOURSEMENT DE VOS DETTES',
          description: (
            <>
              BANK OF AFRICA, par le biais d’une convention signée avec la
              Caisse Centrale de Garantie, aide les entreprises privées de plus
              de 3 ans à gérer leur endettement.
              <br />
              Relancia est une solution de crédit qui accompagne votre
              entreprise dans son projet de restructuration de dettes.
              <br /> Elle vous permet :
            </>
          ),
          items: [
            'D’éclaircir votre situation de trésorerie en regroupant les crédits auxquels votre entreprise a souscrits en un seul',
            'De réduire le montant des échéances pour ne pas dépasser le taux d’endettement maximum',
            'De regrouper vos prêts et de réduire ainsi le montant des mensualités et les taux d’intérêt des sommes dues',
            'De bénéficier d’un crédit de restructuration de dette dont la durée peut atteindre 7 ans avec un amortissement croissant',
          ],
          synthesis:
            'L’expertise de BANK OF AFRICA est de votre côté pour vous aider à prendre un nouveau départ.',
        },
        {
          text: 'Intégra Textile',
          to: '/bmce-integra-textile',
          suTitle: 'LA FORMULE IDÉALE POUR PERCER DANS LE SECTEUR TEXTILE',
          description:
            'Afin de favoriser l’émergence des TPE et PME du secteur textile, BANK OF AFRICA en partenariat avec la CCG, propose une aide aux entreprises avec la formule de garantie Intégra Textile :',
          items: [
            'Adossé à un crédit à court terme, Intégra Textile finance votre cycle d’exploitation ',
            'Adossé à un crédit à moyen ou long terme, Intégra Textile finance la constitution des stocks de votre entreprise',
          ],
          synthesis:
            'Améliorez votre compétitivité et pérennisez votre activité textile avec l’aide de votre partenaire BANK OF AFRICA',
        },
      ],
    },
  ],
  INTERNATIONAL: [
    {
      title: 'Règlement à l’international',
      links: [
        {
          text: 'Virement International',
          to: '/virement-international',
          suTitle:
            'FACILITEZ VOTRE ACTIVITÉ D’IMPORT-EXPORT EN OPTANT POUR NOS VIREMENTS INTERNATIONAUX',
          description: (
            <>
              Dans votre activité internationale d’import-export, vous devez
              assurer l’encaissement du produit d’une exportation ainsi que le
              règlement des importations à votre fournisseur.
              <br />
              En ce cas, souscrire à un ordre de virement auprès de votre
              partenaire BME Bank, c’est :
            </>
          ),
          items: [
            'Simplifier les formalités liées à vos procédures de règlement international',
            'Un coût minime des opérations bancaires BANK OF AFRICA',
            'Recevoir directement sur votre compte le virement correspondant à la contre-valeur de la marchandise expédiée',
            'Un ordre de paiement irrévocable en devises en faveur de votre fournisseur étranger',
            'Une sécurité de transfert de fonds optimale grâce au réseau international SWIFT',
          ],
          synthesis:
            'Avec BANK OF AFRICA, réalisez vos virements internationaux en toute facilité.',
        },
        {
          text: 'Remise Documentaire',
          to: '/remise-documentaire',
          suTitle:
            'POUR PLUS D’EFFICACITÉ ET DE SÉCURITÉ DANS LE COMMERCE INTERNATIONAL DE VOTRE ENTREPRISE',
          description:
            'Votre partenaire BANK OF AFRICA gère efficacement l’échange des documents nécessaire au bon déroulement des transactions internationales de votre entreprise :',
          items: [
            'La Remise Documentaire à l’import vous permet de régler votre fournisseur de manière simple en activant l’ordre de virement bancaire et d’entrer rapidement en possession de votre marchandise importée',
            'L’ordre de paiement n’est décidé qu’après examen minutieux par les experts de BANK OF AFRICA de la conformité des documents d’expédition',
            'La Remise Documentaire à l’export facilite l’encaissement des fonds sur votre compte en échange des documents réclamés par la banque de votre client à l’étranger',
            'Mode de paiement conditionné, la Remise Documentaire de BANK OF AFRICA vous garantit le respect de vos échéances contractuelles',
          ],
          synthesis:
            'Facilitez vos transactions internationales grâce au service Remise Documentaire de BANK OF AFRICA.',
        },
        {
          text: 'Crédit Documentaire',
          to: '/credit-documentaire',
          suTitle:
            'POUR UNE SUPERVISION OPTIMALE DE VOTRE CONTRAT COMMERCIAL D’IMPORT-EXPORT',
          description:
            'Le Crédit Documentaire de BANK OF AFRICA vous permet de sécuriser vos transactions internationales à l’importation ou à l’exportation. Les opérations bancaires sont conditionnées :',
          items: [
            'La vérification des documents d’expédition par les experts de BANK OF AFRICA et leur conformité en vertu du Crédit Documentaire sont les strictes conditions pour que le paiement de votre client soit honoré',
            'La documentation requise au moment de l’émission vous informe en détail des caractéristiques de l’opération d’importation (montants, délais, quantités de marchandises…)',
            'Vos conseillers BANK OF AFRICA vous accompagnent dans vos négociations internationales jusqu’au dénouement final de la transaction',
          ],
          synthesis:
            'Sécurisez vos transactions internationales en toute quiétude avec le Crédit Documentaire de BANK OF AFRICA.',
          action: true,
        },
        {
          text: 'Lettre de Crédit Stand By',
          to: '/lettre-credit-stand-by',
          suTitle:
            'UNE GARANTIE RÉCIPROQUE SUR VOS TRANSACTIONS INTERNATIONALES D’IMPORT ET D’EXPORT',
          description:
            'Vous êtes importateur ou exportateur et cherchez à améliorer vos échanges commerciaux ?  L’ouverture d’une Lettre de Crédit Stand By vous permet d’obtenir une sécurité optimale dans vos transactions commerciales d’import et d’export avec vos partenaires commerciaux étrangers :',
          items: [
            'Vous êtes exportateur : en cas d’absence de paiement de la part de votre client étranger, vous utilisez la Lettre de Crédit Stand By sur simple présentation des documents preuves de la créance : la banque émettrice de votre client procédera au paiement',
            'Vous êtes importateur : selon le même principe, vous garantissez à votre fournisseur que la BANK OF AFRICA se substituera à vous en cas de nécessité',
            'Ce système de garantie rassure réciproquement les partenaires et contribue ainsi à améliorer la confiance autour de vos échanges commerciaux',
          ],
          synthesis:
            'La confiance est un vrai levier de réussite commerciale. A l’export comme à l’import, faites-le choix de Lettre de Crédit Stand By et rassurer vos parties prenantes.',
          action: true,
        },
      ],
    },
    {
      title: 'Cautions en douane',
      links: [
        {
          text: 'Crédit d’Enlèvement',
          to: '/bmce-credit-enlevement',
          suTitle: 'DIFFÉREZ LE PAIEMENT DE VOS FRAIS DOUANIERS À L’IMPORT ',
          description: (
            <>
              Vous êtes importateur et souhaitez fluidifier les démarches
              douanières à l’arrivée de vos marchandises ?
              <br />
              Choisir la solution de Crédit d’Enlèvement de BANK OF AFRICA,
              c’est se munir d’une astuce simple et avantageuse pour votre
              gestion d’entreprise :
            </>
          ),
          items: [
            'Vous obtenez un délai supplémentaire avant le règlement des droits de douane et taxes douanières',
            'Vous bénéficiez d’un report de paiement de 30 jours maximum',
            'Votre marchandise vous est remise immédiatement sans attendre la liquidation douanière  et évitez retard dans son traitement',
          ],
          synthesis:
            'Disposez très facilement de vos marchandises à la douane avec Crédit d’Enlèvement.',
          action: true,
        },
        {
          text: 'Obligation Cautionnée',
          to: '/bmce-obligation-cautionnee',
          suTitle: 'BÉNÉFICIEZ D’UN LONG DÉLAI POUR RÉGLER VOS FRAIS DOUANIERS',
          description: (
            <>
              Vous avez engagé une opération d’importation et souhaitez différer
              avantageusement vos obligations douanières ?<br />
              La caution douanière Obligation Cautionnée de BANK OF AFRICA est
              une solution idéale pour vos importations :
            </>
          ),
          items: [
            'Vous pouvez échelonner le règlement de vos droits de douane dans le temps et à votre convenance',
            'Vous bénéficiez d’un délai de 120 jours avant le règlement des frais et taxes dus aux bureaux de douane',
            'Ce délai vous donne le temps d’écouler la marchandise avant de payer le tarif dû à la douane marocaine et l’encaissement de la valeur de votre produit à la revente facilite le paiement',
          ],
          synthesis:
            'Gérez vos importations de manière optimale grâce à la solution Obligation Cautionnée.',
          action: true,
        },
        {
          text:
            'BANK OF AFRICA Avance à Court Terme pour le Financement des Droits de Douane',
          to: '/bmce-avance-financement-douane',
          suTitle: 'FACILITEZ LE PAIEMENT DE VOS FRAIS D’IMPORTATION',
          description:
            'Pour aider votre entreprise dans son activité d’import, BANK OF AFRICA vous propose une avance à court terme pour régler vos frais envers la douane marocaine :',
          items: [
            'Vous bénéficiez d’une avance sur vos obligations douanières pour une durée pouvant atteindre 180 jours',
            'Cette avance permet à votre entreprise de s’acquitter immédiatement des droits de douanes et de disposer de la marchandise importée dans les plus brefs délais',
            'La TVA de douane est acquittée à un taux inférieur à celui de l’Obligation Cautionnée',
          ],
          synthesis:
            'Assurez à votre marchandise un passage en douane efficace avec la solution BANK OF AFRICA Avance à Court Terme',
          action: true,
        },
        {
          text: 'Admission Temporaire ',
          to: '/bmce-admission-temporaire',
          suTitle: 'UNE DISPENSE TEMPORAIRE OU DÉFINITIVE DES FRAIS DE DOUANE',
          description: (
            <>
              Dans le cadre des régimes économiques en douane, BANK OF AFRICA
              vous permet de suspendre temporairement le règlement des droits de
              douane pour faciliter vos transactions internationales.
              <br />A cet effet, la Caution d’Admission Temporaire est utile à
              plusieurs niveaux :
            </>
          ),
          items: [
            'La suspension qui en découle vous donne le temps de procéder aux transformations sur la marchandise importée, ou de l’employer pour exposition, réalisation de travaux, essais etc.).',
            'Vous bénéficiez d’une exonération totale ou partielle des frais de douane',
            'Ce régime particulier s’applique à toutes les marchandises identifiables destinées à être réexpédiées en l’état',
            'La durée réglementaire de l’Admission Temporaire de BANK OF AFRICA est de 6 mois, mais il vous est possible d’obtenir une prolongation allant jusqu’à 2 ans',
          ],
          synthesis:
            'Contactez les experts de BANK OF AFRICA pour vos solutions douanières.',
          action: true,
        },
        {
          text: 'Importations et Exportations Temporaires',
          to: '/bmce-imputations-temporaire',
          suTitle:
            'SUSPENDEZ LE RÈGLEMENT DES DROITS DE DOUANE DE VOTRE ACTIVITÉ D’IMPORT EXPORT',
          description:
            'Pour importer ou exporter les marchandises à usage temporaire, BANK OF AFRICA vous procure les moyens de suspendre le règlement des droits et taxes dus à la douane marocaine :',
          items: [
            'La Caution d’Importation Temporaire vous permet de suspendre le règlement des droits et taxes dus à la douane sur certains produits ou équipements. Ceux-ci doivent être réexportés en l’état dans un délai de 6 mois à 2 ans',
            'La Caution d’Exportation Temporaire vous offre la possibilité de faire sortir certains produits en suspension des droits et taxes pour l’usage personnel des personnes résidents habituellement au Maroc',
            'Vous évitez le décaissement douanier dans le cadre d’une exposition temporaire ou d’une participation à une foire internationale',
            'Vous bénéficiez d’une souplesse d’utilisation dans la mise en place et d’un suivi garanti par votre conseiller spécialisé en tarif douanier de BANK OF AFRICA',
          ],
          synthesis:
            'Exonérez sans contrainte votre marchandise des droits de douane grâce à Importations et Exportations Temporaires.',
          action: true,
        },
        {
          text: 'Acquit à Caution',
          to: '/bmce-acquit-caution',
          suTitle:
            'FLUIDIFIEZ VOS TRANSACTIONS INTERNATIONALES GRÂCE À L’ACQUIT À CAUTION DE BANK OF AFRICA',
          description:
            'Crédit par signature et solution de financement des frais douaniers, l’Acquit à Caution vous permet de :',
          items: [
            'Faciliter le transit des marchandises de votre entreprise à l’import',
            'Couvrir le montant des droits de douane exigés pour retirer la marchandise',
            'Vous libérer des contraintes des formalités douanières dans la gestion de vos importations, et ce jusqu’à leur acheminement au bureau de douane finale',
            'Assurer le dédouanement de votre marchandise dans les meilleurs délais et à des tarifs très avantageux',
          ],
          synthesis:
            'Des formalités plus simples avec Acquit à Caution pour accélérer vos transactions',
        },
      ],
    },
    {
      title: 'Financements a l’international',
      links: [
        {
          text: 'Préfinancement à l’Exportation',
          to: '/prefinancement-exportation',
          suTitle: 'FINANCEZ LE CYCLE D’EXPLOITATION DE VOTRE ENTREPRISE',
          description:
            'La solution de préfinancement de BANK OF AFRICA vous offre une avance sur votre trésorerie qui vous permet d’optimiser les étapes du cycle de production de votre entreprise liées à votre activité d’exportation :',
          items: [
            'Une trésorerie immédiate couvre vos besoins de production, la gestion des approvisionnements en matières premières, en fournitures etc…',
            'Votre entreprise voit la réalisation de son contrat commercial assurée par la couverture des frais d’exportations ou des frais de stockage',
            'Vous êtes libre de rembourser ce crédit à court terme sur une durée pouvant atteindre 6 mois avec possibilité de prorogation',
            'Une couverture à terme vous permet d’éviter le risque de change',
          ],
          synthesis:
            'Avec le Préfinancement à l’Exportation de BANK OF AFRICA, profitez d’une avance sur trésorerie sur l’ensemble du cycle d’exploitation de votre activité.',
          action: true,
        },
        {
          text: 'Mobilisation de Créances sur l’Étranger',
          to: '/mobilisation-creances-etranger',
          suTitle:
            'UN RECOUVREMENT DE CRÉANCES PALLIANT LE DÉLAI DE PAIEMENT DE VOS CLIENTS ÉTRANGERS',
          description: (
            <>
              Votre entreprise exporte et vous accordez des délais à vos
              acheteurs étrangers ? BANK OF AFRICA vous permet de tirer parti de
              ce compromis.
              <br />
              La Mobilisation de Créances sur l’Etranger est une procédure
              simple qui permet aux exportateurs de recevoir le montant des
              créances à court terme qu’ils détiennent sur leurs partenaires
              commerciaux à l’étranger :
            </>
          ),
          items: [
            'C’est un crédit par trésorerie en Dirham suite à la mobilisation d’une créance en devises octroyée à votre entreprise',
            'Les fonds sont perçus dès la naissance de la créance en devises, c’est-à-dire à l’exportation des marchandises',
            'BANK OF AFRICA vous garantit l’avance de tout ou partie du montant du contrat d’exportation',
            'Votre trésorerie ainsi équilibrée vous permet, de votre côté, de régler vos fournisseurs locaux dans le respect des délais',
          ],
          synthesis:
            'Faites vivre votre business à l’export en toute quiétude avec les solutions de Mobilisation de Créances sur l’Etranger de BANK OF AFRICA.',
          action: true,
        },
        {
          text: 'Financement des Importations',
          to: '/financement-importations',
          suTitle: 'PAYEZ VOS FRAIS D’IMPORTATION ET LIMITEZ LES RISQUES',
          description:
            'Pour vos opérations à l’import, BANK OF AFRICA vous octroie une ligne de crédit à court terme qui permet d’optimiser la rotation des stocks de votre entreprise :',
          items: [
            'Le règlement de vos importations est immédiat tandis que l’impact sur les finances de votre entreprise est différé',
            'La trésorerie du client est optimisée, ce qui contribue à maintenir le climat de confiance autour de votre contrat commercial',
            'La prolongation du terme de règlement vous permet de procéder aux transformations nécessaires sur la marchandise importée avant sa revente',
            'Vous pouvez bénéficier d’une remise de la part de votre fournisseur et d’un différentiel éventuel entre le taux d’intérêt du Dirham et celui de la devise du financement',
            'BANK OF AFRICA a prévu des instruments de couverture contre le risque de change pour sa clientèle',
          ],
          synthesis:
            'Optez pour la solution de financement de BANK OF AFRICA pour obtenir des importations fluides et sûres',
          action: true,
        },
        {
          text: 'Escompte sans Recours ou Forfaiting',
          to: '/escompte-sans-recours-forfaiting',
          suTitle: 'GARANTISSEZ LE RÈGLEMENT SUR VOTRE ACTIVITÉ D’IMPORTATION',
          description:
            'Vous êtes importateur et vous souhaitez optimiser vos transactions commerciales ? Escompte Sans Recours est une solution permettant :',
          items: [
            'Le rachat par votre partenaire BANK OF AFRICA des créances détenues sur votre entreprise par votre client exportateur',
            'De sécuriser le règlement de votre fournisseur marocain ou étranger par la matérialisation des créances sous forme de traites avalisées ou de lettres de crédit',
            'D’écarter les risques de non paiement, de retard de paiement et d’optimiser votre activité internationale',
            'De maintenir un climat de confiance avec votre fournisseur en assurant le respect des délais de paiement',
          ],
          synthesis:
            'Avec l’Escompte Sans Recours (Forfaiting), assurez le règlement de vos transactions à l’import.',
          action: true,
        },
        {
          text: 'Crédit Acheteur',
          to: '/credit-acheteur',
          suTitle:
            'GARANTISSEZ LE FINANCEMENT DE VOS TRANSACTIONS D’IMPORT EXPORT',
          description:
            'Que vous soyez importateur ou exportateur, la technique de financement du commerce international Crédit Acheteur de BANK OF AFRICA vous procure une solution simple et rapide qui vous garantit :',
          items: [
            'Un financement partiel à moyen ou long terme de vos importations/exportations reposant sur la garantie de votre partenaire BANK OF AFRICA dans le cadre d’une collaboration sécurisée avec nos homologues à l’étranger',
            'Le respecter des délais de règlement à l’import-export ; ainsi vous évitez de subir ou de faire subir un retard de paiement et par conséquent vos relations commerciales sont améliorées',
            'La réalisation de paiement au comptant au fur et à mesure des négociations relatives à votre contrat commercial',
            'D’un prix avantageux compte tenu du taux différentiel entre le Dirham et la devise correspondante',
          ],
          synthesis:
            'Avec le Crédit Acheteur de BANK OF AFRICA, sécurisez le financement de vos transactions d’import-export.',
        },
        {
          text: 'Crédit Fournisseur',
          to: '/credit-fournisseur',
          suTitle: 'BÉNÉFICIEZ D’UN DÉLAI DE PAIEMENT SUR VOS IMPORTATIONS',
          description:
            'Vous êtes importateur de biens et services et vous souhaitez différer vos règlements tout en sécurisant vos transactions internationales ?<br>La solution de financement Crédit Fournisseur vous permet :',
          items: [
            'De régler votre fournisseur au fur et à mesure des livraisons par une solution d’escompte commercial sur les effets avalisés par votre partenaire BANK OF AFRICA',
            'De garantir le paiement de vos fournisseurs en contrepartie d’un acheminement sécurisé de vos biens ou services importés',
            'De soulager votre trésorerie en différant le règlement de vos importations tout en optimisant le climat de confiance dans vos relations commerciales',
          ],
          synthesis:
            'Avec le Crédit Fournisseur de BANK OF AFRICA, mettez une bouffée d’oxygène dans votre trésorerie.',
        },
      ],
    },
    {
      title: 'Cautions et garanties à l’internationnal',
      links: [
        {
          text: 'Lettre de Garantie',
          to: '/lettre-garntie',
          suTitle: 'FACILITEZ LE RETRAIT DE VOTRE MARCHANDISE À L’IMPORTATION',
          description:
            'Votre marchandise est arrivée à bon port et vous craignez un retard de livraison dû au délai d’obtention de votre titre de transport ? La Lettre de Garantie de BANK OF AFRICA accélère la démarche :',
          items: [
            'Vous prenez possession de votre marchandise importée dès réception de l’avis d’arrivée et présentation au transporteur de la Lettre de Garantie',
            'La Lettre de Garantie présente l’avantage de se substituer aux documents d’expédition (connaissement maritime) jusqu’à leur obtention définitive',
            'Vous réalisez des économies en matière de frais d’emmagasinage et de stockage',
            'Vous évitez les risques de détérioration ou de péremption de la marchandise en cas de blocage prolongé ',
          ],
          synthesis:
            'Disposez de votre marchandise sans encombre avec la Lettre de Garantie de BANK OF AFRICA.',
        },
        {
          text: 'Aval',
          to: '/bmce-aval',
          suTitle:
            'GARANTISSEZ LA FLUIDITÉ DES EXPORTATIONS DE VOTRE ENTREPRISE',
          description:
            'Les experts en tarif douanier de votre partenaire BANK OF AFRICA ont conçu un système avantageux dans le cadre de vos transactions internationales :',
          items: [
            'L’aval est une caution d’admission temporaire qui vous donne la possibilité de suspendre le règlement du droit de douane',
            'L’aval de BANK OF AFRICA occasionne un soulagement de votre trésorerie vous permettant d’assurer à votre fournisseur le règlement de sa créance (lettre de change, effets de commerce, billet à ordre).',
          ],
          synthesis:
            'Menez vos transactions en douane l’esprit serein avec Aval.',
          action: true,
        },
        {
          text: 'Cautions Diverses à l’International',
          to: '/cautions-diverses-international',
          suTitle:
            'SÉCURISEZ VOS TRANSACTIONS INTERNATIONALES GRÂCES AUX CAUTIONS DE BANK OF AFRICA',
          description:
            'Nos cautions vous sont proposées pour permettre de conclure le contrat vous liant à votre fournisseur. Vous pouvez demander à votre partenaire étranger de souscrire en votre faveur par le biais de sa banque aux diverses cautions répondant aux besoins spécifiques de votre entreprise :',
          items: [
            "La caution provisoire, dite aussi de soumission, garantit votre indemnisation en cas de désistement de votre fournisseur après l'adjudication",
            'La caution définitive vous garantit la bonne exécution des termes du contrat qui vous lie à votre fournisseur',
            "La caution de restitution d'acompte vous garantit la récupération de vos acomptes déjà versés en cas de rupture du contrat d'engagement par votre fournisseur",
            'La caution de retenue de garantie vous assure le bon fonctionnement du matériel acquis pendant la période de garantie',
          ],
          synthesis:
            'Développez votre business à l’international en toute aisance et choisissez la caution BANK OF AFRICA la plus adaptée.',
          action: true,
        },
        {
          text: 'Damane Export',
          to: '/bmce-damane-export',
          suTitle: 'DONNEZ DE L’ENVERGURE À VOS EXPORTATIONS ',
          description: (
            <>
              BANK OF AFRICA encourage l’activité d’exportation de votre TPE ou
              PME avec Damane Export, une formule qui accompagne vos besoins de
              trésorerie à l’export.
              <br />
              Damane Export vous procure de nombreux avantages :
            </>
          ),
          items: [
            'Garantie octroyée par la CCG, Damane Export peut être adossé à votre crédit d’exploitation',
            'Votre entreprise développe son commerce extérieur dans les conditions optimales de sécurité et de rapidité',
            'Vous réduisez les risques liés aux impayés sur votre marchandise exportée',
          ],
          synthesis:
            'Exportez en toute tranquillité avec la garantie Damane Export.',
        },
      ],
    },
  ],
  PME: [
    {
      title: 'Solution express PME',
      links: [],
    },
    {
      title: 'Solution PME relancia',
      links: [],
    },
    {
      title: 'Solution PME croissance verte',
      links: [],
    },
    {
      title: 'Solution PME croissance',
      links: [
        {
          text: 'CRÉDIT CROISSANCE PME CT',
          to: '/credit-croissance-pme-ct',
          suTitle:
            'Prévenez tout décalage de trésorerie avec la solution de facilité de caisse de BANK OF AFRICA',
          description:
            'Adaptée au niveau d’activité de votre entreprise, la Facilité de Caisse est très simple d’utilisation et répond à votre besoin ponctuel de financement par une avance de trésorerie :',
          items: [
            'Vous êtes assuré de gagner en souplesse dans votre gestion de trésorerie et de renflouer votre budget entreprise dans la limite du plafond autorisé',
            'Une fois le montant de la ligne de Facilité Caisse accordé et les garanties constituées, vous êtes libre de l’utiliser ou non, au fur et à mesure de l’évolution de votre activité',
            'Les intérêts sont calculés uniquement en fonction du nombre de jours pendant lesquels le crédit de trésorerie a été effectivement utilisé, et sur le montant dont vous avez réellement disposé',
            'Il vous est possible de renouveler notre solution de Facilité de Caisse autant de fois que vous le souhaitez',
          ],
        },
        {
          text: 'Crédit Croissance PME MLT',
          to: '/credit-croissance-pme-mlt',
          suTitle:
            'Prévenez tout décalage de trésorerie avec la solution de facilité de caisse de BANK OF AFRICA',
          description:
            'Adaptée au niveau d’activité de votre entreprise, la Facilité de Caisse est très simple d’utilisation et répond à votre besoin ponctuel de financement par une avance de trésorerie :',
          items: [
            'Vous êtes assuré de gagner en souplesse dans votre gestion de trésorerie et de renflouer votre budget entreprise dans la limite du plafond autorisé',
            'Une fois le montant de la ligne de Facilité Caisse accordé et les garanties constituées, vous êtes libre de l’utiliser ou non, au fur et à mesure de l’évolution de votre activité',
            'Les intérêts sont calculés uniquement en fonction du nombre de jours pendant lesquels le crédit de trésorerie a été effectivement utilisé, et sur le montant dont vous avez réellement disposé',
            'Il vous est possible de renouveler notre solution de Facilité de Caisse autant de fois que vous le souhaitez',
          ],
        },
      ],
    },
    {
      title: 'Solution PME Croissance export-Industrie',
      links: [],
    },
  ],
};
