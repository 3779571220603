import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LightDivider } from 'features/loanRequest/components/Dividers';
import { makeStyles } from '@material-ui/styles';
import { authWording } from 'common/locale';
import { generalConditionSelector } from 'features/app/selector.app';
import { useSelector } from 'react-redux';
import { authAT } from '../../actions.auth';
import { useDispatch } from 'react-redux';
import { ReactComponent as ScrollDown } from 'assets/svg/modal-scroll-down.svg';
import { GeneralConditionConst } from 'common/constants/generalCondition';

const useStyles = makeStyles(({ color }) => ({
  devider: {
    height: 6,
    backgroundColor: color.shallowGray,
  },
  deviderFull: ({ percentage }) => ({
    height: 6,
    backgroundColor: color.primary,
    width: `${percentage}%`,
  }),
  inlineDevider: {
    flexGrow: 2,
  },
  modal: {
    paddingRight: '0px!important',
    '& .title3': {
      marginBottom: '10px!important',
    },
    '& .modal-content': {
      width: '100%',
      height: 'calc(100vh - 100px)',
      overflow: 'hidden',
    },
    '& .modal-title': {
      margin: 'auto',
    },
    '& $scrollDown': {
      marginLeft: '0px',
    },
    '@media (max-width: 992px)': {
      '& .modal-header': {
        paddingTop: '20px!important',
        paddingLeft: '25px!important',
        paddingRight: '25px!important',
      },
      '& .modal-content': {
        height: 'calc(100vh - 100px)',
      },

      '& .modal-body': {
        padding: '10px 30px',
        '& .pl-6': {
          paddingLeft: '15px!important',
        },
        '& .pr-6': {
          paddingRight: '15px!important',
        },
      },
      '& .modal-dialog': {
        padding: '40px 15px',
      },
      '& $disabledButton': {
        margin: 'auto',
      },
    },
  },
  body: {
    display: 'flex',
    maxHeight: 500,
    overflow: 'auto',
    paddingLeft: 64,
    paddingRight: 165,
    width: '85%',
    height: '100%',
    overflowY: 'scroll',
    boxSizing: 'content-box',
    scrollBehavior: 'smooth',
    '@media (max-height: 740px)': {
      // maxHeight: '100%',
    },
    '@media (max-height: 992px)': {
      paddingRight: '22px!important',
      overflowX: 'hidden',
      maxHeight: '100%',
    },
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
      marginRight: '10px',
      marginLeft: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: `${color.personaliseGray}`,
      marginRight: '10px',
      marginLeft: '10px',
    },
  },
  scrollDownContainer: {
    position: 'relative',
  },
  scrollDown: () => ({
    position: 'absolute',
    cursor: 'pointer',
    marginLeft: 20,
    bottom: `-${
      document.getElementById('modalContent')
        ? document.getElementById('modalContent').scrollTop
        : 0
    }px`,
  }),
  disabledButton: {
    '&:disabled': {
      opacity: '.5',
      cursor: 'not-allowed',
    },
  },
}));

function GeneralCondition() {
  const [show, setShow] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const classes = useStyles({ percentage });
  const dispatch = useDispatch();

  const acceptGeneralCondition = () => {
    dispatch({ type: authAT.ACCEPT_GENERAL_CONDITION });
  };
  const generalCondition = useSelector(generalConditionSelector);

  const handleScroll = () => {
    const { scrollHeight, scrollTop, offsetHeight } = document.getElementById(
      'modalContent',
    );
    setPercentage(() => (100 * (offsetHeight + scrollTop)) / scrollHeight);
  };

  useEffect(() => {
    const showModal = async () => {
      await setShow(generalCondition);
      if (generalCondition) {
        const {
          scrollHeight,
          scrollTop,
          offsetHeight,
        } = document.getElementById('modalContent');
        setPercentage(() => (100 * (offsetHeight + scrollTop)) / scrollHeight);
      }
    };
    showModal();
  }, [generalCondition]);

  const handleScrollDown = () => {
    document.getElementById('modalContent').scrollTop =
      document.getElementById('modalContent').scrollHeight -
      document.getElementById('modalContent').offsetHeight;
  };

  return (
    <>
      <Modal show={show} className={`${classes.modal}`} size='lg'>
        <Modal.Header className={'pl-6 pr-6 pt-6 text-center'}>
          <Modal.Title className={'loanStepTitle'}>
            {authWording.generalCondition.generalConditionTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          id={'modalContent'}
          className={classes.body}
          onScroll={handleScroll}
        >
          <div>
            {GeneralConditionConst.map(element => (
              <React.Fragment key={element.id}>
                <LightDivider light={true} title={element.title} />
                <div dangerouslySetInnerHTML={{ __html: element.content }} />
              </React.Fragment>
            ))}
          </div>
          <div className={classes.scrollDownContainer}>
            <div className={classes.scrollDown} onClick={handleScrollDown}>
              <ScrollDown />
            </div>
          </div>
        </Modal.Body>
        <div className={` ${classes.devider} ${classes.inlineDevider}`}>
          <div className={` ${classes.deviderFull} ${classes.inlineDevider}`} />
        </div>
        <Modal.Footer className={` ustify-content-end`}>
          <Button
            onClick={acceptGeneralCondition}
            disabled={percentage < 99.8}
            className={`pl-6 pr-6 rounded-pill primaryButton ${classes.disabledButton}`}
          >
            {authWording.VALIDATE}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GeneralCondition;
