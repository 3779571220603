import React from 'react';
import Helmet from 'react-helmet';
import Thumbnail from 'assets/images/thumbnail.png';
import logo from 'assets/images/main-logo.png';
import {
  url,
  defaultTitle,
  defaultDescription,
  social,
  socialLinks,
  address,
  contact,
  legalName,
  founders,
  foundingDate,
  type,
  facebookAppId,
  defaultKeywords,
} from './config';

const Seo = ({ title, description, keywords, cover, location = '' }) => {
  const structuredDataOrganization = `{
		"@context": "http://schema.org",
		"@type": "${type}",
		"legalName": "${legalName}",
		"url": "${url}",
		"logo": "${url}/${logo}",
		"foundingDate": "${foundingDate}",
		"founders": [{
			"@type": "Person",
			"name": "${founders}"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"telephone": "${contact.phone}",
			"contactType": "customer service"
		}],
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "${address.city}",
			"addressRegion": "${address.region}",
			"addressCountry": "${address.country}",
			"postalCode": "${address.zipCode}"
		},
		"sameAs": [
			"${socialLinks.twitter}",
			"${socialLinks.youtube}",
			"${socialLinks.linkedin}",
		]
  	}`;
  return (
    <Helmet>
      {/* The description that appears under the title of your website appears on search engines results */}
      <meta name='description' content={description || defaultDescription} />
      <meta name='keywords' content={keywords || defaultKeywords} />
      <link href={logo} rel='icon' />
      {/* The thumbnail of website */}
      <meta
        name='image'
        content={cover ? `${url}${cover}` : `${url}${Thumbnail}`}
      />

      {/* Opengraph meta tags for Facebook & LinkedIn */}
      <meta property='og:url' content={`${url}${location}/?ref=${url}`} />
      <meta property='og:type' content={type} />
      <meta
        property='og:title'
        content={`${founders} | ${title || defaultTitle}`}
      />
      <meta
        property='og:description'
        content={description || defaultDescription}
      />
      <meta
        property='og:image'
        content={cover ? `${url}${cover}` : `${url}${Thumbnail}`}
      />

      {/* You can get this id when you create an app id on Facebook of your Facebook page */}
      <meta property={`fb:${facebookAppId}`} content={social.facebook} />

      {/* These tags work for Twitter & Slack, notice I've included more custom tags like reading time etc... */}
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:creator' content={socialLinks.twitter} />
      <meta name='twitter:site' content={social.twitter} />
      <meta
        name='twitter:title'
        content={`${founders} | ${title || defaultTitle}`}
      />
      <meta
        name='twitter:description'
        content={description || defaultDescription}
      />
      <meta
        name='twitter:image:src'
        content={cover ? `${url}${cover}` : `${url}${Thumbnail}`}
      />

      {/* Structured data */}
      <script type='application/ld+json'>{structuredDataOrganization}</script>

      <title>{`${title || defaultTitle}`}</title>

      {/* Default language and direction */}
      <html lang='fr' dir='ltr' />
    </Helmet>
  );
};

export default Seo;
