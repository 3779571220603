import { fromJS } from 'immutable';
import {
  setLocalStorage,
  getLocalStorage,
  AUTH_STORAGE_KEY,
  ACCOUNT_TYPE_KEY,
} from '../../common/utils/localStorage.util';
import { authAT, prospectAT } from './actions.auth';

export const AUTH_REDUCER = 'AUTH_REDUCER';

export const initState = fromJS({
  isAuthenticated: !!getLocalStorage(AUTH_STORAGE_KEY),
  [ACCOUNT_TYPE_KEY]: getLocalStorage(ACCOUNT_TYPE_KEY) || '',
  tribunals: [],
  bcs: [],
  ssoAuth: {
    tiersList: [],
  },
  bboAuth: {
    tiersList: [],
  },
  bmceDirectAuth: {},
  currentStep: 0,
  register: {
    error: {
      code: '',
      message: '',
    },
  },
  authErrors: {
    auth: {
      login: null,
      password: null,
    },
  },
  validateAccountError: '',
  changePassword: {
    login: '',
    oldPassword: '',
    error: '',
  },
  prospect: {
    selectedCenter: {},
    showingInfoWindow: false,
    agencyCoordinates: [],
    cities: [],
    step: 0,
    fields: {},
    errors: {},
    legalCategories: [],
  },
});

export function authReducer(state = initState, action) {
  switch (action.type) {
    case authAT.SUBMIT_BMCEDIRECT_AUTH_SUCCESS:
      return state;
    case authAT.SUBMIT_AUTH_ERROR:
      return state.setIn(['authErrors', 'globalError'], fromJS(action.error));
    case authAT.SUBMIT_SPECIFIC_AUTH_ERROR:
      return state.setIn(['authErrors', 'auth'], action.errors);
    case authAT.INCREMENT_SIGNUP_STEP:
      return state.update('currentStep', value => value + 1);
    case authAT.DESCREMENT_SIGNUP_STEP:
      return state.update('currentStep', value => value - 1);
    case authAT.SET_REGISTER_DATA:
      const newRegister = state.get('register').mergeDeep(action.payload);
      return state.set('register', newRegister);
    case authAT.INIT_SIGNUP:
      return state
        .set('currentStep', initState.get('currentStep'))
        .set('register', initState.get('register'));
    case authAT.SET_AUTH_TYPE:
      setLocalStorage(ACCOUNT_TYPE_KEY, action.accountType || '');
      return state.set(ACCOUNT_TYPE_KEY, action.accountType);
    case authAT.SET_AUTH_SUCCESS:
      setLocalStorage(AUTH_STORAGE_KEY, true);
      return state.set('isAuthenticated', true);

    case authAT.LOG_OUT_SUCCESS:
      setLocalStorage(AUTH_STORAGE_KEY, false);
      setLocalStorage(ACCOUNT_TYPE_KEY, '');
      return initState.set('isAuthenticated', false).set(ACCOUNT_TYPE_KEY, '');
    case authAT.SET_TRIBUNALS:
      return state.set('tribunals', action.tribunals);
    case authAT.SET_BUSNESS_CENTERS:
      return state.set('bcs', action.bcs);
    case authAT.VALIDATE_ACCOUNT_ERROR:
      return state.set('validateAccountError', action.error);
    case authAT.SET_CHANGE_PASSWORD:
      return state.setIn(['changePassword', action.keyName], action.value);
    case authAT.INIT_CHANGE_PASSWORD:
      return state.set('changePassword', initState.get('changePassword'));
    case authAT.SUBMIT_BBO_AUTH_SUCCESS:
      return state.setIn(['bboAuth', 'tiersList'], fromJS(action.tiersList));
    case authAT.SUBMIT_SSO_AUTH_SUCCESS:
      return state.setIn(['ssoAuth', 'tiersList'], fromJS(action.tiersList));
    case prospectAT.UPDATE_PROSPECT:
      return state.setIn(['prospect', ...action.names], action.value);
    case authAT.SUBMIT_BBO_AUTH:
      // init tiers list
      return state.setIn(['bboAuth', 'tiersList'], fromJS([]));
    case authAT.SUBMIT_SSO_AUTH:
      // init tiers list
      return state.setIn(['ssoAuth', 'tiersList'], fromJS([]));
    case prospectAT.SUBMIT_PROSPECt_REQUEST_SUCCESS:
      return state.set(
        'prospect',
        fromJS({
          ...state.get('prospect').toJS(),
          cities: [],
          fields: {},
          errors: {},
          legalCategories: [],
        }),
      );
    default:
      return state;
  }
}
