import axios from 'axios';
import { ErrorToast } from '../component/Toast';
import { Errors } from 'common/locale';
import { push } from 'connected-react-router';
import authAT from 'features/auth/actions.auth';
import store from 'store';
import { toastTypes } from 'common/component/constants';
import { HOME_ENDPOINTS } from 'features/home/constants.loansList';
import { APP_ENDPOINTES } from 'features/app/constants.app';
import { showGeneralConditionAction } from 'features/app/actions.app';
import { env } from 'env';
import React from 'react';
import WafMessageErreur from 'common/component/WafMessageErreur';
import getSuportIdFromResponse from 'common/utils/wafMessage.js';
/* #### ignore error message for these APIs #### */
const IGNORED_ENDPOINTS = [
  ...Object.values(HOME_ENDPOINTS),
  ...Object.values(APP_ENDPOINTES),
];

const API = axios.create({
  baseURL: env.apiPath || 'http://localhost:3000',
});

API.interceptors.request.use(
  config => {
    // Do something before request is sent
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);
// Add a response interceptor
API.interceptors.response.use(
  response => {
    // do something with the response data
    const contentType =
      response && response.headers ? response.headers['content-type'] : '';
    if (
      typeof contentType === 'string' &&
      contentType.startsWith('text/html')
    ) {
      const supportID = getSuportIdFromResponse(response.data);
      if (supportID) {
        ErrorToast(<WafMessageErreur supportID={supportID} />);
      }
    }

    return response;
  },
  error => {
    const status = error.response && error.response.status;
    if (status === 401) {
      ErrorToast(Errors.unauthorized, 5000, toastTypes.UNAUTHORIZED);
      store.dispatch({
        type: authAT.LOG_OUT_SUCCESS,
      });
      store.dispatch(push('/auth'));
    } else if (status === 403) {
      store.dispatch(showGeneralConditionAction(true));
    }

    let {
      config: { url, baseURL },
    } = error;

    /*### REQUEST URL ###*/
    const REQUEST_URL = url.slice(baseURL.length, url.length);

    if (status >= 500 && !IGNORED_ENDPOINTS.includes(REQUEST_URL)) {
      ErrorToast(Errors.serverNotAvailable);
    } else if (error.message === 'Network Error') {
      ErrorToast(Errors.networkError);
    }

    return Promise.reject(error);
  },
);

export const apiPost = (url, payload, options = {}) =>
  API.post(url, payload, options);
export const apiGet = (url, params = null) => API.get(url, params);

export default API;
