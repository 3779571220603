import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ color }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  currency: ({ negative, theme }) => ({
    color: negative
      ? color.error
      : theme === 'dark'
      ? color.darkBlue
      : color.secondary,
    fontSize: 18,
    lineHeight: '22px',
    fontFamily: 'tex_gyre_herosbold',
    '@media (max-width: 756px)': {
      fontSize: 14,
    },
  }),
  currencySelected: ({ negative, theme }) => ({
    color: negative
      ? color.error
      : theme === 'white'
      ? 'white'
      : color.secondary,
    fontSize: 18,
    lineHeight: '22px',
    fontFamily: 'tex_gyre_herosbold',
    '@media (max-width: 756px)': {
      fontSize: 14,
    },
  }),
  suffix: {
    color: color.inputColor,
    fontSize: 12,
    marginLeft: 7,
    fontFamily: 'tex_gyre_herosbold',
  },
  suffixSelected: {
    color: 'white',
    fontSize: 12,
    marginLeft: 7,
    fontFamily: 'tex_gyre_herosbold',
  },
}));

const CurrencyFormatter = ({ currency, theme }) => {
  const classes = useStyles({ theme, negative: currency < 0 });
  return (
    <span className={classes.container}>
      <CurrencyFormat
        value={currency || 0}
        displayType='text'
        thousandSeparator='&#160;'
        decimalSeparator=',&#160;'
        fixedDecimalScale={true}
        decimalScale={2}
        renderText={value => (
          <span
            className={
              theme === 'white' ? classes.currencySelected : classes.currency
            }
          >
            {value}
          </span>
        )}
      />

      <span
        className={theme === 'white' ? classes.suffixSelected : classes.suffix}
      >
        MAD
      </span>
    </span>
  );
};

export default CurrencyFormatter;
