import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/styles';
import EnvelopeIcon from 'assets/svg/icone-mail.svg';
import { loanRequestWording } from 'common/locale';
import { motion } from 'framer-motion';
import { childVariants, variants } from 'features/auth/constants.auth';
import { Button } from 'react-bootstrap';
import backImg from 'assets/svg/back.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  desc: {
    margin: '0 auto',
    maxWidth: '70%',
  },
  icon: {
    maxWidth: '150px',
    margin: '0 auto',
    height: 'auto',
    fill: 'red',
  },
  back: {
    '& img': {
      marginLeft: '80px',
      height: '70px',
    },
  },
});

const Validation = () => {
  const classes = useStyles();
  return (
    <div className={` ${classes.container} mt-5`}>
      <Button as={Link} to='/home' variant='link' className={classes.back}>
        <img src={backImg} alt='go back' />
      </Button>
      <motion.div initial='hidden' animate='visible' variants={variants}>
        <div className='text-center'>
          <h1 className={`title1 mb-3`}>
            {loanRequestWording.validation.MAIN_TITLE}
          </h1>
          <h3 className={` title2 mb-5`}>
            {loanRequestWording.validation.SUB_TITLE}
          </h3>
        </div>
      </motion.div>
      <motion.div initial='hidden' animate='visible' variants={childVariants}>
        <div className='text-center mb-5'>
          <Row className='justify-content-sm-center'>
            <Col xs={7}>
              <img
                className={`${classes.icon} mb-7 mt-4`}
                src={EnvelopeIcon}
                alt='success'
              />
              <p className={`p1 ${classes.desc}`}>
                {loanRequestWording.validation.MESSAGE1}
              </p>
              <p className={`${classes.desc} p1 mt-4`}>
                {loanRequestWording.validation.MESSAGE2}
              </p>
            </Col>
          </Row>
        </div>
      </motion.div>
    </div>
  );
};

export default Validation;
