import React from 'react';

export const LegalNoticeConst = [
  {
    id: 1,
    title: '1. Editeur du site Internet  :',
    content: (
      <>
        BANK OF AFRICA -S.A. au Capital de 1.998.204.600 Dirhams - 140, avenue
        Hassan II - 20 000 Casablanca - Maroc - BP 13425 CASA Ppal - Télex : 21
        931 - 24 004 <br /> +212 (0) 522 20 04 20 / +212 (0) 522 20 03 25 -
        Registre de Commerce : CASA 27129 - N° Identification Fiscale : 01085112
        - Patente : 35502790 - CCP RABAT 1030 - CNSS 10 2808 5 -{' '}
        <span className='general-condition-url'>
          www.creditbusinessonline.ma
        </span>
      </>
    ),
  },
  {
    id: 2,
    title: '2. Informations sur le site Internet :',
    content: (
      <>
        Les informations externes fournies sur le site proviennent de sources
        dignes de foi mais ne sauraient entraîner la responsabilité de BANK OF
        AFRICA.
      </>
    ),
  },
  {
    id: 3,
    title: '3. Aspects techniques :',
    content: (
      <>
        Le visiteur est informé que le site Internet est accessible 24 H/24 H et
        7 jours/7 jours à l'exception des cas de force majeure, difficultés
        informatiques, difficultés liés à la structure du réseau de
        télécommunication ou difficultés techniques. Pour des raisons de
        maintenance BANK OF AFRICA pourra interrompre temporairement le site
        Internet.
        <br /> BANK OF AFRICA ne saurait être tenue responsable de
        l’impossibilité d’accéder au présent site. Par ailleurs BANK OF AFRICA
        se réserve le droit de refuser l’accès à tout internaute de manière
        unilatérale et sans aucun préavis ni notification préalable en cas de
        violation par ce dernier de l’une des règles de navigation.
        <br /> L’accès, le cas échéant, aux zones protégées par mot de passe
        et/ou sécurisées du présent site et leur utilisation sont limités aux
        utilisateurs autorisés. Les personnes non autorisées tentant d’y accéder
        peuvent faire l’objet de poursuites.
      </>
    ),
  },
  {
    id: 4,
    title: '4. Protection des données à caractère personnel :',
    content: (
      <>
        <p>
          BANK OF AFRICA s’engage à protéger la vie privée de ses utilisateurs
          dans le respect des réglementations en vigueur et en particulier de la
          loi n° 09-08 relative à la protection des personnes physiques à
          l'égard du traitement des données à caractère personnel.
        </p>
        Les utilisateurs sont susceptibles de communiquer à BANK OF AFRICA des
        données à caractère personnel les concernant en renseignant les
        formulaires ou questionnaires sur le présent site Ces données seront
        utilisées pour les finalités suivantes : traitement de votre demande
        d'informations, de souscription à un produit ou service, d'établissement
        de statistiques, à des fins de prospection commerciale, de gestion de la
        relation bancaire et financière, de gestion des opérations relatives à
        la banque à distance et aux services en ligne, de gestion des
        réclamations et de l'exécution des dispositions légales, réglementaires
        et administratives en vigueur. A défaut de remplir les champs
        obligatoires, BANK OF AFRICA ne pourra satisfaire à votre demande.
        <br /> Le Groupe BANK OF AFRICA est seul destinataire de ces données.
        Aucun transfert n’est réalisé hors du territoire Marocain. Toutefois,
        BANK OF AFRICA se réserve le droit de transmettre vos données
        personnelles afin de satisfaire à ses obligations légales, et notamment
        si elle en était contrainte par réquisition judiciaire.
        <br />{' '}
        <b>
          {' '}
          <u>Modalités d'exercice de vos droits </u>{' '}
        </b>
        <br />
        En application des articles 23 et suivants de la loi N° 09-08 promulguée
        par le Dahir N° 1-09-15 du 22 Safar 1430, BANK OF AFRICA met en œuvre
        toutes les mesures techniques et organisationnelles nécessaires pour
        garantir la sécurité et la confidentialité des données à caractère
        personnel collectées et traitées, et notamment empêcher qu'elles soient
        déformées, endommagées ou communiquées à des tiers non autorisés, en
        assurant un niveau de sécurité adapté aux risques liés au traitement et
        à la nature des données à protéger. Vous bénéficiez des droits d’accès,
        de rectification et d’opposition, sauf dans les cas où le traitement
        répond à une obligation légale, au traitement de vos données à caractère
        personnel. Vous pouvez exercer ces droits par courrier avec accusé de
        réception à l’adresse suivante : BANK OF AFRICA, Compliance, 140 Avenue
        Hassan II Casablanca.
        <br />{' '}
        <b>
          {' '}
          <u>
            Utilisation de vos données à des fins de prospection Conformément
          </u>{' '}
        </b>
        <br />
        à la réglementation applicable, toute prospection par automate d'appel,
        courrier électronique, SMS, MMS ou fax, ainsi que toute cession ou
        location des données à des fins de prospection utilisant ces moyens, ne
        seront réalisées qu'avec votre consentement préalable à recevoir des
        prospections via ce canal ou/et à la cession de ses données. <br /> Si
        vous acceptez de recevoir de tels messages de prospection en cochant les
        cases d'acceptation prévues à cet effet sur le présent site, il vous
        sera possible à tout moment de revenir sur ce consentement, et notamment
        chacun des courriers électroniques de prospection vous offrira la
        possibilité de vous opposer pour le futur à l'envoi de nouveaux
        courriers électroniques de prospection par BANK OF AFRICA. A tout moment
        et sans frais, vous pouvez vous opposer à toute prospection par d'autres
        moyens en écrivant aux contacts mentionnés au paragraphe « Modalités
        d'exercice de vos droits ».
        <br />{' '}
        <b>
          {' '}
          <u> Liens hypertextes :</u>{' '}
        </b>
        <br />
        Le présent site comporte notamment des informations mises à disposition
        par des sociétés externes ou des liens hypertextes vers d’autres sites
        qui n’ont pas été développés par BANK OF AFRICA. L’existence, sur le
        présent site, d’un lien hypertexte conduisant à un autre site ne
        constitue pas une validation de cet autre site ou de son contenu. BANK
        OF AFRICA n’assume par conséquent aucune responsabilité de ces sites,
        leur contenu, publicités, produits ou services disponibles sur ou à
        partir de ces sites. BANK OF AFRICA ne peut en aucun cas être tenue
        responsable d'éventuels dommages occasionnés par l'utilisation de ces
        sites. Toutes plaintes concernant les sites de tierces parties doivent
        être dirigées aux administrateurs desdits sites.
      </>
    ),
  },
  {
    id: 5,
    title: '5. Droit de propriété intellectuelle :',
    content: (
      <>
        La structure générale, les textes, images animées ou non et tous les
        éléments composant ce site Internet, sont la propriété de Bank Of
        Africa, ou ont été acquis auprès de tiers pour utilisation sur Internet
        ou autres supports et ne peuvent être utilisés sans autorisation de BANK
        OF AFRICA. Toute reproduction totale ou partielle de ce site ou d'un de
        ces éléments sans autorisation expresse et préalable de BANK OF AFRICA
        est interdite. Toute représentation ou reproduction de ce site, par
        quelque procédé que ce soit, constituerait une contrefaçon susceptible
        d’engager la responsabilité civile et pénale du contrefacteur. Les logos
        et marques de BANK OF AFRICA figurant sur ce site sont des marques
        déposées. Toute reproduction totale ou partielle de ces marques et logos
        à quelque titre que ce soit, et notamment à des fins publicitaires,
        effectuée à partir des éléments de ce site sans l’accord et écrit du
        titulaire de la marque est interdite conformément aux dispositions de la
        loi 17-97 relative à la Protection de la Propriété Industrielle telle
        que modifiée et complétée par la loi 31-05.
      </>
    ),
  },
  {
    id: 6,
    title: '7. Limitation de responsabilité :',
    content: (
      <>
        <p>
          BANK OF AFRICA ne peut être tenue pour responsable de tout dommage
          provenant du non-respect par l’utilisateur des règles d’utilisation du
          site Internet. De même, BANK OF AFRICA ne peut être tenue responsable
          des conséquences de l’utilisation du site par tout tiers autorisé ou
          non.
        </p>
        De même, l’Utilisateur reconnaît par les présentes que la responsabilité
        de BANK OF AFRICA ne pourrait être recherchée à quelque titre que ce
        soit, en cas d’utilisation frauduleuse (piratage, contrefaçon
        informatique, Hacking, etc.) et pour tout dommage qui serait
        éventuellement due à une défaillance du système de sécurité des
        fournisseurs d’accès Internet.
        <br /> L’utilisateur reconnaît désengager BANK OF AFRICA de toute
        responsabilité en cas où ce dernier aurait subi un dommage ou pertes en
        relation avec l’utilisation des services disponibles sur ces sites ou
        sources externes.
      </>
    ),
  },
  {
    id: 8,
    title: '8. Modification du site ',
    content: (
      <>
        BANK OF AFRICA se réserve le droit de modifier et/ou de corriger le
        contenu de ce site et/ou de ses mentions légales à tout moment et ceci
        sans préavis.
      </>
    ),
  },
  {
    id: 9,
    title: '9. Services fournis ',
    content: (
      <>
        L’ensemble des activités de la société ainsi que ses informations sont
        présentés sur notre site{' '}
        <span className='general-condition-url'>
          www.creditbusinessonline.ma
        </span>{' '}
        BANK OF AFRICA s’efforce de fournir sur le site{' '}
        <span className='general-condition-url'>
          www.creditbusinessonline.ma
        </span>{' '}
        des informations aussi précises que possible. les renseignements
        figurant sur le site{' '}
        <span className='general-condition-url'>
          www.creditbusinessonline.ma
        </span>{' '}
        ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés
        sous réserve de modifications ayant été apportées depuis leur mise en
        ligne. Par ailleurs, tous les informations indiquées sur le site{' '}
        <span className='general-condition-url'>
          www.creditbusinessonline.ma
        </span>{' '}
        sont données à titre indicatif, et sont susceptibles de changer ou
        d’évoluer sans préavis.
      </>
    ),
  },
  {
    id: 10,
    title: '10. Cookies ',
    content: (
      <>
        Le site{' '}
        <span className='general-condition-url'>
          www.creditbusinessonline.ma
        </span>{' '}
        peut être amené à vous demander l’acceptation des cookies pour des
        besoins de statistiques et d’affichage. Un cookie est une information
        déposée sur votre disque dur par le serveur du site que vous visitez. Il
        contient plusieurs données qui sont stockées sur votre ordinateur dans
        un simple fichier texte auquel un serveur accède pour lire et
        enregistrer des informations. Certaines parties de ce site ne peuvent
        être fonctionnelles sans l’acceptation de cookies.{' '}
      </>
    ),
  },
];
