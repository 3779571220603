import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Switch, Input } from 'common/component/Fields';
import { makeStyles } from '@material-ui/styles';
import { loanRequestWording } from 'common/locale';

const useStyles = makeStyles(({ color }) => ({
  questionContainer: {
    minHeight: 48,
  },
  devider: {
    height: 1,
    backgroundColor: color.shallowGray,
  },
  label: {
    fontSize: 12,
    '@media (max-width: 1000px)': {
      marginBottom: '10px',
    },
  },
  textArea: {
    height: '100px  !important',
  },
  switchContainer: {
    paddingLeft: '34px',
  },
}));

function Question({ question, updateInput, index, textArea }) {
  const classes = useStyles();
  const updateValue = event => {
    updateInput(['questions', index, event.target.name], event.target.value);
  };
  const handleSwitchChange = value => {
    updateInput(['questions', index, 'value'], value);
  };

  return (
    <>
      <div className={`mb-3 mt-3 ${classes.devider}`} />
      <Row className={`${classes.questionContainer} align-items-center`}>
        <Col
          lg={textArea ? 12 : 5}
          md={12}
          className={`d-flex align-items-center label ${classes.label}`}
        >
          {question.question}
        </Col>
        <Col lg={textArea ? 12 : 6} md={textArea ? 12 : 10}>
          {question.hasResponse && !textArea ? (
            question.disableResponse ? (
              question.value ? (
                <Input
                  data-testid='response-input'
                  onChange={updateValue}
                  name={'response'}
                  value={question.response ? question.response : ''}
                  placeholder={
                    question.placeholder ||
                    loanRequestWording.additionalInformation
                      .RESPONSE_PLACEHOLDER
                  }
                />
              ) : null
            ) : (
              <Input
                data-testid='response-input'
                onChange={updateValue}
                name={'response'}
                value={question.response ? question.response : ''}
                placeholder={
                  question.placeholder ||
                  loanRequestWording.additionalInformation.RESPONSE_PLACEHOLDER
                }
              />
            )
          ) : null}
          {question.hasResponse && textArea && (
            <Input
              className={`mt-4 ${classes.textArea}`}
              as={'textarea'}
              onChange={updateValue}
              name={'response'}
              value={question.response ? question.response : ''}
              placeholder={
                question.placeholder ||
                loanRequestWording.additionalInformation.RESPONSE_PLACEHOLDER
              }
            />
          )}
        </Col>
        {question.hasValue && (
          <Col md={1} className={`${classes.switchContainer} float-right`}>
            <Switch
              id={question.id}
              onChange={handleSwitchChange}
              checked={question.value}
            />
          </Col>
        )}
      </Row>
    </>
  );
}

export default Question;
