import { loanRequestWording } from 'common/locale';
import React from 'react';
import { steps } from 'features/loanRequest/LoanRequest';
import CurrencyFormatter from 'common/component/CurrencyFormatter';

export const formatLoanTypesList = loanTypesList => {
  return loanTypesList.map(element => {
    const header = { label: element.nature.label };
    const items = [];

    items.push({
      label: loanRequestWording.AMOUNT,
      value: <CurrencyFormatter currency={element.amount} />,
      key: element.id,
    });

    items.push({
      label: loanRequestWording.loanInformationStep.LOAN_TYPE,
      value: element.type.label,
      key: element.type.value,
    });
    items.push({
      label: loanRequestWording.loanInformationStep.LOAN_SUB_TYPE,
      value: element.subType.label,
      key: element.subType.value,
    });
    items.push({
      label: loanRequestWording.loanInformationStep.PURPOSE_OF_THE_LOAN,
      value: element.loanPurpose === 'Other' ? 'Autre' : element.loanPurpose,
      key: 'loanPurpose',
    });
    return { header, items, id: element.id };
  });
};

export const getStepUrlByStepId = (stepId, idLoan) => {
  const step = steps(idLoan).find(element => {
    return element.id === stepId;
  });

  return step ? step.url : '';
};

export const getStepIdByStepUrl = (stepUrl, idLoan) => {
  const step = steps(idLoan).find(element => {
    return element.url === stepUrl;
  });
  return step ? step.id : '';
};
