import validate from 'validate.js';
import { FIELDS_REQUIRED } from './errors';
import { NEW_LAON } from 'features/loanRequest/constants.loanRequest';

validate.validators.regex = function(value, options) {
  let regExp = new RegExp(options.pattern);

  if (!regExp.test(value)) {
    return options.message;
  }
};

validate.validators.renewalPresence = function(
  value,
  options,
  key,
  attributes,
) {
  if (attributes.loanType === NEW_LAON) {
    return null;
  } else if (!value) {
    return [FIELDS_REQUIRED];
  } else {
    return null;
  }
};

validate.validators.arrayOfElements = function(value, options) {
  const result = {};
  const keys = options.keys;
  if (value) {
    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < keys.length; j++) {
        if (validate.isEmpty(value[i][keys[j]])) {
          if (!result[value[i]['order']]) result[value[i]['order']] = {};
          Object.assign(result[value[i]['order']], {
            [keys[j]]: [FIELDS_REQUIRED],
          });
        }
      }
    }
  }
  return result;
};

validate.validators.guarnteesDependencies = function(value, options) {
  let error = false;
  value.forEach(g => {
    if (g.guarantee.garantiesDependencies) {
      g.guarantee.garantiesDependencies.forEach(depG => {
        // if dependencie guarantee was not found in the submitted list
        if (!value.some(el => Number(el.idGarantie) === Number(depG))) {
          error = true;
        }
      });
    }
  });

  if (error) return options.message;
};

validate.validators.questions = function(value, options) {
  const errors = value.reduce((acc, question) => {
    if (!question.response) {
      acc[question.id] = [options.message];
    }
    return acc;
  }, {});

  if (Object.entries(errors).length) return errors;
};

export const costumValidate = (data, constraint, options = null) => {
  const customOptions = {
    fullMessages: false,
    ...options,
  };

  return validate(data, constraint, customOptions);
};
