import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import loadComponent, { ErrorBoundary } from './common/component/loadComponent';
import useIsAuthenticated from 'common/hooks/useIsAuthenticated';

const LoanDetails = loadComponent({
  loader: () =>
    import(
      './features/home/RequestTracking' /* webpackChunkName: "RequestTracking" */
    ),
});

const ReleaseDetails = loadComponent({
  loader: () =>
    import(
      './features/home/ReleaseTracking' /* webpackChunkName: "RequestTracking" */
    ),
});

const ReleaseList = loadComponent({
  loader: () =>
    import(
      './features/releaseRequest/components/listReleaseRequest/ListReleaseRequest' /* webpackChunkName: "RequestTracking" */
    ),
});

const ReleaseValidation = loadComponent({
  loader: () =>
    import(
      './features/loanRequest/components/ValidationRelease' /* webpackChunkName: "RequestTracking" */
    ),
});

const GeneralCondition = loadComponent({
  loader: () =>
    import(
      './features/generalCondition/GeneralCondition' /* webpackChunkName: "GeneralCondition" */
    ),
});

const Profile = loadComponent({
  loader: () =>
    import('./features/profile/Profile' /* webpackChunkName: "Profile" */),
});

const LoanRequest = loadComponent({
  loader: () =>
    import(
      './features/loanRequest/LoanRequest' /* webpackChunkName: "LoanRequest" */
    ),
});

const MyLoans = loadComponent({
  loader: () =>
    import('./features/home/Home' /* webpackChunkName: "MyLoans" */),
});

const LandingPage = loadComponent({
  loader: () =>
    import('./features/landing/Landing' /* webpackChunkName: "LandingPage" */),
});
const LandingPageTPE = loadComponent({
  loader: () =>
    import(
      './features/landingTPE/LandingTPE' /* webpackChunkName: "LandingTPEPage" */
    ),
});

const RenewalLoans = loadComponent({
  loader: () =>
    import('././features/home/RenewalLoans' /* webpackChunkName: "Faq" */),
});
const ToModifyLoans = loadComponent({
  loader: () =>
    import('././features/home/ToModifyLoans' /* webpackChunkName: "Faq" */),
});

const Faq = loadComponent({
  loader: () => import('./features/faq/Faq' /* webpackChunkName: "Faq" */),
});

const SignUp = loadComponent({
  loader: () =>
    import('./features/auth/SignUp' /* webpackChunkName: "SignUp" */),
});

const Prospect = loadComponent({
  loader: () =>
    import('./features/auth/Prospect' /* webpackChunkName: "prospect" */),
});

const Auth = loadComponent({
  loader: () => import('./features/auth/Auth' /* webpackChunkName: "Auth" */),
});

const AuthSSO = loadComponent({
  loader: () =>
    import('./features/auth/AuthSSO' /* webpackChunkName: "Auth" */),
});

const ValidateAccount = loadComponent({
  loader: () =>
    import(
      './features/auth/ValidateAccount' /* webpackChunkName: "ValidateAccount" */
    ),
});

const ChangePassword = loadComponent({
  loader: () =>
    import(
      './features/auth/ChangePassword' /* webpackChunkName: "ChangePassword" */
    ),
});

const Exploitation = loadComponent({
  loader: () =>
    import(
      './features/landing/Exploitation' /* webpackChunkName: "Exploitation" */
    ),
});

const Investissement = loadComponent({
  loader: () =>
    import(
      './features/landing/Investissement' /* webpackChunkName: "Investissement" */
    ),
});

const International = loadComponent({
  loader: () =>
    import(
      './features/landing/International' /* webpackChunkName: "International" */
    ),
});

const Pme = loadComponent({
  loader: () => import('./features/landing/Pme' /* webpackChunkName: "Pme" */),
});

const Tpe = loadComponent({
  loader: () => import('./features/landing/Tpe' /* webpackChunkName: "Tpe" */),
});

const FinancementCreation = loadComponent({
  loader: () =>
    import(
      './features/landingTPE/FinancementCreation' /* webpackChunkName: "Tpe" */
    ),
});
const FinancementCycleExploitation = loadComponent({
  loader: () =>
    import(
      './features/landingTPE/FinancementCycleExploitation' /* webpackChunkName: "Tpe" */
    ),
});
const FinancementDeveloppment = loadComponent({
  loader: () =>
    import(
      './features/landingTPE/FinancementDeveloppement' /* webpackChunkName: "Tpe" */
    ),
});

const releaseRequest = loadComponent({
  loader: () =>
    import(
      './features/releaseRequest/ReleaseRequest' /* webpackChunkName: "releaseRequest" */
    ),
});

const NoMatch = loadComponent({
  loader: () =>
    import('common/component/NoMatch' /* webpackChunkName: "NoMatch" */),
});

export const PrivateRoute = ({
  component: Component,
  onAuth = false,
  ...rest
}) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === onAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={onAuth === true ? '/auth' : '/home'} />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/offres-tpe' component={LandingPageTPE} />
        <Route exact path='/faq' component={Faq} />
        <Route exact path='/terms' component={GeneralCondition} />
        <PrivateRoute exact path='/auth' component={Auth} />
        <Route exact path='/authSSO/:login/:password' component={AuthSSO} />
        <PrivateRoute
          path='/activate-account/:id'
          component={ValidateAccount}
        />
        <PrivateRoute exact path='/sign-up' component={SignUp} />
        <PrivateRoute exact path='/prospect' component={Prospect} />
        <PrivateRoute
          exact
          path='/change-password'
          component={ChangePassword}
        />
        <PrivateRoute exact path='/profile' onAuth={true} component={Profile} />
        <PrivateRoute path='/loan/:id' onAuth={true} component={LoanRequest} />
        <PrivateRoute
          exact
          path='/RenewalLoans'
          onAuth={true}
          component={RenewalLoans}
        />
        <PrivateRoute
          exact
          path='/ToModifyLoans'
          onAuth={true}
          component={ToModifyLoans}
        />

        <PrivateRoute exact path='/home' onAuth={true} component={MyLoans} />
        <PrivateRoute
          exact
          path='/loan-details/:id'
          onAuth={true}
          component={LoanDetails}
        />
        <PrivateRoute
          exact
          path='/release/validation'
          onAuth={true}
          component={ReleaseValidation}
        />
        <PrivateRoute
          exact
          path='/release/edit'
          onAuth={true}
          component={releaseRequest}
        />
        <PrivateRoute
          exact
          path='/release/:id'
          onAuth={true}
          component={ReleaseDetails}
        />
        <PrivateRoute
          exact
          path='/release'
          onAuth={true}
          component={ReleaseList}
        />

        <Route path='/exploitation' component={Exploitation} />
        <Route path='/investissement' component={Investissement} />
        <Route path='/international' component={International} />
        <Route path='/pme' component={Pme} />
        <Route path='/tpe' component={Tpe} />

        <Route
          path='/offres-tpe/financement-creation'
          component={FinancementCreation}
        />
        <Route
          path='/offres-tpe/finanacement-cycle-exploitation'
          component={FinancementCycleExploitation}
        />
        <Route
          path='/offres-tpe/finanacement-developpement'
          component={FinancementDeveloppment}
        />
        <Route component={NoMatch} />
      </Switch>
    </ErrorBoundary>
  );
};

export default Routes;
