import { makeStyles } from '@material-ui/styles';
import loadComponent from 'common/component/loadComponent';
import { loanRequestWording } from 'common/locale';
import { getStepIdByStepUrl } from 'common/utils/loanRequest.utils';
import { AVAILABLE_STATUS } from 'features/home/constants.loansList';
import LoanSteps from 'features/loanRequest/components/loanSteps';
import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLoanRequestDataAction,
  submitStepAction,
  updateCurrentStep,
} from './actions.loanRequest';
import AdditionalInformation from './components/additionalInformation/AdditionalInformation';
import FormLayout from './components/formLayout';
import Validation from './components/Validation';
import {
  ADDITIONAL_INFORMATION_STEP,
  DOCUMENTS_UPLOAD_STEP,
  ENTREPRISE_IDENTIFICATION_STEP,
  GUARANTEES_STEP,
  LOAN_INFORMATION_STEP,
  menuVariants,
  stepVariants,
  SUMMARY_STEP,
} from './constants.loanRequest';
import { getLoanId } from './selectors.loanRequest';
import { Button } from 'react-bootstrap';
import backImg from 'assets/svg/back-9x18.svg';
import { Link } from 'react-router-dom';

const EntrepriseIdentification = loadComponent({
  loader: () =>
    import(
      './components/entrepriseIdentification/entrepriseIdentification' /* webpackChunkName: "EntrepriseIdentification" */
    ),
});

const LoanInformation = loadComponent({
  loader: () =>
    import(
      './components/loanInformation/loanInformation' /* webpackChunkName: "LoanInformation" */
    ),
});

const DocumentsUpload = loadComponent({
  loader: () =>
    import(
      './components/documentsUpload/DocumentsUpload' /* webpackChunkName: "DocumentsUpload" */
    ),
});

const Guarantee = loadComponent({
  loader: () =>
    import(
      './components/loanGuarantee/Guarantee' /* webpackChunkName: "Guarantee" */
    ),
});

const RecapLoanInformation = loadComponent({
  loader: () => import('./components/summary/Summary'),
});

export const useStyles = makeStyles(({ color }) => ({
  container: {
    paddingLeft: 50,
    display: 'flex',
    '@media (max-width: 768px)': {
      paddingRight: 0,
    },
  },
  stepContent: {
    flexGrow: 2,
  },
  back: {
    background: `${color.darkGreen} 0% 0% no-repeat`,
    width: '165px',
    height: '50px',
    fontSize: '16px',
    marginTop: '20px',
    '& img': {
      marginRight: '15px',
      width: '14px',
      height: '22px',
    },
  },
}));

export const steps = id => [
  {
    key: 0,
    id: ENTREPRISE_IDENTIFICATION_STEP,
    title: loanRequestWording.steps.CLIENT_IDENTIFICATION,
    menuTitle: loanRequestWording.steps.MENU_CLIENT_IDENTIFICATION,
    summaryTitle: loanRequestWording.steps.SUMMARY_CLIENT_IDENTIFICATION,
    url: `/loan/${id}/entreprise-identification`,
    component: EntrepriseIdentification,
  },
  {
    key: 1,
    id: LOAN_INFORMATION_STEP,
    title: loanRequestWording.steps.LOAN_INFORMATION,
    menuTitle: loanRequestWording.steps.MENU_LOAN_INFORMATION,
    summaryTitle: loanRequestWording.steps.SUMMARY_LOAN_INFORMATION,
    url: `/loan/${id}/loan-information`,
    component: LoanInformation,
  },
  {
    key: 2,
    id: GUARANTEES_STEP,
    title: loanRequestWording.steps.LOAN_GUARANTEE,
    menuTitle: loanRequestWording.steps.MENU_LOAN_GUARANTEE,
    summaryTitle: loanRequestWording.steps.SAMMURY_LOAN_GUARANTEE,
    url: `/loan/${id}/guarantee`,
    component: Guarantee,
  },
  {
    key: 3,
    id: DOCUMENTS_UPLOAD_STEP,
    title: loanRequestWording.steps.DOCUMENTS_UPLOAD,
    menuTitle: loanRequestWording.steps.MENU_DOCUMENTS_UPLOAD,
    summaryTitle: loanRequestWording.steps.SUMMARY_DOCUMENTS_UPLOAD,
    url: `/loan/${id}/upload-documents`,
    component: DocumentsUpload,
  },
  {
    key: 4,
    id: ADDITIONAL_INFORMATION_STEP,
    title: loanRequestWording.steps.ADDITIONAL_INFORMATION,
    menuTitle: loanRequestWording.steps.MENU_ADDITIONAL_INFORMATION,
    summaryTitle: loanRequestWording.steps.SUMMARY_ADDITIONAL_INFORMATION,
    url: `/loan/${id}/additional-information`,
    component: AdditionalInformation,
  },
  {
    key: 5,
    id: SUMMARY_STEP,
    title: loanRequestWording.steps.RECAP_INFORMATION,
    menuTitle: loanRequestWording.steps.MENU_RECAP_INFORMATION,
    summaryTitle: loanRequestWording.steps.SUMMARY_RECAP_INFORMATION,
    url: `/loan/${id}/recap-information`,
    component: RecapLoanInformation,
  },
];

function LoanRequest(props) {
  const landingEl = useRef(null);
  const location = props.location.pathname;
  const dispatch = useDispatch();
  const classes = useStyles();
  const storedLoanId = useSelector(getLoanId);
  const {
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    dispatch(updateCurrentStep(getStepIdByStepUrl(location, id)));
  }, [location, dispatch, id]);

  useEffect(() => {
    if (id && parseFloat(id) > 0) {
      dispatch(getLoanRequestDataAction(id));
      return;
    }
    if (!storedLoanId && storedLoanId !== 0) {
      dispatch(getLoanRequestDataAction(storedLoanId));
      return;
    }
    // eslint-disable-next-line
  }, [dispatch, storedLoanId]);

  const scrollToTop = () => {
    const block = 'start';
    setTimeout(() => {
      landingEl.current.scrollIntoView({ behavior: 'smooth', block });
    }, 200);
  };

  const changeStep = (currentStep, nextStep, checked, requestStatus) => {
    if (checked && requestStatus === AVAILABLE_STATUS.INITIATED) {
      dispatch(submitStepAction(currentStep, nextStep));
      scrollToTop();
    }
  };

  const submitStep = (currentStep, nextStep) => {
    dispatch(submitStepAction(currentStep, nextStep));
    scrollToTop();
  };

  const detectErrors = errors => {
    const keys = Object.keys(errors);
    const dom = document.getElementsByName(keys[0]);
    if (dom[dom.length - 1]) {
      dom[dom.length - 1].focus();
    }
  };

  return (
    <div className={classes.container}>
      {!/validation/.test(location) && (
        <>
          <motion.div
            initial='hidden'
            animate='visible'
            variants={menuVariants}
            ref={landingEl}
          >
            <Button
              as={Link}
              to='/home'
              variant='secondary'
              className={`rounded-pill d-flex align-items-center justify-content-center ${classes.back}`}
            >
              <img src={backImg} alt='go back' />
              {loanRequestWording.YOUR_CREDITS}
            </Button>
            <LoanSteps steps={steps(id)} changeStep={changeStep} />
          </motion.div>
          <div xs={10} className={`p-0 ${classes.stepContent}`}>
            {steps(id).map(step => {
              if (step.url === location) {
                return (
                  <FormLayout sectionTitle={step.title} key={step.id} id={id}>
                    <motion.div
                      initial='hidden'
                      animate='visible'
                      variants={stepVariants}
                    >
                      <step.component
                        detectErrors={detectErrors}
                        submitStep={submitStep}
                        idLoan={id}
                      />
                    </motion.div>
                  </FormLayout>
                );
              }
              return null;
            })}
          </div>
        </>
      )}
      {/validation/.test(location) && <Validation />}
    </div>
  );
}

export default LoanRequest;
