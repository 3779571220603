import React from 'react';
export const subMenuTPEWording = {
  FINANCEMENT_CREATION: [
    {
      title: 'PROGRAMME INTELAKA',
      links: [
        {
          text: 'INTELAK Likoum',
          to: '/INTELAK-LIKOUM',
          suTitle: '',
          description:
            'Vous êtes auto entrepreneur, entrepreneur à la tête d’une TPE ou d’une affaire personnelle. Vous êtes installé en milieu URBAIN, Vous avez créé votre entreprise il y a moins de 5 ans ou êtes en cours de création. Réussissez votre projet grâce à INTELAK LIKOUM, une solution de financement simple et flexible qui s’adapte à la singularité de votre activité. Ce crédit vous donne accès à :',
          items: [
            'Financement de votre besoin quelle que soit sa nature : Fonds de roulement de démarrage, acquisition de matériel, acquisition de local ou décalages de règlements.',
            'Des conditions préférentielles sans précédent vous attendent : 2% de taux d’intérêt H.T, sur une durée de 84 mois et un différé qui peut aller jusqu’à 12 mois.',
            'Gratuité des frais et commissions.',
            'Seules les garanties liées au projet sont demandées et aucune garantie personnelle.',
            'Accès à une offre de services non financiers diversifiée (accompagnement, formation, coaching)',
          ],
          synthesis: '',
          action: false,
        },
        {
          text: 'INTELAK Al Moustatmir Alqarawi',
          to: '/INTELAK-ALMOUSTATMIR-ALQARAWI',
          suTitle: '',
          description:
            'Vous êtes auto entrepreneur, entrepreneur à la tête d’une TPE ou d’une affaire personnelle. Vous êtes installé en milieu RURAL, Vous avez créé votre entreprise il y a moins de 5 ans ou êtes en cours de création. Réussissez votre projet grâce à INTELAK LIKOUM, une solution de financement simple et flexible qui s’adapte à la singularité de votre activité. Ce crédit vous donne accès à :',
          items: [
            'Financement de votre besoin quelle que soit sa nature : Fonds de roulement de démarrage, acquisition de matériel, acquisition de local ou décalages de règlements.',
            'Des conditions préférentielles sans précédent vous attendent : 1,75% de taux d’intérêt H.T, sur une durée de 84 mois et un différé qui peut aller jusqu’à 12 mois.',
            'Gratuité des frais et commissions.',
            'Seules les garanties liées au projet sont demandées et aucune garantie personnelle.',
            'Accès à une offre de services non financiers diversifiée (accompagnement, formation, coaching)',
          ],
          synthesis: '',
          action: false,
        },
        {
          text: 'START TPE',
          to: '/START-TPE',
          suTitle: '',
          description: (
            <>
              <p>
                Si vous avez contracté un crédit d’investissement INTELAK ne
                dépassant pas 300 000 DH, pour financer votre besoin en Fonds de
                Roulement, vous pouvez bénéficier d’un prêt d’Honneur START TPE
              </p>
              <p>
                Le montant du prêt d’honneur représentant 20% du montant
                financement débloqué dans le cadre de INTELAK et il est sans
                intérêt et sans garantie. Le montant est plafonné à 50 000 DH et
                vous ne pouvez en bénéficier qu’une seule fois.
              </p>
              <p>
                Le remboursement s’effectue en une seule échéance, 5 ans après
                le déblocage du prêt.
              </p>
            </>
          ),
          items: null,
          synthesis: '',
          action: false,
        },
      ],
    },
    {
      title: 'CREDIT MOYEN LONG TERME',
      links: [
        {
          text: 'Crédit Moyen Long Terme',
          to: '/Credit-Moyen-Long-Terme',
          suTitle: '',
          description: (
            <>
              Vous avez prévu un investissement destiné à créer, équiper ou
              financer l’extension de votre entreprise.
              <br />
              Votre partenaire BANK OF AFRICA vous accompagne sur la durée dans
              votre projet de modernisation par l’octroi d’un Crédit à Moyen et
              Long Terme offrant de multiples avantages:
            </>
          ),
          items: [
            'Le montant de l’aide peut atteindre 70% du coût total d’investissement, fonds de roulement compris',
            'Aisément ajustable, le crédit entreprise de BANK OF AFRICA peut être cumulé avec d’autres sources de financement comme le leasing, un crédit en devise ou le recours à une ligne extérieure)',
            'La durée de remboursement peut atteindre 7 ans pour le moyen terme et 12 ans pour le long terme',
          ],
          synthesis: '',
          action: false,
        },
      ],
    },
    {
      title: 'LEASING',
      links: [
        {
          text: 'Probail',
          to: '/PROBAIL',
          suTitle:
            'Acquérez votre matériel d’entreprise sans épuiser vos fonds avec Pro Bail',
          description: (
            <>
              <p>
                Parce que BANK OF AFRICA connaît bien les besoins de votre
                entreprise, nous avons conçu le crédit leasing Pro Bail en
                partenariat avec Maghrebail.
              </p>
              <p>
                Cette option vous permet d’acquérir plusieurs types de biens
                (4x4 clés en main, véhicule utilitaire neuf, matériel médical ou
                informatique, mobilier de bureau…) tout en bénéficiant d’une
                fiscalité avantageuse grâce au principe de financement par
                leasing.
              </p>
              <p>
                Vos locations de biens sont comptabilisées en tant que charges
                déductibles et vous profitez ainsi d’une réduction de votre
                impôt sur le revenu. D’autre part, le crédit Pro Bail vous offre
                l’occasion d’une exonération TVA à l’acquisition du bien.
              </p>
              <p>
                Et si vous souhaitez devenir propriétaire à terme, vous avez le
                choix de financer jusqu’à 100 % de la valeur du bien sur une
                durée de 36 à 60 mois
              </p>
            </>
          ),
          items: null,
          synthesis: '',
          action: false,
        },
        {
          text: 'BMCE Bail',
          to: '/BMCE-BAIL',
          suTitle:
            'Acquérez le matériel indispensable à la croissance de votre entreprise',
          description:
            'Pour vous aider dans l’acquisition de votre équipement d’entreprise, votre partenaire BANK OF AFRICA a mis au point Bail Entreprises, une solution de crédit-bail qui vous permet :',
          items: [
            'De financer un équipement neuf ou d’occasion, local ou importé : matériel informatique, mobilier de bureau, matériel roulant, engins de travaux, véhicules de tourisme etc.',
            'De disposer du matériel de votre choix jusqu’à terme du contrat de bail',
            'De bénéficier à terme d’une option d’achat sur le matériel dont vous avez disposé',
            'Donnez de l’envergure à votre activité avec Bail Entreprises.',
          ],
          synthesis: '',
          action: false,
        },
      ],
    },
  ],
  FINANCEMENT_CYCLE_EXPLOITATION: [
    {
      title: 'Crédits de trésorerie',
      links: [
        {
          text: 'Facilité de caisse',
          to: '/Facilite-caisse',
          suTitle: '',
          description:
            'Vous souhaitez prévenir ou faire face aux décalages de votre trésorerie ? la Facilité de Caisse répond à votre besoin ponctuel de financement par une avance de trésorerie qui sera autorisée sur le compte de votre entreprise.',
          items: [
            'Le plafond de la facilité de caisse sera adapté à votre volume d’activité et vous permettra de trouver un équilibre dans votre trésorerie',
            'Une fois le montant de la ligne de Facilité Caisse accordé et les garanties constituées, vous êtes libre de l’utiliser ou non, au fur et à mesure de l’évolution de votre activité',
            'Les intérêts sont calculés uniquement en fonction du nombre de jours pendant lesquels le crédit de trésorerie a été effectivement utilisé, et sur le montant dont vous avez réellement disposé',
            'Il vous est possible de renouveler notre solution de Facilité de Caisse autant de fois que vous le souhaitez',
          ],
          synthesis: '',
          action: false,
        },
        {
          text: 'Découvert',
          to: '/Decouvert',
          suTitle: '',
          description:
            'Pour couvrir les dépenses nécessaires à votre activité, l’autorisation de découvert de BANK OF AFRICA offre plusieurs avantages :',
          items: [
            'Utilisable immédiatement, l’avance de trésorerie accordée par BANK OF AFRICA répond spécifiquement aux besoins de votre secteur d’activité',
            'Notre solution finance votre besoin de trésorerie sur plusieurs mois, le temps pour votre entreprise de gérer ses dépenses et de renflouer son budget',
            'Son coût est proportionnel à la durée du découvert autorisé et aux montants accordés',
          ],
          synthesis: '',
          action: false,
        },
        {
          text: 'Escompte commercial',
          to: '/Escompte-commercial',
          suTitle: '',
          description: (
            <>
              L’escompte commercial vous permet de faire face aux délais de
              paiement de vos clients en tenant compte de vos effets de commerce
              dans votre chiffre d’affaires.
              <br />
              Cette solution vous offre plusieurs avantages :
            </>
          ),
          items: [
            'Elle facilite le financement de vos marchés publics par l’octroi d’une ligne d’escompte',
            'Le prêt accordé est garanti par la CMM (Caisse Marocaine des Marchés)',
            'Le financement peut durer globalement de 6 à 12 mois avec un montant variant de 75 à 90 % de la valeur du marché',
          ],
          synthesis:
            'Grâce à la solution d’escompte commercial, les délais n’auront plus d’impact sur votre trésorerie.',
          action: false,
        },
        {
          text: 'Crédit par signature (cautions)',
          to: '/credit-par-signature',
          suTitle: '',
          description:
            'Votre candidature à l’adjudication aux marchés publics est facilitée par la délivrance d’une Caution Provisoire BANK OF AFRICA à l’administration concernée :',
          items: [
            "Système efficace et rapide, la validité de la caution bancaire échoit le jour de l'attribution du marché, à l’ouverture des plis",
            'Vous êtes dispensé de dépôts de fonds, ainsi votre trésorerie se voit soulagée',
            'Le recours à la Caution Provisoire de BANK OF AFRICA donne une impulsion à votre candidature aux appels d’offre du marché',
          ],
          synthesis:
            'Avec BANK OF AFRICA, obtenez vos cautions provisoires en toute facilité.',
          action: false,
        },
      ],
    },
    {
      title: 'TAMWIL Entrepreneur',
      links: [
        {
          text: 'TAMWIL Entrepreneur',
          to: '/TAMWIL-Entrepreneur',
          suTitle: '',
          description: (
            <>
              <p>
                Vous êtes à la tête d’une entreprise et vous projetez de
                nombreux investissements ? Tamwil Entrepreneur vous aide à
                consolider votre activité.
              </p>
              <p>
                Tamwil Entrepreneur est un crédit professionnel spécialement
                dédié aux entrepreneurs.
              </p>
              <p>
                Que vous soyez commerçant, artisan ou agriculteur, votre
                entreprise a besoin de fonds pour s’agrandir. Acquisition de
                matériel professionnel, de locaux ou de véhicules. <br />
                BANK OF AFRICA vous accompagne à chaque étape de votre activité
                pour en garantir la croissance et la longévité.
              </p>
              <p>
                Avec le crédit amortissable Tamwil Entrepreneur, le
                remboursement s’effectue par mensualités. D’un montant maximal
                de 60 000 Dhs, c’est une solution de financement sûre, adaptée à
                vos besoins et adossée à votre compte courant. Et pour davantage
                de liberté, vous avez la possibilité de procéder à un
                remboursement anticipé quand vous le désirez.
              </p>
            </>
          ),
          items: null,
          synthesis: '',
          action: false,
        },
      ],
    },
    {
      title: 'TAMWIL Auto Entrepreneur',
      links: [
        {
          text: 'TAMWIL Auto Entrepreneur',
          to: '/TAMWIL-Auto-Entrepreneur',
          suTitle: '',
          description: (
            <>
              <p>
                Tamwil Auto-Entrepreneur est un crédit professionnel
                spécialement dédié aux autoentrepreneurs. Frais d’établissement,
                honoraires de conseil fiscal, prévision de fonds de roulement,
                location de local, équipements… s’installer en Auto-entrepreneur
                a un coût.
              </p>
              <p>
                Spécialement conçu pour aider au démarrage des
                autoentrepreneurs, Tamwil Auto-Entrepreneur est un crédit
                amortissable, dont le remboursement s’effectue par mensualités.
                D’un montant maximal de 20 000 Dhs, c’est une solution de
                financement sûre adossée à votre compte chèque.
              </p>
              <p>
                Mettez toutes les chances de votre côté pour le démarrage de
                votre activité avec BMCE Tamwil Auto-Entrepreneur.
              </p>
            </>
          ),
          items: null,
          synthesis: '',
          action: false,
        },
      ],
    },
  ],
  FINANCEMENT_DEVELOPPEMENT: [
    {
      title: 'L’Entreprenariat au Féminin (Women In Business)',
      links: [
        {
          text: 'L’Entreprenariat au Féminin (Women In Business)',
          to: '/Women-In-Business',
          suTitle: '',
          description: (
            <>
              Vous êtes une femme entrepreneure ou vous venez de démarrer dans
              l’entrepreneuriat ?<br />
              Vous dirigez une entreprise ou vous êtes à la tête d’une activité
              ou d’une coopérative ?<br />
              BANK OF AFRICA, encourage votre esprit d’entreprendre!
              <br />
              Profitez d’une offre complète et sur mesure, qui vous accompagnera
              dans la réalisation et la réussite de vos projets !
            </>
          ),
          items: [
            'Un Package spécial et GRATUIT pour toute nouvelle cliente !',
            'Des formules de crédits modulables, pour financer vos besoins d’exploitation et d’investissement !',
            'Un programme d’accompagnement pensé pour vous (Formations, networking, etc)',
          ],
          synthesis: '',
          action: false,
        },
      ],
    },
    {
      title: 'Crédit TANMYA',
      links: [
        {
          text: 'Crédit TANMYA',
          to: '/credit-tanmya',
          suTitle: '',
          description: (
            <>
              Le CREDIT « TANMYA» est une offre de financement combinant, une
              ligne de crédit Amortissable et/ou de Trésorerie adossée au FONDS
              DAMANE de la CCG, destinée à financer les besoins d’investissement
              et / ou de Trésorerie
            </>
          ),
          items: null,
          synthesis: '',
          action: false,
        },
      ],
    },
  ],
};
