import React from 'react';
import { ReactComponent as Icon } from 'assets/svg/status-expired.svg';
import { ToastContainer as Container, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.scss';
import { toastTypes } from '../constants';

const toastList = new Set();

const ToastContainer = () => (
  <Container
    autoClose={false}
    className='bmce-toast-container'
    bodyClassName='bmce-toast-body'
    toastClassName='bmce-toast'
    progressClassName='bmce-toast-progress-bar'
    // To change position, make sure to use one of the string bellow
    position='top-center' // top-right, top-center, top-left, bottom-right, bottom-center, bottom-left
    transition={Slide}
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    draggable
    pauseOnHover
  />
);

const SuccessToast = Content =>
  toast.success(
    () => (
      <>
        <span className='icon'>
          <Icon />
        </span>
        {Content}
      </>
    ),
    {
      type: toast.TYPE.SUCCESS,
    },
  );

const ErrorToast = (
  Content,
  autoClose = false,
  type = toastTypes.INTERNAL_SERVER_ERROR,
) => {
  if (!toastList.has(type)) {
    toast.error(
      () => (
        <>
          <span className='icon'>
            <Icon />
          </span>
          {Content}
        </>
      ),
      {
        type: toast.TYPE.ERROR,
        onClose: () => toastList.delete(type),
        autoClose: autoClose,
      },
    );

    toastList.add(type);
  }
};

const InfoToast = Content =>
  toast.info(
    () => (
      <>
        <span className='icon'>
          <Icon />
        </span>
        {Content}
      </>
    ),
    {
      type: toast.TYPE.INFO,
    },
  );

const WarningToast = Content =>
  toast.warn(
    () => (
      <>
        <span className='icon'>
          <Icon />
        </span>
        {Content}
      </>
    ),
    {
      type: toast.TYPE.WARNING,
    },
  );
export { SuccessToast, InfoToast, WarningToast, ErrorToast };

export default ToastContainer;
