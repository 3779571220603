import { loanRequestAt } from './actions.loanRequest';
import { fromJS } from 'immutable';
import {
  GUARANTEES_STEP,
  DOCUMENTS_UPLOAD_STEP,
  ENTREPRISE_IDENTIFICATION_STEP,
  LOAN_INFORMATION_STEP,
  SUMMARY_STEP,
} from './constants.loanRequest';
import authAT from 'features/auth/actions.auth';
import { AVAILABLE_STATUS } from 'features/home/constants.loansList';
import { loansListAt } from 'features/home/actions.loansList';
export const LOAN_REQUEST_REDUCER = 'LOAN_REQUEST_REDUCER';

export const initState = fromJS({
  id: null,
  currentStep: ENTREPRISE_IDENTIFICATION_STEP,
  status: AVAILABLE_STATUS.INITIATED,
  prevLoanData: {
    [ENTREPRISE_IDENTIFICATION_STEP]: {},
    [LOAN_INFORMATION_STEP]: {
      loanLines: [],
    },
  },
  [ENTREPRISE_IDENTIFICATION_STEP]: {
    compliance: true,
    shareholders: [],
    managements: [],
    interlocutors: [],
    errors: {},
  },
  [LOAN_INFORMATION_STEP]: {
    disabled: false,
    loanType: 'new',
    errors: {},
    loanLines: [],
    fields: {
      type: {
        value: undefined,
      },
      subType: {
        value: undefined,
      },
    },
  },
  [GUARANTEES_STEP]: {
    errors: {},
    fields: {
      idGarantie: '',
      guaranteeAmount: '',
      guarantor: '',
      description: '',
    },
    demandeGaranties: [],
    guaranteesList: [],
  },
  [DOCUMENTS_UPLOAD_STEP]: {
    documents: [],
    errors: {},
  },

  [SUMMARY_STEP]: {},

  guaranteesList: [],
  loanTypeConfig: [],
  accountsConfig: [],
  objectsConfig: [],
});

export function loanRequestReducer(state = initState, action) {
  switch (action.type) {
    case loanRequestAt.GET_ACCOUNT_LIST_SUCCESS:
      return state.set('accountsList', action.accountsList);
    case loanRequestAt.LOAN_REQUEST_DATA_SUCCESS:
      return state.mergeIn(
        [ENTREPRISE_IDENTIFICATION_STEP],
        fromJS(action.loanRequestData),
      );
    case loanRequestAt.LOAN_REQUEST_STEP_DATA_SUCCESS:
      return state.mergeIn([action.step], fromJS(action.stepData));
    case loanRequestAt.UPDATE_LOAN_DATA:
      return state.setIn(action.input, fromJS(action.data));
    case loanRequestAt.UPDATE_INPUT:
      return state.setIn([action.step, ...action.name], fromJS(action.value));
    case loanRequestAt.SUBMIT_ENTREPRISE_IDENTIFICATION_ERROR:
      return state.setIn(
        [ENTREPRISE_IDENTIFICATION_STEP, 'errors'],
        fromJS(action.errors),
      );
    case loanRequestAt.SET_GUARANTEES_LIST:
      return state.setIn(
        [GUARANTEES_STEP, 'guaranteesList'],
        action.guaranteesList,
      );
    case loanRequestAt.SET_GUARANTEES_ERRORS:
      return state.setIn([GUARANTEES_STEP, 'errors'], action.errors);
    case loanRequestAt.ADD_NEW_ELEMENT:
      const fields = state
        .get(LOAN_INFORMATION_STEP)
        .get('fields')
        .toJS();
      const index = state
        .get(LOAN_INFORMATION_STEP)
        .get('loanLines')
        .toJS()
        .findIndex(element => element.id === fields.id);
      return state
        .mergeIn(
          index > -1
            ? [LOAN_INFORMATION_STEP, 'loanLines', index]
            : [LOAN_INFORMATION_STEP, 'loanLines'],
          fields,
        )
        .mergeIn(
          [LOAN_INFORMATION_STEP, 'fields'],
          fromJS({
            amount: '',
            loanPurpose: '',
            id: null,
          }),
        )
        .setIn([LOAN_INFORMATION_STEP, 'disabled'], true);
    case loanRequestAt.UPDATE_GUARANTEES_LIST:
      return state
        .setIn([GUARANTEES_STEP, 'demandeGaranties'], fromJS(action.guarantees))
        .setIn(
          [GUARANTEES_STEP, 'fields'],
          fromJS({
            guarantee: {},
            guaranteeAmount: '',
            guarantor: '',
          }),
        );
    case loanRequestAt.SET_DOCUMENTS_LIST:
      return state.setIn(
        [DOCUMENTS_UPLOAD_STEP, 'documents'],
        fromJS(action.files),
      );

    case loanRequestAt.STEP_ERRORS:
      return state.setIn([action.step, 'errors'], fromJS(action.errors));

    case loanRequestAt.UPDATE_LOAN_TYPE_CONFIG:
      return state.set('loanTypeConfig', fromJS(action.config));

    case loanRequestAt.GET_CONFIG_SUCCESS:
      return state.setIn([...action.name], fromJS(action.config));

    case loanRequestAt.INIT_LOAN_TYPE:
      return state.mergeIn(
        [LOAN_INFORMATION_STEP, 'fields'],
        fromJS(action.initConfig),
      );

    case loanRequestAt.UPDATE_CURRENT_STEP:
      return state.set('currentStep', fromJS(action.step));

    case loanRequestAt.UPDATE_ID_DEMANDE:
      return state.set('id', fromJS(action.idDemande));

    // Cases when we need to init state
    case loansListAt.SET_SELECTED_TIERS:
    case authAT.LOG_OUT_SUCCESS:
      return initState;

    default:
      return state;
  }
}
