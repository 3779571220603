import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import {
  ADDITIONAL_INFORMATION_STEP,
  DOCUMENTS_UPLOAD_STEP,
  ENTREPRISE_IDENTIFICATION_STEP,
  GUARANTEES_STEP,
  LOAN_INFORMATION_STEP,
  SUMMARY_STEP,
} from '../constants.loanRequest';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  currentStepSelector,
  getLoanStepSelector,
  selectStepInfo,
} from '../selectors.loanRequest';

const useStyles = makeStyles(theme => ({
  step: {
    color: 'white',
    cursor: 'pointer',
    fontSize: 18,
    maxWidth: '200px',
    marginTop: 45,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  currentStepIcon: {
    backgroundColor: theme.color.darkBlue,
    borderColor: `${theme.color.darkBlue}!important`,
    color: 'white',
  },
  otherStepIcon: {
    color: theme.color.darkGray,
    cursor: 'default',
  },
  otherStep: {
    opacity: '0.6',
    cursor: 'default',
  },
  loanStepsContainer: {
    width: 210,
    marginRight: 0,
  },
  title: {
    width: 110,
    fontSize: 16,
  },
  checkStepIcon: {
    backgroundColor: theme.color.secondary,
    borderColor: `${theme.color.secondary}!important`,
    color: 'white',
    cursor: 'default',
  },
  badge: {
    position: 'relative',
    fontSize: 18,
    border: `1px solid ${theme.color.darkGray}`,
    right: -77,
    height: 50,
    width: 50,
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '55%',
  },
  otherStepCol: { cursor: 'default' },
}));

function LoanSteps({ steps, changeStep }) {
  const classes = useStyles();
  const currentStep = useSelector(currentStepSelector);
  const requestStatus = useSelector(getLoanStepSelector('status'));
  const checked = {
    [ENTREPRISE_IDENTIFICATION_STEP]: useSelector(
      selectStepInfo(ENTREPRISE_IDENTIFICATION_STEP, 'checked'),
    ),
    [LOAN_INFORMATION_STEP]: useSelector(
      selectStepInfo(LOAN_INFORMATION_STEP, 'checked'),
    ),
    [GUARANTEES_STEP]: useSelector(selectStepInfo(GUARANTEES_STEP, 'checked')),
    [DOCUMENTS_UPLOAD_STEP]: useSelector(
      selectStepInfo(DOCUMENTS_UPLOAD_STEP, 'checked'),
    ),
    [ADDITIONAL_INFORMATION_STEP]: useSelector(
      selectStepInfo(ADDITIONAL_INFORMATION_STEP, 'checked'),
    ),
    [SUMMARY_STEP]: useSelector(selectStepInfo(SUMMARY_STEP, 'checked')),
  };
  return (
    <div className={`${classes.loanStepsContainer}`}>
      {steps.map((step, key) => (
        <Row
          className={`py-2 title1 ${classes.step}`}
          key={step.key}
          onClick={() =>
            changeStep(currentStep, step.id, checked[step.id], requestStatus)
          }
        >
          <Col
            className={
              currentStep === step.id || checked[step.id]
                ? ''
                : classes.otherStepCol
            }
          >
            <div
              className={
                currentStep === step.id || checked[step.id]
                  ? classes.currentStep + ' ' + classes.title
                  : classes.otherStep + ' ' + classes.title
              }
            >
              {step.menuTitle}
            </div>
            <Badge
              pill
              variant='light'
              className={
                (currentStep === step.id
                  ? classes.currentStepIcon
                  : checked[step.id]
                  ? classes.checkStepIcon
                  : classes.otherStepIcon) +
                ' ' +
                classes.badge
              }
            >
              {checked[step.id] && currentStep !== step.id && (
                <FontAwesomeIcon icon={faCheck} />
              )}
              {(!checked[step.id] ||
                currentStep === step.id ||
                (!checked[step.id] && currentStep !== step.id)) && (
                <span>{key + 1}</span>
              )}
            </Badge>
          </Col>
        </Row>
      ))}
    </div>
  );
}
export default LoanSteps;
