import { createSelector } from 'reselect';
import { LOAN_REQUEST_REDUCER } from './reducers.loanRequest';
import { subTypeConst, natureConst } from './constants.loanRequest';

export const getLoanRequest = state => state.get(LOAN_REQUEST_REDUCER);

export const getGrcDataSelector = createSelector(
  getLoanRequest,
  loanRequest => loanRequest.get('grcData'),
);

export const getLoanStepSelector = step =>
  createSelector(
    getLoanRequest,
    loanRequest =>
      Object(loanRequest.get(step)) !== loanRequest.get(step)
        ? loanRequest.get(step)
        : loanRequest.get(step).toJS(),
  );

export const selectStepInfo = (step, type) => {
  return createSelector(
    getLoanRequest,
    // use toJS() function only on object type.
    loanRequest =>
      Object(loanRequest.getIn([step, type])) !==
      loanRequest.getIn([step, type])
        ? loanRequest.getIn([step, type])
        : loanRequest.getIn([step, type]).toJS(),
  );
};

export const selectStepInfoByArray = (step, type) => {
  return createSelector(
    getLoanRequest,
    // use toJS() function only on object type.
    loanRequest =>
      Object(loanRequest.getIn([step, ...type])) !==
      loanRequest.getIn([step, ...type])
        ? loanRequest.getIn([step, ...type])
        : loanRequest.getIn([step, ...type]).toJS(),
  );
};
export const getLoanId = createSelector(
  getLoanRequest,
  loan => loan.get('id'),
);

export const guaranteesListSelector = createSelector(
  getLoanRequest,
  loan => loan.get('guaranteesList'),
);

export const loanTypeConfigSelector = createSelector(
  getLoanRequest,
  loan => loan.get('loanTypeConfig').toJS(),
);

export const accountsConfigSelector = createSelector(
  getLoanRequest,
  loan =>
    loan
      .get('accountsConfig')
      .toJS()
      .map(element => {
        return {
          value: element.value,
          label: element.label + ' ' + element.value,
        };
      }),
);

export const objectsConfigSelector = createSelector(
  getLoanRequest,
  loan => loan.get('objectsConfig').toJS(),
);

export const currentStepSelector = createSelector(
  getLoanRequest,
  loan => loan.get('currentStep'),
);

export const loanTypeSelector = (typeParam, subTypeParam) =>
  createSelector(
    loanTypeConfigSelector,
    loan => {
      const subType = loan.find(element => {
        return (
          parseInt(element.value) === parseInt(typeParam ? typeParam : 1, 10)
        );
      });

      let nature = null;
      if (subType && subType[subTypeConst]) {
        nature = subType[subTypeConst].find(element => {
          return (
            parseInt(element.value) ===
            parseInt(subTypeParam ? subTypeParam : 1, 10)
          );
        });
      }

      return [
        subType ? subType[subTypeConst] : [],
        nature ? nature[natureConst] : [],
      ];
    },
  );
