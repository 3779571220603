import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { ThemeProvider } from '@material-ui/styles';
import { ConnectedRouter } from 'connected-react-router/immutable';
import theme from 'core/root.theme';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { history } from './store';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <Row className={'w-100 m-0'}>
          <Col className={'p-0'}>
            <App />
          </Col>
        </Row>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
