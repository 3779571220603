import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { sharedStyle } from '../styles.loanRequest';
import { convertHexToRgba } from 'common/utils/colorConverter';
import { Button } from 'react-bootstrap';
import { loanRequestWording } from 'common/locale';

const useStyles = makeStyles(({ color }) => ({
  ...sharedStyle,
  devider: {
    height: 1,
    backgroundColor: color.shallowGray,
  },
  inlineDevider: {
    flexGrow: 2,
  },
  plusIcon: {
    transition: 'all 500ms',
    border: `1px solid ${color.shallowGray}`,
    cursor: 'pointer',
    backgroundColor: convertHexToRgba(color.darkBlue, 2),
    borderRadius: '45px',
    color: color.darkBlue,
    height: 38,
    '& line': {
      stroke: color.darkBlue,
    },
    '&:hover': {
      backgroundColor: color.darkBlue,
      '& line': {
        stroke: color.shallowGray,
      },
    },
  },
  lightDividerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const SecondaryDivider = ({ title }) => {
  const classes = useStyles();
  return (
    <div className='mb-4'>
      <div className={`mb-3 ${classes.devider}`} />
      <h2 className='title4Regular s-18 mb-0'>{title}</h2>
    </div>
  );
};

export const PrimaryDivider = ({
  title,
  light = false,
  isEnabled = false,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div className={`title4 ${classes.groupTitle}`}>
      <h2 className={`s-18 mb-0 ${light ? 'title3' : 'title4'}`}>{title}</h2>
      <div className={`mx-2 ${classes.devider} ${classes.inlineDevider}`} />
      {isEnabled && (
        <>
          <Button
            className={`rounded-pill ${classes.plusIcon}`}
            variant='dark'
            onClick={onClick}
          >
            <Plus data-testid='plus-btn' className={'mr-2'} variant='dark' />
            {loanRequestWording.add}
          </Button>
        </>
      )}
    </div>
  );
};

export const LightDivider = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.lightDividerContainer}>
      <p className={`mb-0 title3`}>{title}</p>
      <div className={`mx-2 ${classes.devider} ${classes.inlineDevider}`} />
    </div>
  );
};
