import { createSelector, createStructuredSelector } from 'reselect';
import { LOANS_LIST_REDUCER } from './reducer.loansList';
import {
  ACCEPTED_LOANS,
  LOANS_REQUEST,
  MOST_USED_NATURES,
} from './constants.loansList';
const getLoansListReducer = state => state.get(LOANS_LIST_REDUCER).toJS();

export const tiersListSelector = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer.tiers,
);

export const mostUsedNaturesSelector = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer[MOST_USED_NATURES],
);

const loanLines = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer[ACCEPTED_LOANS],
);
const listDemandesSelector = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer[LOANS_REQUEST],
);
export const loansSelector = createStructuredSelector({
  [LOANS_REQUEST]: listDemandesSelector,
  [ACCEPTED_LOANS]: loanLines,
});

export const isFetchingSelector = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer.isFetching,
);

export const getSelectedTiersSelector = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer.selectedTiers,
);

export const getLoanDetailsSelector = createSelector(
  getLoansListReducer,
  loans => loans.loanDetails,
);

export const getModalSelector = modal =>
  createSelector(
    getLoansListReducer,
    loans => loans[modal],
  );

export const toastIsOpenSelector = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer.toastIsOpen,
);

export const initiatedLoanIdSelector = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer.initiatedLoanId,
);

export const contactSelector = createSelector(
  getLoansListReducer,
  loantsListReducer => loantsListReducer.contact,
);
