const getSuportIdFromResponse = responseData => {
  if (responseData && responseData.includes('support ID')) {
    let splitedMessage = responseData.split('<br>');
    splitedMessage = splitedMessage[2].split(':');
    return splitedMessage[1];
  }
  return null;
};

export default getSuportIdFromResponse;
