export const authAT = {
  SUBMIT_BMCEDIRECT_AUTH: 'SUBMIT_BMCEDIRECT_AUTH',
  SUBMIT_BMCEDIRECT_AUTH_SUCCESS: 'SUBMIT_BMCEDIRECT_SUCCESS_AUTH',
  SUBMIT_SPECIFIC_AUTH_ERROR: 'SUBMIT_BMCEDIRECT_ERROR_AUTH',
  SUBMIT_AUTH_ERROR: 'SUBMIT_ERROR_AUTH',
  SUBMIT_BBO_AUTH: 'SUBMIT_BBO_AUTH',
  SUBMIT_SSO_AUTH: 'SUBMIT_SSO_AUTH',
  SUBMIT_BBO_AUTH_WITH_TIER: 'SUBMIT_BBO_AUTH_WITH_TIER',
  SUBMIT_SSO_AUTH_WITH_TIER: 'SUBMIT_SSO_AUTH_WITH_TIER',
  SUBMIT_BBO_AUTH_SUCCESS: 'SUBMIT_BBO_AUTH_SUCCESS',
  SUBMIT_SSO_AUTH_SUCCESS: 'SUBMIT_BBO_AUTH_SUCCESS',
  SUBMIT_BMCE_DIRECT_AUTH_SUCCESS: 'SUBMIT_BMCE_DIRECT_AUTH_SUCCESS',
  SUBMIT_PROFILE_AUTH: 'SUBMIT_PROFILE_AUTH',

  VERIFY_RIB: 'VERIFY_RIB',
  SET_REGISTER_DATA: 'SET_REGISTER_DATA',
  SIGNUP: 'SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SET_AUTH_SUCCESS: 'SET_AUTH_SUCCESS',
  SET_AUTH_TYPE: 'SET_AUTH_TYPE',
  CONFIRME_PHONE_NUMBER: 'CONFIRME_PHONE_NUMBER',
  INCREMENT_SIGNUP_STEP: 'INCREMENT_SIGNUP_STEP',
  DESCREMENT_SIGNUP_STEP: 'DESCREMENT_SIGNUP_STEP',
  LOG_OUT: 'LOG_OUT',
  LOG_OUT_SUCCESS: 'LOG_OUT_SUCCESS',
  INIT_SIGNUP: 'INIT_SIGNUP',
  VALIDATE_ACCOUNT: 'VALIDATE_ACCOUNT',
  VALIDATE_ACCOUNT_ERROR: 'VALIDATE_ACCOUNT_ERROR',
  FETCH_TRIBUNALS: 'FETCH_TRIBUNALS',
  FETCH_BUSNESS_CENTERS: 'FETCH_BUSNESS_CENTERS',
  SET_TRIBUNALS: 'SET_TRIBUNALS',
  SET_BUSNESS_CENTERS: 'SET_BUSNESS_CENTERS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SET_CHANGE_PASSWORD: 'SET_CHANGE_PASSWORD',
  INIT_CHANGE_PASSWORD: 'INIT_CHANGE_PASSWORD',
  ACCEPT_GENERAL_CONDITION: 'ACCEPT_GENERAL_CONDITION',
};

export const prospectAT = {
  LEGAL_FORM: 'LEGAL_FORM',
  VALIDATE_INFORMATION: 'VALIDATE_INFORMATION',
  FETCH_AGENCIES: 'FETCH_AGENCIES',
  SUBMIT_PROSPECt_REQUEST: 'SUBMIT_PROSPECt_REQUEST',
  SUBMIT_PROSPECt_REQUEST_SUCCESS: 'SUBMIT_PROSPECt_REQUEST_SUCCESS',
  UPDATE_PROSPECT: 'UPDATE_PROSPECT',
  GET_CITIES: 'GET_CITIES',
  GET_AGENCY_COORDONATES: 'GET_AGENCY_COORDONATES',
  GET_AGENCY_COORDONATES_SUCCESS: 'GET_AGENCY_COORDONATES_SUCCESS',
};

export const initSignUp = () => ({ type: authAT.INIT_SIGNUP });
export const incrementStep = () => ({ type: authAT.INCREMENT_SIGNUP_STEP });
export const decrementeStep = () => ({ type: authAT.DESCREMENT_SIGNUP_STEP });
export const setAuthSuccess = accountType => ({
  type: authAT.SET_AUTH_SUCCESS,
  accountType,
});

export const updateProspect = (names, value) => ({
  type: prospectAT.UPDATE_PROSPECT,
  names,
  value,
});

export const getLegalForm = () => ({
  type: prospectAT.LEGAL_FORM,
});

export const validateProspectInformation = () => ({
  type: prospectAT.VALIDATE_INFORMATION,
});

export const fetchTribunals = () => ({
  type: authAT.FETCH_TRIBUNALS,
});

export const fetchBusinessCenters = city => ({
  type: authAT.FETCH_BUSNESS_CENTERS,
  city,
});

export const fetchAgenciesByCity = city => ({
  type: prospectAT.FETCH_AGENCIES,
  city,
});

export const submitProspectRequest = () => ({
  type: prospectAT.SUBMIT_PROSPECt_REQUEST,
});

export const getCities = agence => ({
  type: prospectAT.GET_CITIES,
  agence,
});

export const getAgencyCoordinates = () => ({
  type: prospectAT.GET_AGENCY_COORDONATES,
});

export default authAT;
