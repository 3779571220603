import React from 'react';
import { validFileExtensions } from 'features/loanRequest/constants.loanRequest';
export const authWording = {
  seo: {
    TITLE: 'Crédit en ligne Maroc | BANK OF AFRICA',
    DESCRIPTION:
      'Accédez à la plateforme de crédit en ligne de BANK OF AFRICA et augmentez votre marge de manœuvre',
  },
  LOG_IN: 'Connexion',
  BECOME_CUSTOMER: 'Devenir Client',
  OPEN_ACCOUNT: 'Je crée mon profil',
  CLIENT_IDENTIFICATION: "Identification de l'entreprise",
  MANAGER_IDENTIFICATION: 'Identification du contact',
  ENTER_BUSINESS_REGISTER: 'N° Registre de commerce',
  PROFILE_VALIDATION: 'Validation du profil',
  VALIDATE: 'Valider',
  NEXT: 'Suivant',
  PREV: 'Précédent',
  TRIBUNAL: 'Code tribunal',
  CITY: 'Ville',
  VALIDATION_CODE: 'Code de validation',
  RC: 'Numéro de compte',
  COMPANY_NAME: "Raison sociale de l'entreprise",
  ACTIVITY_AREA: "Secteur d'activité",
  FIRST_NAME: 'Nom',
  LAST_NAME: 'Prénom',
  PHONE_NUMBER: 'Numéro de téléphone GSM',
  MAIL: 'Adresse mail',
  BUSNESS_CENTER: 'Votre agence / CAF',
  KEY: 'Clé',
  RACINE: 'Racine',
  MAIL_CONFIRMATION: "Confirmation d'adresse mail",
  FUNCTION: "Fonction au sein de l'entreprise",
  CONGRATULATIONS: 'Félicitations!',
  ACCOUNT_CREATED_SUCCESSFULLY:
    'Votre compte a bien été créé, pour finaliser l’inscription, merci de vérifier votre boite mail et cliquer sur le lien transmis pour activer votre compte.',
  ACCOUNT_CREATED_DES:
    "NB: le numéro de téléphone entré n'est pas le même dans le GRC",
  PASSWORD_SENDED: 'Votre mot de passe a été envoyé au *******',
  CONTACT_YOUR_AGENCY: 'Veuillez contacter votre agence.',
  SMS_NOT_RECEIVED:
    'Mot de passe non reçu? Nous vous invitons à contacter votre chargé d’affaire.',
  PASSWORD_CHANGED_SUCCESSFULLY: 'Le mot de passe a été changé avec succès',
  auth: {
    ACCESS_TYPE: 'Veuillez choisir votre accès',
    ACCESS:
      'Accédez à votre espace à travers le mode d’authentification de votre choix : ',
    BBO: 'Business online',
    BMCEDIRECT: 'BMCE Direct',
    PROFILE: 'Profil',
    RETURN: 'Retour',
    CONNEXION: 'Connexion',
    LOGIN: 'Utilisateur',
    PASSWORD: 'Mot de passe',
    AUTHENTICATION: 'Clients BANK OF AFRICA',
    VALIDATE: 'Valider',
    TIER_TITLE: 'Veuillez choisir un compte',
  },
  signUpAccess: {
    BRIEF_DESCRIPTION:
      'Gérer vos crédits Entreprise à distance en toute sécurité !',
    DESCRIPTION:
      'Que vous gériez une TPE, une PME ou une grande entreprise, nous soutenons vos projets par nos services de conseils professionnels à distance et augmentons votre marge de manœuvre à travers un financement adapté aux besoins de votre entreprise.',
    INVITATION:
      'Partagez avec nous votre besoin de financement, un conseiller vous contactera dans les plus brefs délais.',
    WELCOME: 'Vous n’êtes pas client BANK OF AFRICA ?',
    SUBTITLE: 'Vous n’êtes pas client BANK OF AFRICA ?',
  },
  ACCOUNT_VALIDATION: 'Validation du compte...',
  LOG_OUT: 'Déconnexion',
  generalCondition: {
    generalConditionTitle: 'Conditions générales d’utilisation',
  },
  CHOOSE_COMPANY: 'Merci de choisir une société:',
};

export const prospectWording = {
  SOCIAL_REASON: 'Raison sociale',
  LEGAL_FORM: 'Forme juridique',
  HEAD_OFFICE: 'Siège social',
  CAPITAL: 'Capital Social',
  TURNOVER: 'Dernier chiffre d’affaires (en DHS)',
  REQUESTED_ENVELOPE: 'Enveloppe sollicitée',
  NAME: 'Nom interlocuteur',
  COORDONNER: 'N° de téléphone',
  BACK_HOME: 'Retour à la page d’accueil',
  PROSPECT_TITLE: 'Vous n’êtes pas encore client ?',
  PROSPECT_VALIDATION: 'Merci d’avoir choisi BANK OF AFRICA',
  PROSPECT_VALIDATION_SUB_TITLE:
    'Un conseiller prendra contact avec vous dans les plus brefs délais.',
  PROSPECT_TITLE_INFORMATION: 'Merci de renseigner les informations suivantes',
  PROSPECT_BUSINESS_CENTER: 'Choisissez un Centre d’Affaires ou une Agence',
  NEXT: 'Suivant',
  VALIDATE: 'Valider',
  CITY: 'Ville',
  PROSPECT_LINK: 'Accéder au formulaire de contact',
  COMPLIANCE_BEGIN: 'J’ai lu et j’accepte les termes des',
  COMPLIANCE_LEGALE_NOTICE: 'mentions légales.',
  COMPLIANCE_END:
    'Ce traitement est autorisé par la CNDP sous le numéro A-GC-368/2014',
};

export const loanRequestWording = {
  AMOUNT: 'Montant',
  VALIDATE: 'Valider',
  CANCEL: 'Annuler',
  steps: {
    CLIENT_IDENTIFICATION: 'Identification du client',
    LOAN_INFORMATION: 'Demande de crédit',
    DOCUMENTS_UPLOAD: 'Je transmets mes documents',
    LOAN_GUARANTEE: 'Garanties à constituer',
    ADDITIONAL_INFORMATION: 'Informations complémentaires',
    RECAP_INFORMATION: 'Récapitulatif de votre demande',

    // title for menu
    MENU_CLIENT_IDENTIFICATION: 'Identification du client',
    MENU_LOAN_INFORMATION: 'Demande de crédit',
    MENU_DOCUMENTS_UPLOAD: 'Documents justificatifs',
    MENU_LOAN_GUARANTEE: 'Garanties à constituer',
    MENU_ADDITIONAL_INFORMATION: 'Informations complémentaires',
    MENU_RECAP_INFORMATION: 'Récapitulatif de votre demande',

    // title for summary
    SUMMARY_CLIENT_IDENTIFICATION: 'Identification du client',
    SUMMARY_LOAN_INFORMATION: 'Demande de crédit',
    SUMMARY_DOCUMENTS_UPLOAD: 'Documents à transmettre',
    SUMMARY_LOAN_GUARANTEE: 'Garanties à constituer',
    SUMMARY_ADDITIONAL_INFORMATION: 'Informations complémentaires',
    SUMMARY_RECAP_INFORMATION: 'Récapitulatif de votre demande',
  },
  entrepriseIdentificationStep: {
    seo: {
      TITLE: 'Emprunt entreprise | BANK OF AFRICA',
      DESCRIPTION:
        'Demandez votre emprunt entreprise sans vous déplacer en agence via la plateforme de crédit en ligne de BANK OF AFRICA ',
    },
    LEGAL_CATEGORIE: 'Catégorie Juridique',
    COMPANY_NAME: 'Raison sociale',
    SHARE_CAPITAL: 'Capital Social',
    HEAD_OFFICE: 'Siège social',
    PATENT: 'Patente',
    NUM_ICE: 'ICE',
    GROUP_MEMBERSHIP: 'Appartenance à un Groupe',
    COMMERCIAL_REGISTER: 'Register commercial',
    RC_NUMBER: 'N°RC',
    RC_TRIBUNAL_CODE: 'Code Tribunal',
    RC_CITY: 'Ville',
    ACTIVITY_AREA: "Secteur d'activité",
    GRC: "Secteur d'activité",
    DESCRIPTION: 'Description de l’activité',

    SHAREHOLDERS: 'Actionnaires',
    SHAREHOLDER: 'Actionnaire',
    SHAREHOLDERS_NAME: ' Nom / Raison sociale',
    SHAREHOLDERS_CIN: 'CIN / RC',
    CAPITAL_PERCENTAGE: '% dans le Capital',
    SHAREHOLDERS_LIST: 'Vos actionnaires',

    MANAGEMENT: 'Management',
    MANAGEMENT_NANE: 'Nom',
    MANAGEMENT_CIN: 'CIN',
    MANAGEMENT_JOB_TITLE: 'Fonction',
    MANAGEMENT_CONTACT: 'N° de téléphone',
    MANAGEMENT_CONTACT_PLACEHOLDER: '0666666666',
    MANAGEMENT_LIST: 'Votre Management',

    INTERLOCUTOR: 'Interlocuteur',
    INTERLOCUTOR_NAME: 'Nom',
    INTERLOCUTOR_CONTACT: 'N° de téléphone',
    INTERLOCUTOR_JOB_TITLE: 'Fonction',
    INTERLOCUTOR_CONTACT_PLACEHOLDER: '0666666666',
    INTERLOCUTOR_CIN: 'CIN',
    INTERLOCUTOR_EMAIL: 'Mail',
    INTERLOCUTOR_LIST: 'Vos interlocuteurs',
    LAST_CA: 'Dernier chiffre d’affaire',
    LAST_CA_YEAR: 'Année',
    LAST_CA_AMOUNT: 'Montant',

    SWITCH_POSITIVE_VALUE: 'Oui',
    SWITCH_NEGATIVE_VALUE: 'Non',

    SENIORITY: "Ancienneté de l'activité",
    PREVISIONNEL_CA: 'CA Prévisionnel',
    PREVISIONNEL_CA_LABEL: "chiffre d'affaire prévisionnel",

    PROFFESSION: 'Profession',
    RNAE: 'RNAE',
  },
  uploadDocumentsStep: 'Pièces à fournir',
  loanInformationStep: {
    seo: {
      TITLE: 'Dossier credit bancaire | BANK OF AFRICA',
      DESCRIPTION:
        'Grâce à la plateforme de crédit en ligne de BANK OF AFRICA, vous pouvez transmettre les documents de votre dossier de crédit bancaire sans vous déplacer en agence        ',
    },
    ACCOUNT_PLACEHOLDER: 'Comptes disponibles',
    LOAN_REQUEST_TYPE: 'Votre demande de crédit concerne',
    RENEWAL_TYPE_PLACEHOLDER: 'Veuillez choisir le type de renouvellement',
    SUB_TYPE_PLACEHOLDER: 'Veuillez choisir un sous type',
    ACCOUNT: 'Choisissez le compte associé à votre demande',
    YOUR_ACCOUNT: 'Votre compte',
    PURPOSE_OF_THE_LOAN: 'Objet du crédit',
    PURPOSE_OF_THE_LOAN_TOOLTIP: 'Objet du crédit',
    AMOUNT: 'Montant sollicité',
    LOAN_TYPE_TITLE: 'Vos demandes',
    LOAN_TYPE: 'Type',
    LOAN_SUB_TYPE: 'Sous-Type',
    LOAN_NATURE: 'Nature',
    ADD_NEW_LINE: 'Ajouter une autre ligne',
    TOTAL_AMOUNT: 'Montant total',
    VALIDATE: 'Valider',
    LIST_OF_LOANS: 'Demandes de crédit',
  },
  loanGuaranteeStep: {
    ADD_GUARANTEE: 'Choix des garanties',
    MY_GUARANTEES: 'Garanties',
    LIST_OF_GUARANTEE: 'Garanties à proposer',
    LIST_OF_GUARANTEE_PLACEHOLDER: 'Sélectionnez la garantie',
    AMOUNT_OF_GUARANTEE: 'Montant de la garantie',
    DOCUMENT_PER_GUARANTEE: 'Documents à joindre par nature de garantie',
    GUARANTOR: 'Garant',
    AT_LEAST_ONE_GUARANTEE_REQUIRED:
      'Au moins une ligne de garantie est obligatoire.',
    DESCRIPTION: 'Description de la garantie',
  },
  uploadDocuments: {
    UPLOAD_ERROR: 'Erreur lors de télécharger de ce fichier',
    ALL_DOCUMENTS_ARE_REQUIRED:
      'Les documents comprenant un * sont obligatoires. Merci de les transmettre.',
    DOWNLOAD_TEMPLATES: 'Télécharger la liste des documents',
    FILE_EXTION_INVALIDE: `Seuls les formats ${validFileExtensions.join(
      ', ',
    )} sont acceptés`,
    FILE__SIZE_INVALIDE: 'La taille de votre document dépasse 5 Mo.',
    DOWNLOAD_MODEL: 'Télécharger le modèle',
    DOWNLOAD_LISTE: 'Télécharger la liste',
    TRANSMIT_DOCUMENT: 'Transmettre le document',
  },
  recapInformationStep: {
    DOCUMENTS_UPLOAD: 'Documents transmis',
    CONFIRMATION_MESSAGE:
      'En cliquant sur « Confirmer » votre demande de crédit en ligne  sera transmise  à votre Conseiller.',
  },
  validation: {
    MAIN_TITLE: 'Validation de votre demande',
    SUB_TITLE: "Merci d'avoir choisi BANK OF AFRICA",
    MESSAGE1: 'Votre demande est prise en compte.',
    MESSAGE2: 'Un mail récapitulatif vous a été transmis.',
  },
  validationRelease: {
    MAIN_TITLE: "Validation de votre demande d'attestation",
    SUB_TITLE: "Merci d'avoir choisi BANK OF AFRICA",
    MESSAGE: "Votre demande d'attestation est prise en compte.",
  },
  additionalInformation: {
    SUB_TITLE: 'Merci de fournir les précisions suivantes',
    RESPONSE_PLACEHOLDER: 'Merci de saisir votre réponse ici ...',
  },
  next: 'Suivant',
  previous: 'Précédent',
  validate: 'Valider',
  send: 'Envoyer',
  add: 'Ajouter',
  YOUR_CREDITS: 'Vos crédits',
  TABLE_VIEW: 'Affichage Tableau',
  KANBAN_VIEW: 'Affichage Horizontal',
};

export const ChangePassword = {
  currentPassword: 'Mot de passe actuel',
  newPassword: 'Nouveau mot de passe',
  newPasswordConfirmation: 'Confirmation du nouveau mot de passe',
  validate: 'Valider',
  title: 'Changement de mot de passe',
  description:
    'Monotonectally extend ethical e-markets via alternative metrics. Proactively enhancebackward-compatible infras',
};

export const Errors = {
  serverNotAvailable:
    'La page demandée est actuellement inaccessible. Merci de réessayer ultérieurement.',
  unauthorized: 'Votre session a expiré, veuillez vous réauthentifier',
  networkError: 'Veuillez vérifier votre connexion réseau',
  invalidNature: 'Invalide Nature',
  duplicatedNature:
    'Vous avez selectioné deux ou plusieurs lignes de meme nature',
  wafMessage:
    "L'URL demandé a été rejeté. Veuillez consulter votre administrateur.",
  idSupport: 'Votre ID de support est: ',
};

export const renewalLoansListWording = {
  A_RENOUVLER: 'A renouveler',
  RENOUVELER_CREDIT: 'Renouveler un crédit',
  MODIFIER_CREDIT: 'Modifier les conditions d’un crédit',
  PAS_DE_CREDIT_A_MODIFIER: 'Vous n’avez pas de crédits à modifier',
  PAS_DE_CREDIT_A_RENOUVELER: 'Vous n’avez pas de crédits à renouveler',
  SELECTIONEZ_CREDIT_A_RENOUVELER:
    'Veuillez sélectionner le crédit que vous souhaitez renouveler',
  SELECTIONEZ_CREDIT_A_MODIFIER:
    'Veuillez sélectionner le crédit que vous souhaitez modifier',
  LONAS_TO_RENEW_NOT_FOUND:
    'Vous ne détenez actuellement aucun crédit à renouveler.',
  LONAS_TO_MODIFY_NOT_FOUND:
    'Vous ne détenez actuellement aucun crédit à modifier.',
};

export const loansListWording = {
  SELECT_TIERS: 'Sélectionner un tiers',
  YOUR_CREDITS_GRANTED: 'Vos crédits octroyés',
  YOUR_CREDITS_ONLINE: 'Vos demandes de crédit en ligne',
  MOST_USED_NATURES:
    'Le top 5 des crédits les plus utilisés par les clients de BANK OF AFRICA',
  REQUEST_NEW_LOAN: 'Demander un nouveau crédit',
  AMOUNT_REQUESTED: 'Montant',
  AUTHORIZATION_GRANTED: 'Autorisation',
  AUTHORIZATION_AMOUNT: "Montant de l'autorisation",
  LINE: 'Ligne',
  STATUS: 'Statut',
  LOAN_RECAP: 'Récapitulatif et suivi',
  INVALID_NATURE: 'Invalide Nature',
  OUTSTANDING_AMOUNT: 'Encours',
  SUIVI_TITLE: 'Suivi de mon dossier en cours',
  DETAIL: 'Détail',
  SUBSCRIBE: 'Souscrire',
  DATE: 'Echéance',
  DATE_RERQUEST: 'Date',
  DATE_LOAN: 'Date de la demande',
  GET_DETAILS: 'Suivi de votre demande',
  NO_REQUEST: "Vous n'avez aucune demande pour l'instant!",
  SENT: 'Envoyé',
  CLOSED: 'Clôturé',
  TREATED: 'Traitée',
  REJECTED: 'Rejetée',
  IN_PROGRESS: 'En cours',
  RENEW: 'A Renouveler',
  PENDING: 'En attente',
  INITIATED: 'Initié',
  INCOMPLETE: 'Incomplet',
  REQUEST_NUMBER: 'Ref: ',
  REQUEST_REF: 'Référence',
  HOME: 'Accueil',
  REQUEST_LOAN: 'Demander un crédit',
  FAKE_MENU: 'Lorem ipsum',
  INFORMATION_REQUEST: 'Demande d’informations',
  INFORMATION_REQUEST_LABEL: 'Information manquante',
  INFORMATION_REQUEST_MESSAGE:
    "Veuillez répondre aux questions de votre chargé d'affaires",
  COMPLEMENT_REQUEST: 'Demande de complément',
  COMPLEMENT_REQUEST_LABEL: 'Document manquant',
  COMPLEMENT_REQUEST_MESSAGE:
    "Veuillez télécharger les documents demandés par votre chargé d'affaires",
  VALIDATE: 'Valider',
  CANCEL: 'Annuler',
  REPLY: 'Répondre',
  TRANSFER: 'Transmettre',
  CONFIRM: 'Confirmer',
  DOCUMENT_UPLOADED_SUCCESS: 'Vos documents ont bien été pris en compte',
  REPLY_QUESTION_SUCCESS: 'Votre réponse a bien été prise en compte',
  HOME_TOAST:
    'Vous avez initié une demande de crédit en ligne, vous pouvez poursuivre votre demande en cliquant ',
  HERE: 'ici.',
  CANCEL_LOAN_REQUEST: 'Pour annuler votre demande, cliquer ',
  STATUS_UPDATED_NOTIFICATION: id =>
    `Le statut de votre demande ${id} a été modifié, pour le consulter, veuillez`,
  EMPTY_LIST_OF_NOTIFICATIONS: 'Aucune notification',
  CLICK_HERE: 'cliquer ici.',
  RENEW_TOOLTIP:
    'Votre crédit est arrivé à échéance, merci de procéder à son renouvellement',
};

export const homeMenuWording = {
  HOME: 'ACCUEIL',
  REQUEST_LOAN: 'DEMANDER UN CRÉDIT',
  MANAGE_MY_LOANS: 'GÉRER MES CRÉDITS',
  REVOLVE_LOAN: 'Renouveler un crédit',
  MODIFY_CONDICTION_LOAN: "Modifier les conditions d'un crédit",
  REQUEST_ATTESTATION: 'Demander une attestation',
  MONITORING_REQUESTS_ATTESTATION: 'Suivi des demandes d’attestation',
  OPERATING_CREDIT: 'Crédit de fonctionnement',
  RENEW_A_CREDIT: 'Renouveler un crédit',
  REQUEST_LOAN_TYPE2: 'Demander une main levée',
  REQUEST_LOAN_TYPE3: 'Demander un rachat par anticipation',
  REQUEST_LOAN_TYPE4: 'Demander un tableau d’amortissement',
  CONTACT: 'Mon conseiller',
  FAQ: 'FAQ',
};

export const institutionalWording = {
  HOME_TPE_ACCESS: 'Espace Entrepreneurs et TPE',
  HOME_MAIN_TITLE: 'CRÉDIT',
  HOME_SUB_TITLE: 'BUSINESS ONLINE',
  HOME_DESCRIPTION: [
    'Crédit Business Online est une solution innovante, interactive et complète vous permettant d’initier les demandes de crédit de votre entreprise à distance en toute sécurité.',
    'À travers Crédit Business Online, accédez à notre offre de crédit sur mesure couvrant l’ensemble des besoins de financement de votre entreprise.',
    'Aussi, notre solution vous offre la possibilité de suivre en temps réel l’état d’avancement de vos demandes ainsi que les encours de vos crédits octroyés.',
  ],
  HOME_DESCRIPTION_TPE: [
    'Conçu pour faciliter l’accès des entrepreneurs aux solutions de financement, Crédit Business Online est un service innovant qui vous permet d’initier et de renouveler à distance et en toute sécurité toutes les demandes de crédit pour les besoins de votre activité.',
    'Que vous soyez à la tête d’une petite entreprise, auto-entrepreneur ou que vous exercez une profession libérale, Crédit Business Online vous oriente vers des lignes de financement adaptées à la nature et au volume de votre activité aussi bien pour la phase de démarrage que pour le développement ou l’extension.',
  ],
  SEE_MORE: 'Voir plus',
  SEE_LESS: 'Voir moins',
  GET_DEMANDE: 'Demandez votre crédit',
  EXPLOITATION: ['Financement du cycle', ' d’exploitation'],
  INVESTISSEMENT: ['Financement de', ' l’investissement'],
  INTERNATIONAL: ['Opérations à', ' L’international'],
  PME: ['Les solutions', ' PME'],
  TPE: ['Les solutions', ' TPE'],
  FINANCEMENT_CREATION: ['Financement', ' de la création'],
  FINANCEMENT_CYCLE_EXPLOITATION: ['Financement du Cycle', ' d’exploitation'],
  FINANCEMENT_DEVELOPPEMENT: ['Financement', ' du Développement'],
  SUBSCRIBE: 'Souscrire',
  REMOVAL_CREDIT: "Un crédit d'enlèvement",
  ADVANCE_EXPORT: 'Une Avance sur créances nées à l’export',
  CASH_FACILITY: 'Une facilité de Caisse',
  TREASURY_DESCRIPTION:
    'Vous peinez à financer vos besoins en fonds de roulement, vous souffrez d’un déficit de trésorerie dû au décalage entre les délais clients et les délais fournisseurs, vous vous trouvez face à une dépense imprévue.',
  MISSION_TITLE: 'emprunter pour soutenir votre mission',
  SPOT_CREDIT: 'Crédit Spot',
  BBO_TITLE: 'Découvrez BUSINESS ONLINE',
  BBO_DESCRIPTION:
    'Business Online est le portail de global banking qui vous permet d’initier, de valider toutes vos opérations domestiques et internationales et de les suivre à distance et en temps rèel.',
  LOREM_DESCRIPTION:
    'Monotonectally extend ethical e-markets via alternative metrics. Proactively enhance backward-compatible infrastructures through equity invested manufactured products. Holisticly evolve resource-leveling applications through backward-compatible e-business.',
  BBO_LINK_TEXT: 'Découvrez ce site',
  BBO_LINK: 'https://www.bmcebusinessonline.com/',
  DEMANDE_CREDIT: 'Demandez ce crédit',
  ANIMATED_BOX_DESCRIPTION: (
    <>
      posez toutes vos questions à notre <span>service client</span> dédié aux
      tpe au{' '}
    </>
  ),
  ANIMATED_BOX_CONTACT: '05 22 42 15 43',
};

export const FAQWording = {
  TITLE: 'Foire aux questions',
};

export const generalConditionWording = {
  TITLE: 'Conditions Générales D’utilisation',
};

export const footerWording = {
  GROUP_WEBSITES: 'Sites du groupe',
  RIGHTS_OF_USE: 'Droits d’utilisation',
  CONTACT: 'Contact',
  SHOWCASE_SITE: 'Site vitrine',
  SHOWCASE_SITE_WEBSITE: 'https://www.bmcebank.ma/',
  BMCE_BANK_OF_AFRICA: 'Business Online',
  BMCE_BANK_OF_AFRICA_WEBSITE: 'https://bmcebusinessonline.ma/',
  BMCE_DIRECT: 'BMCE Direct',
  BMCE_DIRECT_WEBSITE: 'https://www.bmcedirect.ma/',
  TERMS_OF_SERVICE: 'Conditions Générales d’Utilisation',
  LEGAL_NOTICE: 'Mentions légales',
  FAQ: 'FAQ',
  PHONE_NUMBER: '0522 46 24 24',
  TPE_PHONE_NUMBER: '0522 42 15 43',
  WEB_SITE: 'creditbusinessonline@bankofafrica.ma',
  ADRESS: 'BANK OF AFRICA SIÈGE, 140 AVENUE HASSAN II CASABLANCA',
  FOLLOW_US: 'Suivez-nous',
  COPYRIGHT: '2020 © BANK OF AFRICA. Tous droits réservés.',
  CLOSE: 'Fermer',
};

export const landingWording = {
  seo: {
    TITLE: 'Crédit Business Online | BANK OF AFRICA',
    DESCRIPTION:
      'Découvrez la plateforme crédit business Online de BANK OF AFRICA et effectuez votre demande de crédit en ligne sans vous déplacer en agence !',
  },
};

export const requestTrackingWording = {
  seo: {
    TITLE: 'Dossier credit bancaire | BANK OF AFRICA',
    DESCRIPTION:
      'Grâce à la plateforme de crédit en ligne de BANK OF AFRICA, vous pouvez transmettre les documents de votre dossier de crédit bancaire sans vous déplacer en agence',
  },
};

export const altWording = {
  BANK_OF_AFRICA: 'BANK OF AFRICA',
  BANK_OF_AFRICA_MAROC: 'BANK OF AFRICA maroc',
  ONLINE_BANK: 'banque en ligne',
  BANK_MAROC_ENTREPRISE: 'banque entreprise maroc',
  TRANSFER: 'Transmettre',
  ARROW_UP: 'vers le haut',
  ARROW_DOWN: 'vers le bas',
  ONLINE_LOAN: 'credit en ligne maroc',
};

export const releaseRequestWording = {
  MY_REQUESTS: "Mes demandes d'attestation",
  MY_REQUEST: "Ma demande d'attestation",
  title: "Demande d'attestation",
  ADD_RELEASE: 'Vos demandes',
  MY_RELEASE_REQUESTS: 'Demandes',
  LIST_OF_RELEASE: "Type d'attestation",
  LIST_OF_RELEASE_PLACEHOLDER: "Sélectionnez l'attestation",
  LIST_OF_GUARANTEE: 'Type de garanties',
  LIST_OF_GUARANTEE_PLACEHOLDER: 'Sélectionnez la garantie',
  DOCUMENT_PER_GUARANTEE: 'Documents à joindre par nature de garantie',
  RELEASE_NOT_FOUND:
    "Vous ne détenez actuellement aucune demande d'attestation.",
  CREATE_RELEASE: 'Effectuer une demande',
  AT_LEAST_ONE_GUARANTEE_REQUIRED:
    'Au moins une ligne de garantie est obligatoire.',
  DESCRIPTION: 'Description de la demande',
  GUARANTEE: 'Garantie',
  VALIDATE: 'Valider',
  ATTESTATION_UPDATED_NOTIFICATION: id =>
    // eslint-disable-next-line no-useless-escape
    `Le statut de votre demande d\'attestation ${id} a été modifié, pour le consulter, veuillez`,
  REQUEST_NUMBER: 'Ref: ',
  DOWNLOAD: 'Télécharger',
  REF: 'ATT',
  OBJECT: 'Objet',
  COMMENT: 'Comentaire',
  CONFIRMATION_MESSAGE:
    'En cliquant sur « Confirmer » votre demande d’attestation sera transmise à votre conseiller.',
  CANCEL: 'Annuler',
  CONFIRM: 'Confirmer',
};

export const noMatchWording = {
  TITLE: 'Impossible de trouver cette page',
  MESSAGE:
    "Nous ne trouvons pas la page que vous recherchez. Essayez de retourner à la page précédente ou à la page d'accueil.",
  BACK_TO_HOME: "Retour à l'accueil",
};
