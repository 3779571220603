import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { institutionalWording } from 'common/locale';
import { subMenuWording } from 'common/constants/subMenuWording';
import { useSelector } from 'react-redux';
import { getLocation } from 'core/root.selector';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/svg/retour.svg';

const item = item => {
  return item.map((str, i) => (
    <React.Fragment key={str}>
      {str}
      {item.length - 1 !== i && <br />}
    </React.Fragment>
  ));
};

const Element = ({ submenu, baseLink, classes }) => {
  return (
    <div className='mb-4'>
      <h3 className={`${classes.subTitle} title4 text-uppercase`}>
        {submenu.title}
      </h3>
      <div>
        {submenu.links.map(link => (
          <div key={link.text}>
            <Link className={classes.subLink} to={`${baseLink}${link.to}`}>
              {link.text}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

const NavItemEXPLOITATION = ({
  classes,
  label,
  baseLink,
  customClass,
  items,
  isActiveMenu,
  onMenuClicked,
}) => {
  const location = useSelector(getLocation);
  const isCurrentMenu = location.startsWith(baseLink);
  return (
    <Nav.Item
      className={`link-element ${classes.textPrimary} ${
        isCurrentMenu ? 'current' : ''
      } ${isActiveMenu ? classes.activeMenu : ''}`}
    >
      <div className='menu-element' onClick={onMenuClicked}>
        <span className={classes.backIcon}>
          <BackIcon />
        </span>
        {item(label)}
      </div>
      <div className={`${customClass} ${classes.submenu}`}>
        <div className='row'>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[0]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[1]} baseLink={baseLink} />
            <Element classes={classes} submenu={items[3]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[2]} baseLink={baseLink} />
            <Element classes={classes} submenu={items[4]} baseLink={baseLink} />
          </div>
        </div>
      </div>
    </Nav.Item>
  );
};
const NavItemINVESTISSEMENT = ({
  classes,
  label,
  baseLink,
  customClass,
  items,
  isActiveMenu,
  onMenuClicked,
}) => {
  const location = useSelector(getLocation);
  const isCurrentMenu = location.startsWith(baseLink);
  return (
    <Nav.Item
      className={`link-element ${classes.textPrimary} ${
        isCurrentMenu ? 'current' : ''
      } ${isActiveMenu ? classes.activeMenu : ''}`}
    >
      <div className='menu-element' onClick={onMenuClicked}>
        <span className={classes.backIcon}>
          <BackIcon />
        </span>
        {item(label)}
      </div>
      <div className={`${customClass} ${classes.submenu}`}>
        <div className='row'>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[0]} baseLink={baseLink} />
            <Element classes={classes} submenu={items[3]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[1]} baseLink={baseLink} />
            <Element classes={classes} submenu={items[4]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[2]} baseLink={baseLink} />
            <Element classes={classes} submenu={items[5]} baseLink={baseLink} />
          </div>
        </div>
      </div>
    </Nav.Item>
  );
};
const NavItemINTERNATIONAL = ({
  classes,
  label,
  baseLink,
  customClass,
  items,
  isActiveMenu,
  onMenuClicked,
}) => {
  const location = useSelector(getLocation);
  const isCurrentMenu = location.startsWith(baseLink);
  return (
    <Nav.Item
      className={`link-element ${classes.textPrimary} ${
        isCurrentMenu ? 'current' : ''
      } ${isActiveMenu ? classes.activeMenu : ''}`}
    >
      <div className='menu-element' onClick={onMenuClicked}>
        <span className={classes.backIcon}>
          <BackIcon />
        </span>
        {item(label)}
      </div>
      <div className={`${customClass} ${classes.submenu}`}>
        <div className='row'>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[0]} baseLink={baseLink} />
            <Element classes={classes} submenu={items[3]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[1]} baseLink={baseLink} />
          </div>
          <div className='col-md-4'>
            <Element classes={classes} submenu={items[2]} baseLink={baseLink} />
          </div>
        </div>
      </div>
    </Nav.Item>
  );
};

const Links = ({ classes }) => {
  const availableMenus = {
    EXPLOITATION: 'EXPLOITATION',
    INVESTISSEMENT: 'INVESTISSEMENT',
    INTERNATIONAL: 'INTERNATIONAL',
  };
  const [activeMenu, setActiveMenu] = useState('');
  const changeActiveMenu = newMenu => {
    if (window.innerWidth > 992) {
      return;
    }
    newMenu === activeMenu ? setActiveMenu('') : setActiveMenu(newMenu);
  };
  return (
    <Nav className={`pl-4 ${classes.nav}`}>
      {(!activeMenu || activeMenu === availableMenus.EXPLOITATION) && (
        <NavItemEXPLOITATION
          label={institutionalWording.EXPLOITATION}
          baseLink='/exploitation'
          items={subMenuWording.EXPLOITATION}
          classes={classes}
          customClass=''
          isActiveMenu={activeMenu === availableMenus.EXPLOITATION}
          onMenuClicked={() => changeActiveMenu(availableMenus.EXPLOITATION)}
        />
      )}

      {(!activeMenu || activeMenu === availableMenus.INVESTISSEMENT) && (
        <NavItemINVESTISSEMENT
          label={institutionalWording.INVESTISSEMENT}
          baseLink='/investissement'
          items={subMenuWording.INVESTISSEMENT}
          classes={classes}
          customClass=''
          isActiveMenu={activeMenu === availableMenus.INVESTISSEMENT}
          onMenuClicked={() => changeActiveMenu(availableMenus.INVESTISSEMENT)}
        />
      )}

      {(!activeMenu || activeMenu === availableMenus.INTERNATIONAL) && (
        <NavItemINTERNATIONAL
          label={institutionalWording.INTERNATIONAL}
          baseLink='/international'
          items={subMenuWording.INTERNATIONAL}
          classes={classes}
          customClass=''
          isActiveMenu={activeMenu === availableMenus.INTERNATIONAL}
          onMenuClicked={() => changeActiveMenu(availableMenus.INTERNATIONAL)}
        />
      )}
    </Nav>
  );
};

export default Links;
