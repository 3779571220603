import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router/immutable';
import backImg from 'assets/svg/back.svg';
const SHOW_ON = ['/sign-up', '/auth', '/prospect'];

const getPosition = location => {
  switch (location) {
    case '/sign-up':
    case '/prospect':
      return 140;
    default:
      return 80;
  }
};

const useStyles = makeStyles({
  back: props => {
    return {
      width: 50,
      height: 'auto',
      position: 'absolute',
      left: getPosition(props.location),
      display: 'block',
      top: 108,
      cursor: 'pointer',
      zIndex: 1,
      '@media (max-width: 1300px)': {
        left: getPosition(props.location) - 65,
      },
      '@media (max-height: 740px)': {
        top: 70,
      },
    };
  },
});

const GoBack = ({ location }) => {
  const dispatch = useDispatch();
  const goBackHistory = () => dispatch(goBack());
  const classes = useStyles({ location });

  if (SHOW_ON.includes(location) && window.innerWidth > 992) {
    return (
      <div onClick={goBackHistory} className={classes.back}>
        <img src={backImg} alt='go back' />
      </div>
    );
  } else return null;
};

export default GoBack;
