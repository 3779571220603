import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { SuccessToast } from 'common/component/Toast';
import { authWording } from 'common/locale';
import 'common/utils/extensions';
import { apiGet, apiPost } from 'common/utils/request';
import {
  authConstraint,
  prospectConstraint,
} from 'common/utils/validate/constraints';
import { MODIFY_PASSWORD } from 'common/utils/validate/errorKeys';
import * as errors from 'common/utils/validate/errors';
import { costumValidate } from 'common/utils/validate/validate';
import { go, push } from 'connected-react-router';
import { showGeneralConditionAction } from 'features/app/actions.app';
import { hideLoaderAction, showLoaderAction } from '../app/actions.app';
import authAT, {
  incrementStep,
  prospectAT,
  setAuthSuccess,
  updateProspect,
} from './actions.auth';
import { authTypeConst } from './constants.auth';
import { getAccoutTypeSelector, getProspectData } from './selectors.auth';
import React from 'react';

export function* submitAuth(action) {
  yield put({
    type: authAT.SUBMIT_SPECIFIC_AUTH_ERROR,
    errors: { login: null, password: null },
  });

  yield put({
    type: authAT.SET_AUTH_TYPE,
    accountType: action.userData.type,
  });

  const {
    data: { tiersList, errorCode, successCode, typeAuthentication = '' },
  } = yield call(
    apiPost,
    '/creditEntreprise/authentication/authenticate',
    action.userData,
  );

  if (typeAuthentication === 'BMCEDIRECT') {
    yield put({
      type: authAT.SUBMIT_BMCE_DIRECT_AUTH_SUCCESS,
    });
  }

  if (
    successCode === MODIFY_PASSWORD &&
    action.userData.type === authTypeConst.PROFILE
  ) {
    yield forceChangePassword(action.userData);
    return yield put(hideLoaderAction());
  }

  if (errorCode) {
    yield put({
      type: authAT.SUBMIT_AUTH_ERROR,
      error: errors[errorCode],
    });
    return yield put(hideLoaderAction());
  }

  /* INIT ERRORS IF ALL GOES RIGHT */
  yield put({
    type: authAT.SUBMIT_AUTH_ERROR,
    error: '',
  });

  if (action.type === authAT.SUBMIT_SSO_AUTH && tiersList) {
    yield put({
      type: authAT.SUBMIT_SSO_AUTH_SUCCESS,
      tiersList: tiersList.map(tiers => ({
        value: tiers.value,
        label: tiers.label.toCapitalize(),
      })),
    });

    yield submitSsoAuthWithTier({ ...action, tiersList });
    return yield put(hideLoaderAction());
  }

  if (action.type === authAT.SUBMIT_BBO_AUTH && tiersList) {
    yield put({
      type: authAT.SUBMIT_BBO_AUTH_SUCCESS,
      tiersList: tiersList.map(tiers => ({
        value: tiers.value,
        label: tiers.label.toCapitalize(),
      })),
    });

    return yield put(hideLoaderAction());
  }

  yield put(setAuthSuccess(typeAuthentication));
  yield put(push('/home'));
  yield put(hideLoaderAction());
}

export function* submitBmceDirectAuth(action) {
  try {
    yield put(showLoaderAction());
    const errors = costumValidate(
      action.userData,
      authConstraint.bmceDirectAuth,
    );
    if (errors) {
      yield put({ type: authAT.SUBMIT_SPECIFIC_AUTH_ERROR, errors });
      yield put(hideLoaderAction());
      return;
    }
    yield submitAuth(action);
  } catch (error) {
    console.error('Error auth:', error);
    yield put(hideLoaderAction());
  }
}

export function* submitBboAuth(action) {
  try {
    yield put(showLoaderAction());
    const errors = costumValidate(action.userData, authConstraint.bboAuth);
    if (errors) {
      yield put({ type: authAT.SUBMIT_SPECIFIC_AUTH_ERROR, errors });
      yield put(hideLoaderAction());
      return;
    }
    yield submitAuth(action);
  } catch (error) {
    console.error('Error auth:', error);
    yield put(hideLoaderAction());
  }
}

export function* submitSsoAuth(action) {
  try {
    yield put(showLoaderAction());
    const errors = costumValidate(action.userData, authConstraint.bboAuth);
    if (errors) {
      yield put({ type: authAT.SUBMIT_SPECIFIC_AUTH_ERROR, errors });
      yield put(hideLoaderAction());
      return;
    }
    yield submitAuth(action);
  } catch (error) {
    console.error('Error auth:', error);
    yield put(hideLoaderAction());
  }
}

export function* submitBboAuthWithTier(action) {
  try {
    yield put(showLoaderAction());
    yield call(apiPost, '/creditEntreprise/authentication/setTiers', {
      idTiers: action.tier,
    });
    yield put(setAuthSuccess(authTypeConst.BBO));
    yield put(push('/home'));
    yield put(hideLoaderAction());
  } catch (error) {
    console.error('Error :', error);
    yield put(hideLoaderAction());
  }
}

export function* submitSsoAuthWithTier(action) {
  const tier = action.tiersList[0].value;
  try {
    yield put(showLoaderAction());
    yield call(apiPost, '/creditEntreprise/authentication/setTiers', {
      idTiers: tier,
    });
    yield put(setAuthSuccess(authTypeConst.BBO));
    yield put(push('/home'));
    yield put(hideLoaderAction());
  } catch (error) {
    console.error('Error :', error);

    yield put(hideLoaderAction());
  }
}

export function* submitProfileAuth(action) {
  try {
    yield put(showLoaderAction());
    const errors = costumValidate(action.userData, authConstraint.profileAuth);
    if (errors) {
      yield put({ type: authAT.SUBMIT_SPECIFIC_AUTH_ERROR, errors });
      yield put(hideLoaderAction());
      return;
    }
    yield submitAuth(action);
  } catch (error) {
    console.error('Error auth:', error);
    yield put(hideLoaderAction());
  }
}

export function* submitBmceDirectAuthSaga() {
  yield takeLatest(authAT.SUBMIT_BMCEDIRECT_AUTH, submitBmceDirectAuth);
}

export function* submitBboAuthSaga() {
  yield takeLatest(authAT.SUBMIT_BBO_AUTH, submitBboAuth);
}

export function* submitBboAuthWithTierSaga() {
  yield takeLatest(authAT.SUBMIT_BBO_AUTH_WITH_TIER, submitBboAuthWithTier);
}

export function* submitSsoAuthSaga() {
  yield takeLatest(authAT.SUBMIT_SSO_AUTH, submitSsoAuth);
}

export function* submitSsoAuthWithTierSaga() {
  yield takeLatest(authAT.SUBMIT_SSO_AUTH_WITH_TIER, submitSsoAuthWithTier);
}

export function* submitProfileAuthSaga() {
  yield takeLatest(authAT.SUBMIT_PROFILE_AUTH, submitProfileAuth);
}

export function* verifyRib(action) {
  try {
    yield put(showLoaderAction());

    const {
      data: { errorCode },
    } = yield call(apiPost, '/creditEntreprise/souscription/verifyRib', {
      rib: action.payload.rib,
    });

    /* IN CASE OF ERROR */
    if (errorCode) {
      let error = {
        code: errorCode,
        message: errors.RIB_ERRORS[errorCode],
      };
      yield put({
        type: authAT.SET_REGISTER_DATA,
        payload: { error },
      });
      return yield put(hideLoaderAction());
    }

    /* OTHERWAYS GO FORWARD  */
    yield put({
      type: authAT.SET_REGISTER_DATA,
      payload: {
        ...action.payload,
        error: {
          code: '',
          message: '',
        },
      },
    });

    yield put(incrementStep());
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
  }
}

export function* verifyRibSaga() {
  yield takeLatest(authAT.VERIFY_RIB, verifyRib);
}

export function* completeSignUp(action) {
  try {
    yield put(showLoaderAction());
    const {
      data: { errorCode, phone, message },
    } = yield call(
      apiPost,
      '/creditEntreprise/souscription/createUser',
      action.payload,
    );

    /* IN CASE OF ERROR */
    if (errorCode) {
      let error = {
        code: errorCode,
        message: message,
      };

      yield put({
        type: authAT.SET_REGISTER_DATA,
        payload: { error },
      });

      return yield put(hideLoaderAction());
    }

    /* OTHERWAYS COMPLETE SIGNUP */
    yield put({
      type: authAT.SET_REGISTER_DATA,
      payload: {
        phone: phone || '',
        isSameNumber: action.payload.phone === phone,
        error: {
          code: '',
          message: '',
        },
      },
    });

    yield put(incrementStep());
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
  }
}

function* validateAccount(action) {
  try {
    const res = yield call(
      apiGet,
      `/creditEntreprise/souscription/activateUser/${action.id}`,
    );
    const {
      data: { errorCode },
    } = res;
    if (errorCode) {
      return yield put({
        type: authAT.VALIDATE_ACCOUNT_ERROR,
        error: errors.ACCOUNT_ACTIVATION_ERRORS[errorCode],
      });
    }

    yield put(push('/auth'));
    SuccessToast('Félicitations ! Votre compte a bien été activé');
  } catch (error) {
    console.error('error ', error);
  }
}

function* completeSignUpSaga() {
  yield takeLatest(authAT.SIGNUP, completeSignUp);
}

function* logOut() {
  try {
    const accountType = yield select(getAccoutTypeSelector);

    yield call(apiGet, `/creditEntreprise/logOut`);
    yield put({ type: authAT.LOG_OUT_SUCCESS });
    if (accountType === authTypeConst.SSO) {
      yield put(push('/'));
    } else {
      yield put(push('/auth'));
    }
  } catch (error) {
    console.error('Error logOut:', error);
  }
}

function* logOutSaga() {
  yield takeLatest(authAT.LOG_OUT, logOut);
}
function* validateAccountSaga() {
  yield takeLatest(authAT.VALIDATE_ACCOUNT, validateAccount);
}

function* fetchTribunals() {
  try {
    yield put(showLoaderAction('configLoader'));
    const res = yield call(apiGet, '/creditEntreprise/parametres/tribunals');
    const tribunals = res.data.map(tribunal => ({
      value: tribunal.code,
      label: tribunal.label.toLowerCase(),
    }));

    yield put({ type: authAT.SET_TRIBUNALS, tribunals });
    yield put(hideLoaderAction('configLoader'));
  } catch (error) {
    yield put(hideLoaderAction('configLoader'));
    console.error(error);
  }
}

function* fetchTribunalsSaga() {
  yield takeLatest(authAT.FETCH_TRIBUNALS, fetchTribunals);
}

function* businessCenterSaver(data) {
  const bcs = data.map(bc => ({
    ...bc,
    value: bc.code || bc.codeAgence,
    label: bc.label.toLowerCase().replace('caf ', 'centre affaires '),
    ref: React.createRef(),
  }));
  yield put(updateProspect(['selectedCenter'], bcs ? bcs[0] : {}));
  yield put({ type: authAT.SET_BUSNESS_CENTERS, bcs });
}

export function* fetchBusinessCenters(action) {
  try {
    yield put(showLoaderAction());
    const { data } = yield call(
      apiGet,
      `/creditEntreprise/parametres/centresAffaire/${action.city ?? ''}`,
    );

    yield businessCenterSaver(data);
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error(error);
  }
}
function* fetchBusinessCentersSaga() {
  yield takeLatest(authAT.FETCH_BUSNESS_CENTERS, fetchBusinessCenters);
}

function* forceChangePassword(payload) {
  yield put({
    type: authAT.SET_CHANGE_PASSWORD,
    keyName: 'login',
    value: payload.login,
  });

  yield put({
    type: authAT.SET_CHANGE_PASSWORD,
    keyName: 'oldPassword',
    value: payload.password,
  });

  yield put(push('/change-password'));
}

export function* changePassword(action) {
  try {
    yield put(showLoaderAction());
    const {
      data: { errorCode },
    } = yield call(
      apiPost,
      '/creditEntreprise/souscription/modifyPassword',
      action.payload,
    );

    if (errorCode) {
      yield put({
        type: authAT.SET_CHANGE_PASSWORD,
        keyName: 'error',
        value: errors.PASSWORD_ERRORS[errorCode],
      });
      return yield put(hideLoaderAction());
    }

    yield put({ type: authAT.INIT_CHANGE_PASSWORD });
    yield put(push('/auth'));
    SuccessToast(authWording.PASSWORD_CHANGED_SUCCESSFULLY);
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('error', error);
  }
}

function* changePasswordSaga() {
  yield takeLatest(authAT.CHANGE_PASSWORD, changePassword);
}

export function* legalForms() {
  try {
    const { data } = yield call(
      apiGet,
      '/creditEntreprise/parametres/formesJuridique',
    );

    const legalCategories = data.map(legalForm => ({
      label: legalForm.label,
      value: legalForm.label,
    }));

    yield put(updateProspect(['legalCategories'], legalCategories));
  } catch (error) {
    console.error("can't get list of legal forms", error);
  }
}

function* legalFormsSaga() {
  yield takeLatest(prospectAT.LEGAL_FORM, legalForms);
}

export function* validateProspectInfo() {
  try {
    const { fields, step } = yield select(getProspectData);
    const errors = costumValidate(fields, prospectConstraint.information);
    if (errors) {
      return yield put(updateProspect(['errors'], errors));
    }
    yield put(updateProspect(['errors'], {}));
    yield put(updateProspect(['step'], step + 1));
  } catch (error) {
    console.error('error on validate prospect information', error);
  }
}

function* validateProspectInfoSaga() {
  yield takeLatest(prospectAT.VALIDATE_INFORMATION, validateProspectInfo);
}

export function* getAgencies(action) {
  try {
    yield put(showLoaderAction());
    const { data } = yield call(
      apiGet,
      `/creditEntreprise/parametres/agenceByVille/${action.city}`,
    );

    yield businessCenterSaver(data);
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error("can't get list of agencies", error);
  }
}

function* getAgenciesSaga() {
  yield takeLatest(prospectAT.FETCH_AGENCIES, getAgencies);
}

export function* submitProspectRequest() {
  try {
    const { fields, step } = yield select(getProspectData);
    const errors = costumValidate(fields, prospectConstraint.agency);
    if (errors) {
      return yield put(updateProspect(['errors'], errors));
    }
    yield put(showLoaderAction());
    yield call(
      apiPost,
      '/creditEntreprise/demandeProspect/setDemandeProspect',
      fields,
    );

    yield put(updateProspect(['step'], step + 1));
    yield put(hideLoaderAction());
    yield put({ type: prospectAT.SUBMIT_PROSPECt_REQUEST_SUCCESS });
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('submit prospect request', error);
  }
}

function* submitProspectRequestSaga() {
  yield takeLatest(prospectAT.SUBMIT_PROSPECt_REQUEST, submitProspectRequest);
}

export function* acceptGeneralCondition() {
  try {
    yield put(showLoaderAction());
    const accountType = yield select(getAccoutTypeSelector);
    const {
      data: { tiersList },
    } = yield call(
      apiGet,
      `/creditEntreprise/authentication/create-profile/${accountType}`,
    );
    yield put(showGeneralConditionAction(false));
    if (accountType !== authTypeConst.BBO) {
      yield put(go('/home'));
    } else {
      yield put({
        type: authAT.SUBMIT_BBO_AUTH_SUCCESS,
        tiersList: tiersList.map(tiers => ({
          value: tiers.value,
          label: tiers.label.toCapitalize(),
        })),
      });
    }
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error('accept general condition :', error);
  }
}

function* acceptGeneralConditionSaga() {
  yield takeLatest(authAT.ACCEPT_GENERAL_CONDITION, acceptGeneralCondition);
}

export function* fetchCities(action) {
  try {
    yield put(showLoaderAction('configLoader'));
    const { data } = yield call(
      apiGet,
      '/creditEntreprise/parametres/villesAgence',
      {
        params: {
          agence: action.agence,
        },
      },
    );
    const cities = data.map(city => {
      const _city = city.toCapitalize();
      return { value: _city, label: _city };
    });

    yield put(updateProspect(['cities'], cities));
    yield put(hideLoaderAction('configLoader'));
  } catch (error) {
    yield put(hideLoaderAction('configLoader'));
    console.error(error);
  }
}

function* fetchCitiesSaga() {
  yield takeLatest(prospectAT.GET_CITIES, fetchCities);
}

export function* getAgencyCoordinates() {
  try {
    yield put(showLoaderAction());
    const { data } = yield call(apiGet, `/creditEntreprise/parametres/agences`);
    yield put(updateProspect(['agencyCoordinates'], data));
    yield put(hideLoaderAction());
  } catch (error) {
    yield put(hideLoaderAction());
    console.error("can't get list of agencies", error);
  }
}

function* getAgencyCoordinatesSaga() {
  yield takeLatest(prospectAT.GET_AGENCY_COORDONATES, getAgencyCoordinates);
}

export default [
  changePasswordSaga,
  logOutSaga,
  submitBmceDirectAuthSaga,
  submitBboAuthSaga,
  submitSsoAuthSaga,
  submitProfileAuthSaga,
  verifyRibSaga,
  completeSignUpSaga,
  validateAccountSaga,
  fetchBusinessCentersSaga,
  fetchTribunalsSaga,
  submitBboAuthWithTierSaga,
  submitSsoAuthWithTierSaga,
  legalFormsSaga,
  validateProspectInfoSaga,
  getAgenciesSaga,
  submitProspectRequestSaga,
  acceptGeneralConditionSaga,
  fetchCitiesSaga,
  getAgencyCoordinatesSaga,
];
