import { LOANS_REQUEST, ACCEPTED_LOANS } from './constants.loansList';

export const loansListAt = {
  INIT_TRACKING_DATA: 'INIT_TRACKING_DATA',
  GET_TIERS_LIST: 'GET_TIERS_LIST',
  GET_TIERS_SUCCESS: 'GET_TIERS_SUCCESS',
  GET_LOANS_LIST: 'GET_LOANS_LIST',
  GET_LOANS_SUCCESS: 'GET_LOANS_SUCCESS',
  GET_MOST_USED_NATURES: 'GET_MOST_USED_NATURES',
  GET_MOST_USED_NATURES_SUCCESS: 'GET_MOST_USED_NATURES_SUCCESS',
  SET_SELECTED_TIERS: 'SET_SELECTED_TIERS',
  UPDATE_REQUEST_TRACKING_ID: 'UPDATE_REQUEST_TRACKING_ID',
  GET_REQUEST_TRACKING_DETAILS: 'GET_REQUEST_TRACKING_DETAILS',
  GET_REQUEST_TRACKING_DETAILS_SUCCESS: 'GET_REQUEST_TRACKING_DETAILS_SUCCESS',
  UPDATE_REQUEST_TRACKING_DETAILS: 'UPDATE_REQUEST_TRACKING_DETAILS',
  REMOVE_ADDITIONAL_FILE: 'REMOVE_ADDITIONAL_FILE',
  TOGGLE_REQUEST_MODAL: 'TOGGLE_REQUEST_MODAL',
  REPLY_QUESTIONS: 'REPLY_QUESTIONS',
  SET_SUCCESS_MESSAGE: 'SET_SUCCESS_MESSAGE',

  SELECT_LOAN_NATURE: 'SELECT_LOAN_NATURE',

  UPDATE_TOAST_IS_OPEN: 'UPDATE_TOAST_IS_OPEN',
  UPDATE_INIT_LOAN_ID: 'UPDATE_INIT_LOAN_ID',

  GET_CONTACT: 'GET_CONTACT',
  GET_CONTACT_SUCCESS: 'GET_CONTACT_SUCCESS',
  GET_CONTACT_ERROR: 'GET_CONTACT_ERROR',
};

export const getContact = () => ({
  type: loansListAt.GET_CONTACT,
});

export const getContactSuccess = value => ({
  type: loansListAt.GET_CONTACT_SUCCESS,
  value,
});

export const toggleRequestModalAction = modal => ({
  type: loansListAt.TOGGLE_REQUEST_MODAL,
  modal,
});

export const getTiersList = () => ({
  type: loansListAt.GET_TIERS_LIST,
});

export const getTiersSuccess = tiers => ({
  type: loansListAt.GET_TIERS_SUCCESS,
  tiers,
});

export const getloans = tiers => ({
  type: loansListAt.GET_LOANS_LIST,
  tiers,
});

export const getLoansSuccess = (listDemandes, loanLines) => ({
  type: loansListAt.GET_LOANS_SUCCESS,
  [LOANS_REQUEST]: listDemandes,
  [ACCEPTED_LOANS]: loanLines,
});

export const getMostUsedNatures = () => ({
  type: loansListAt.GET_MOST_USED_NATURES,
});

export const getMostUsedNaturesSuccess = natures => ({
  type: loansListAt.GET_MOST_USED_NATURES_SUCCESS,
  natures,
});
export const setSelectedTiers = tiers => ({
  type: loansListAt.SET_SELECTED_TIERS,
  tiers,
});

export const getRequestTrackingAction = id => ({
  type: loansListAt.GET_REQUEST_TRACKING_DETAILS,
  id,
});

export const getRequestTrackingSuccess = payload => ({
  type: loansListAt.GET_REQUEST_TRACKING_DETAILS_SUCCESS,
  payload,
});

export const updateTrackingRequirement = (target, value) => ({
  type: loansListAt.UPDATE_REQUEST_TRACKING_DETAILS,
  target,
  value,
});

export const removeAdditionalFiles = (idDemande, idDocument) => ({
  type: loansListAt.REMOVE_ADDITIONAL_FILE,
  idDemande,
  idDocument,
});

export const questionsResponse = idDemande => ({
  type: loansListAt.REPLY_QUESTIONS,
  idDemande,
});

export const replySuccessMessage = message => ({
  type: loansListAt.SET_SUCCESS_MESSAGE,
  message,
});

export const initTrackingData = () => ({
  type: loansListAt.INIT_TRACKING_DATA,
});

export const selectLoanNatureAction = nature => ({
  type: loansListAt.SELECT_LOAN_NATURE,
  nature,
});
