import { makeStyles } from '@material-ui/styles';
import Init from 'assets/svg/init.svg';
import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loanRequestAt } from '../actions.loanRequest';
import { currentStepSelector } from '../selectors.loanRequest';
import {
  ENTREPRISE_IDENTIFICATION_STEP,
  SUMMARY_STEP,
} from '../constants.loanRequest';
import Seo from 'common/component/seo/Seo';
import { loanRequestWording } from 'common/locale';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 800,
    backgroundColor: 'white',
    borderRadius: '40px',
    padding: '50px 0 30px 0',
    margin: 0,
    maxWidth: 1200,
  },
  icon: {
    transform: 'rotate(-90deg)',
    cursor: 'pointer',
  },
});

function FormLayout(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(currentStepSelector);
  const syncEntrepriseInformation = () => {
    dispatch({
      type: loanRequestAt.INIT_ENTREPRISE_IDENTIFICATION,
      idLoan: props.idLoan,
    });
  };

  const cancelLoanRequest = () => {
    dispatch({
      type: loanRequestAt.CANCEL_LOAN_REQUEST,
      step: ENTREPRISE_IDENTIFICATION_STEP,
    });
  };

  const isFirstStep = currentStep === ENTREPRISE_IDENTIFICATION_STEP;
  const isLastStep = currentStep === SUMMARY_STEP;

  return (
    <div className={classes.container}>
      <Seo title={props.sectionTitle} />
      <Col xs='10' className={'p-0'}>
        <Row className={`mb-5`}>
          <Col xs={12} lg={isFirstStep ? 9 : 10} className={`loanStepTitle`}>
            {props.sectionTitle}
          </Col>
          {!isLastStep && (
            <Col xs='4' lg={2}>
              <Button
                block
                variant={`outline-primary`}
                className={`rounded-pill primaryButton`}
                onClick={cancelLoanRequest}
              >
                {loanRequestWording.CANCEL}
              </Button>
            </Col>
          )}
          {isFirstStep && (
            <Col xs='1'>
              <OverlayTrigger
                key={'top'}
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}>réinitialiser</Tooltip>}
              >
                <img
                  className={`${classes.icon}`}
                  src={Init}
                  onClick={syncEntrepriseInformation}
                  alt='init'
                />
              </OverlayTrigger>
            </Col>
          )}
        </Row>
        {props.children}
      </Col>
    </div>
  );
}

export default FormLayout;
