import { apiPost } from './request';
import { eventChannel, END } from 'redux-saga';
import {
  validFileExtensions,
  limitSize,
} from 'features/loanRequest/constants.loanRequest';
import FileImage from 'assets/svg/img-ext.svg';
import FilePdf from 'assets/svg/pdf-ext.svg';
import FileExcel from 'assets/svg/exl-ext.svg';
import FileWord from 'assets/svg/doc-ext.svg';
import { loanRequestWording } from 'common/locale';

function upload(formData, url, onProgress) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onProgress,
  };

  return apiPost(url, formData, config);
}

export function createUploader(payload, url) {
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });

  const uploadPromise = upload(payload, url, event => {
    if (event.loaded === event.total) {
      emit(END);
    }
    emit({
      loaded: event.loaded,
      total: event.total,
    });
  });

  return [uploadPromise, chan];
}

export function getfileExtension(file) {
  return file.substr(file.lastIndexOf('.') + 1).toLowerCase();
}

export function getIconFile(fileName) {
  const extension = getfileExtension(fileName);
  switch (extension) {
    case validFileExtensions[0]:
    case validFileExtensions[1]:
    case validFileExtensions[2]:
      return FileImage;
    case validFileExtensions[3]:
    case validFileExtensions[4]:
      return FileWord;
    case validFileExtensions[5]:
    case validFileExtensions[6]:
      return FileExcel;
    case validFileExtensions[7]:
      return FilePdf;
    default:
      return FilePdf;
  }
}

export function validateFile(file) {
  const fileSize = file.size / 1024 / 1024;
  let validateError = '';
  if (!validFileExtensions.includes(getfileExtension(file.name))) {
    validateError = loanRequestWording.uploadDocuments.FILE_EXTION_INVALIDE;
  } else if (fileSize >= limitSize) {
    validateError = loanRequestWording.uploadDocuments.FILE__SIZE_INVALIDE;
  }
  return validateError;
}
