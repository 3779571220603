import React from 'react';
import { Errors } from 'common/locale';

const WafMessageErreur = ({ supportID }) => {
  return (
    <div>
      {Errors.wafMessage} <br />
      {Errors.idSupport} <strong>{supportID}</strong>
    </div>
  );
};

export default WafMessageErreur;
