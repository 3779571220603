import { RELEASE_REQUEST_REDUCER } from './reducers.releaseRequest';
import { createSelector } from 'reselect';

export const getReleaseRequest = state => state.get(RELEASE_REQUEST_REDUCER);

export const releaseRequestsListSelector = createSelector(
  getReleaseRequest,
  releaseRequest => releaseRequest.get('releaseRequestsList').toJS(),
);

export const releaseRequestDetatilSelector = createSelector(
  getReleaseRequest,
  releaseRequest => releaseRequest.get('releaseRequestDetail').toJS(),
);

export const guaranteesListSelector = createSelector(
  getReleaseRequest,
  releaseRequest => releaseRequest.get('guaranteesList'),
);

export const RelesaeTypesSelector = createSelector(
  getReleaseRequest,
  releaseRequest => releaseRequest.get('releaseTypes').toJS(),
);

export const getNewReleaseRequestsListSelector = createSelector(
  getReleaseRequest,
  releaseRequest => releaseRequest.get('newReleaseRequsetsList').toJS(),
);

export const getFieldsSelector = createSelector(
  getReleaseRequest,
  releaseRequest => releaseRequest.get('fields').toJS(),
);
export const getReleaseRequestErrorsSelector = createSelector(
  getReleaseRequest,
  releaseRequest => releaseRequest.get('releaseRequestErrors').toJS(),
);
export const getShowConfirMessage = createSelector(
  getReleaseRequest,
  releaseRequest => releaseRequest.get('showConfirMessage'),
);
