import { fromJS } from 'immutable';
import { loansListAt } from './actions.loansList';
import {
  ACCEPTED_LOANS,
  LOANS_REQUEST,
  MOST_USED_NATURES,
  IS_FILES_MODAL,
  IS_QUESTIONS_MODAL,
} from './constants.loansList';
export const LOANS_LIST_REDUCER = 'LOANS_LIST_REDUCER';

export const initState = fromJS({
  id: '',
  [IS_QUESTIONS_MODAL]: false,
  [IS_FILES_MODAL]: false,
  toastIsOpen: false,
  initiatedLoanId: null,
  loanDetails: {
    status: [],
    questions: [],
    documents: [],
  },
  isFetching: true,
  selectedTiers: {},
  tiers: [],
  [LOANS_REQUEST]: [],
  [ACCEPTED_LOANS]: [],
  [MOST_USED_NATURES]: [],
  contact: {},
});

export function loansListReducer(state = initState, action) {
  switch (action.type) {
    case loansListAt.GET_LOANS_LIST:
      return state.set('isFetching', true);
    case loansListAt.GET_TIERS_SUCCESS:
      return state.set('tiers', fromJS(action.tiers));
    case loansListAt.GET_LOANS_SUCCESS:
      return state
        .set(
          ACCEPTED_LOANS,
          fromJS(action[ACCEPTED_LOANS] ? action[ACCEPTED_LOANS] : []),
        )
        .set(
          LOANS_REQUEST,
          fromJS(action[LOANS_REQUEST] ? action[LOANS_REQUEST] : []),
        )
        .set('isFetching', false);
    case loansListAt.GET_MOST_USED_NATURES_SUCCESS:
      return state.set(MOST_USED_NATURES, fromJS(action.natures));
    case loansListAt.SET_SELECTED_TIERS:
      return state.set('selectedTiers', action.tiers);
    case loansListAt.UPDATE_REQUEST_TRACKING_ID:
      return state.set('id', action.id);
    case loansListAt.GET_REQUEST_TRACKING_DETAILS_SUCCESS:
      return state.set('loanDetails', action.payload);
    case loansListAt.TOGGLE_REQUEST_MODAL:
      return state.set(action.modal, !state.get(action.modal));
    case loansListAt.UPDATE_REQUEST_TRACKING_DETAILS:
      return state.setIn(['loanDetails', action.target], action.value);
    case loansListAt.SET_SUCCESS_MESSAGE:
      return state.setIn(['loanDetails', 'successMessage'], action.message);
    case loansListAt.INIT_TRACKING_DATA:
      return state.set('loanDetails', initState.get('loanDetails'));
    case loansListAt.UPDATE_TOAST_IS_OPEN:
      return state.set('toastIsOpen', action.value);
    case loansListAt.UPDATE_INIT_LOAN_ID:
      return state.set('initiatedLoanId', fromJS(action.value));
    case loansListAt.GET_CONTACT:
      return state.setIn(['contact', 'loader'], true);
    case loansListAt.GET_CONTACT_ERROR:
      return state.set('contact', {});
    case loansListAt.GET_CONTACT_SUCCESS:
      return state.set('contact', fromJS(action.value));
    default:
      return state;
  }
}
