import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { authReducer, AUTH_REDUCER } from '../features/auth/reducer.auth';
import { APP_REDUCER, appReducer } from '../features/app/reducer.app';
import {
  LOAN_REQUEST_REDUCER,
  loanRequestReducer,
} from '../features/loanRequest/reducers.loanRequest';
import { ROUTER_REDUCER } from './root.constants';
import {
  LOANS_LIST_REDUCER,
  loansListReducer,
} from 'features/home/reducer.loansList';

import {
  RELEASE_REQUEST_REDUCER,
  releaseRequestReducer,
} from 'features/releaseRequest/reducers.releaseRequest';

const rootReducer = history =>
  combineReducers({
    [ROUTER_REDUCER]: connectRouter(history),
    [AUTH_REDUCER]: authReducer,
    [APP_REDUCER]: appReducer,
    [LOAN_REQUEST_REDUCER]: loanRequestReducer,
    [LOANS_LIST_REDUCER]: loansListReducer,
    [RELEASE_REQUEST_REDUCER]: releaseRequestReducer,
  });

export default rootReducer;
