const SHOW_ON = [
  '/exploitation',
  '/investissement',
  '/international',
  '/pme',
  '/tpe',
  '/offres-tpe/financement-creation',
  '/offres-tpe/finanacement-cycle-exploitation',
  '/offres-tpe/finanacement-developpement',
];

const useHeaderState = location => {
  const isMenuSubRoute = SHOW_ON.some(link => location.startsWith(link));
  const hasMenu =
    isMenuSubRoute || location === '/' || location === '/offres-tpe';
  return {
    hasMenu,
    isMenuSubRoute,
  };
};

export default useHeaderState;
